import React, { useContext } from "react";
import styles from "./SubscriptionBlock.module.css";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import { useIntl } from "react-intl";
import { ComponentHostContext } from "../index";
import cn from "classnames";

export interface SubscriptionBlockProps {
    title: string;
    description: string;
    buttonName: string;
    footnote: string;
    link?: string;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const SubscriptionBlock = (props: SubscriptionBlockProps) => {
    const cl = useContext(ComponentHostContext);
    let formatMessage;
    let localizedFootnote = props.footnote;

    try {
        const intl = useIntl();
        formatMessage = intl.formatMessage;
        localizedFootnote = formatMessage({ id: "contactUs_description" });
    } catch (error) {
        console.warn("IntlProvider is missing, using default messages.");
    }

    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={cn(styles.container, "max-w-screen-xl mx-auto px-2 md:px-8 my-12 pt-32 lg:pt-12")} style={marginStyles}>
            <div className={cn(styles.content, "flex flex-col lg:items-start lg:text-left md:items-center md:text-center justify-around h-full")}>
                <div className={cn(styles.title)}>
                    {props.title}
                </div>
                <div className={cn(styles.description)}>
                    {props.description}
                </div>
                <form className={cn(styles.form, "relative w-full lg:max-w-lg mt-6")}>
                    <input type="text" className={cn(styles.input)} placeholder={`E-mail`} required={true} />
                    <button className={cn(styles.button)}>{props.buttonName}</button>
                </form>
                <div
                    className={styles.footnote}
                    dangerouslySetInnerHTML={{ __html: localizedFootnote }}
                />
            </div>
        </div>
    );
};

export const SubscriptionBlockInfo: TypedBlockTypeInfo<SubscriptionBlockProps> = {
    id: "subscriptionBlock",
    name: "Subscription",
    preview: preview,
    renderer: SubscriptionBlock,
    initialData: {
        title: "",
        description: "",
        buttonName: "",
        footnote: "",
        link: "/ru/privacy_policy",
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "description",
                type: "String",
                name: "Description",
            },
            {
                id: "buttonName",
                type: "String",
                name: "Button Text",
            },
            {
                id: "footnote",
                type: "String",
                name: "Footnote",
            },
            {
                id: "link",
                type: "String",
                name: "Link",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};

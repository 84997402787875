import React from "react";

import styles from "./LocationUniversityBlock.module.css";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import cn from "classnames";
import { Map, MapComponentsProvider, MapPoint } from "../../ui/Map";

export interface LocationUniversityBlockProps {
  title: string;
  subtitle: string;
  texts: { item: string }[];
  align: string;
  centerLongitude: number;
  centerLatitude: number;
  zoom: number;
  duration: number;
  apiKey: string;
  points: {
    longitude: number;
    latitude: number;
  }[];
}

export const LocationUniversityBlock: React.FC<LocationUniversityBlockProps> = ({
  apiKey,
  title,
  subtitle,
  texts,
  align,
  centerLongitude,
  centerLatitude,
  zoom,
  duration,
  points,
}) => (
  <div className="py-12">
    <div className={cn(`flex flex-col justify-center align-center lg:p-12 max-w-screen-xl w-full mx-auto items-center lg:${align}`)}>
      <h3 className={styles.location}>{title}</h3>
      {texts && texts.map(({ item }) => <p className={styles.text}>{item}</p>)}
      <h2 className={styles.title}>{subtitle}</h2>
    </div>
    <div className={cn(styles.map, "flex flex-col max-w-screen-xl w-full my-0 mx-auto py-6")}>
      <MapComponentsProvider apiKey={apiKey}>
        <Map
          className={"h-full"}
          isGeolocationEnabled={false}
          location={{
            zoom,
            center: [centerLongitude, centerLatitude],
            duration,
          }}
        >
          {points &&
            points.map(({ latitude, longitude }, key) => <MapPoint key={key} coordinates={[longitude, latitude]} />)}
        </Map>
      </MapComponentsProvider>
    </div>
  </div>
);

export const LocationUniversityBlockInfo: TypedBlockTypeInfo<LocationUniversityBlockProps> = {
  id: "locationUniversityBlock",
  name: "LocationUniversityBlock",
  preview: preview,
  renderer: LocationUniversityBlock,
  initialData: {
    apiKey: "",
    title: "",
    subtitle: "1 South Ave, Garden City, NY 11530",
    texts: [
      {
        item: "string",
      },
    ],
    align: "string",
    centerLatitude: 55.751603,
    centerLongitude: 37.6177,
    zoom: 10,
    duration: 300,
    points: [
      {
        latitude: 55.751603,
        longitude: 37.6177,
      },
    ],
  },
  definition: {
    subTypes: {
      text: {
        fields: [
          {
            id: "item",
            name: "Item",
            type: "String",
          },
        ],
      },
      point: {
        fields: [
          {
            id: "longitude",
            name: "Longitude",
            type: "Number",
          },
          {
            id: "latitude",
            name: "Latitude",
            type: "Number",
          },
        ],
      },
    },
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "subtitle",
        type: "String",
        name: "Subtitle",
      },
      {
        id: "texts",
        name: "Texts",
        type: "List",
        listType: "text",
      },
      {
        id: "align",
        name: "Align",
        type: "Radio",
        possibleValues: [
          {
            id: "items-start",
            name: "left",
          },
          {
            id: "items-center",
            name: "center",
          },
          {
            id: "items-end",
            name: "right",
          },
        ],
      },
      {
        id: "apiKey",
        type: "String",
        name: "Api Key",
      },
      {
        id: "centerLongitude",
        type: "Number",
        name: "CenterLongitude",
      },
      {
        id: "centerLatitude",
        type: "Number",
        name: "Center Latitude",
      },
      {
        id: "zoom",
        type: "String",
        name: "Zoom",
      },
      {
        id: "duration",
        type: "String",
        name: "Duration",
      },
      {
        id: "points",
        name: "Points",
        type: "List",
        listType: "point",
      },
    ],
  },
};

import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./WorkBasisBlock.module.css";
import cn from "classnames";
import {ApiBaseUrl} from "../../api/apiClientBase";

export interface WorkBasisBlockElement {
    logo: null | number;
    title: string;
    text: string;
    image: null | number;
    buttonText: string;
    buttonLink: string;
    showButton: boolean
}

export const WorkBasisBlock = (props: WorkBasisBlockElement) => {
    console.log(`${ApiBaseUrl}/api/media/${props.image}`)
    return (
        <div className="flex flex-col lg:p-12 p-4 max-w-screen-xl justify-between gap-10 mx-auto my-10 lg:my-0">
            <div className={cn("flex flex-col justify-between items-center gap-5")}>
                <img src={`${ApiBaseUrl}/api/media/${props.logo}`} alt="" className={cn(styles.workBasisBlock__logo, "md:mb-10")}/>
                <div className={cn(styles.workBasisBlock__title)}>
                    {props.title}
                </div>
                <div className={cn(styles.workBasisBlock__text, "items-center")}>
                    {props.text}
                </div>
            </div>
            {props.image &&
                <img src={`${ApiBaseUrl}/api/media/${props.image}`} alt="" className={cn(styles.workBasisBlock__img)}/>
            }
            {props.showButton &&
                <a className={cn(styles.workBasisBlock__button)} href={props.buttonLink}>
                    {props.buttonText}
                </a>
            }
        </div>
    )
}

export const WorkBasisBlockInfo: TypedBlockTypeInfo<WorkBasisBlockElement> = {
    id: "WorkBasisBlock",
    name: "WorkBasisBlock",
    preview: preview,
    renderer: WorkBasisBlock,
    initialData: {
        logo: null,
        title: "string",
        text: "string",
        image: null,
        buttonText: "string",
        buttonLink: "",
        showButton: false
    },
    definition: {
        fields: [
            {
                id: "logo",
                type: "Custom",
                customType: "Image",
                name: "Logo",
            },
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "text",
                type: "String",
                name: "Text",
            },
            {
                id: "image",
                type: "Custom",
                customType: "Image",
                name: "Image",
            },
            {
                id: "buttonText",
                type: "String",
                name: "Button Text",
            },
            {
                id: "buttonLink",
                type: "String",
                name: "Button Link",
            },
            {
                id: "showButton",
                type: "CheckBox",
                name: "Show Button"
            }
        ]
    }
}

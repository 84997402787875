//IconAndImageBlock
import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./IconAndImageBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface IconAndImageBlockElement {
    icon: number | null;
    text: string;
    img: number | null;
}

export const IconAndImageBlock: React.FC<IconAndImageBlockElement> = ({ icon, text, img}) => {
    return(
        <div className={cn(`flex flex-col items-center justify-between mx-auto w-full max-w-screen-xl`)}>
            {icon && <div>{<img src={`${ApiBaseUrl}/api/media/${icon}`} className={cn(style.icon)} alt="" />}</div>}
            {text && <div className={cn("w-9/12 text-center my-6", style.text)}>{text}</div>}
            {img && <img src={`${ApiBaseUrl}/api/media/${img}`} alt="" />}
        </div>
    )
}

export const IconAndImageBlockInfo: TypedBlockTypeInfo<IconAndImageBlockElement> = {
    id: "IconAndImageBlock",
    name: "IconAndImageBlock",
    preview: preview,
    renderer: IconAndImageBlock,
    initialData: {
        icon: null,
        text: "",
        img: null,
    },
    definition: {
        fields: [
            {
                id: "icon",
                name: "Icon",
                type: "Custom",
                customType: "Image",
            },
            {
                id: "text",
                name: "Text",
                type: "String",
            },
            {
                id: "img",
                name: "Image",
                type: "Custom",
                customType: "Image",
            },
        ],
    },
};
import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./NewFaqBlock.module.css";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import cn from "classnames";
import Search from "../../assets/icons/search.svg";
import Subtract from "../../assets/icons/subtract.svg";

export interface NewFaqBlockProps {
    title: string;
    description: string;
    placeholder: string;
    buttonText: string;
    lackResults: string;
    elements: {
        question: string;
        answer: string;
    }[];
    paginationText: string;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const NewFaqBlock: React.FC<NewFaqBlockProps> = ({ title, description, placeholder, buttonText, lackResults, elements, paginationText, topMargin, bottomMargin, customMargin }) => {
    const [open, setOpen] = useState(-1);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [visibleItems, setVisibleItems] = useState(10);
    const itemsPerPage = 10;
    const answerRefs = useRef<(HTMLDivElement | null)[]>([]);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setIsMobile(window.innerWidth < 768);

            const handleResize = () => {
                setIsMobile(window.innerWidth < 768);
            };

            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []);

    const handleClick = (index: number) => {
        index === open ? setOpen(-1) : setOpen(index);
    };

    const handleSearch = () => {
        setSearchQuery(searchTerm);
        setCurrentPage(1);
    };

    useEffect(() => {
        answerRefs.current.forEach((ref, index) => {
            if (ref) {
                ref.style.height = open === index ? `${ref.scrollHeight}px` : "0px";
            }
        });
    }, [open]);

    const filteredElements = useMemo(() => {
        return elements.filter(({ question, answer }) =>
            question.toLowerCase().includes(searchQuery.toLowerCase()) ||
            answer.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [searchQuery, elements]);

    const totalPages = Math.ceil(filteredElements.length / itemsPerPage);

    const paginatedElements = useMemo(() => {
        const start = (currentPage - 1) * itemsPerPage;
        return filteredElements.slice(start, start + itemsPerPage);
    }, [currentPage, filteredElements]);

    const handlePageChange = (newPage: number) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const getPaginationRange = () => {
        if (totalPages <= 5) {
            return Array.from({ length: totalPages }, (_, i) => i + 1);
        }

        if (currentPage <= 2) {
            return [1, 2, 3, '...', totalPages];
        }

        if (currentPage >= totalPages - 2) {
            return [totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
        }

        return [currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
    };

    const mobileVisibleElements = useMemo(() => {
        return filteredElements.slice(0, visibleItems);
    }, [visibleItems, filteredElements]);

    const handleLoadMore = () => {
        setVisibleItems((prev) => prev + 5);
    };

    const marginStyles = customMargin
        ? {
            marginTop: topMargin ? `${topMargin}px` : undefined,
            marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
        }
        : {};

    return (
        <div className="lg:mt-20 max-w-screen-xl w-full my-0 mx-auto mt-32 px-4 md:px-8 mb-24 md:mb-32 lg:mb-40" style={marginStyles}>
            <h2 className={styles.newFaqBlock__title + " text-left"}
                dangerouslySetInnerHTML={{
                    __html: title
                }} />
            <div className={styles.newFaqBlock__description} dangerouslySetInnerHTML={{
                __html: description
            }} />
            <div className={cn("flex flex-col md:flex-row items-center w-full relative mb-10 md:mb-12 mb-8", styles.newFaqBlock__search)}>
                <div className="flex items-center relative w-full">
                    <span className="absolute left-3.5 md:left-6"><img src={Search} alt="search" width={16} height={16}></img></span>
                    <input
                        type="text"
                        placeholder={placeholder}
                        className={cn("w-full pl-12", styles.newFaqBlock__searchInput)}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {searchTerm && (
                        <button
                            onClick={() => setSearchTerm("")}
                            className="absolute z-10 right-3 md:right-48 lg:right-64 text-gray-500 hover:text-black"
                        >
                            <img src={Subtract} width={16} height={16}/>
                        </button>
                    )}
                </div>
                <button onClick={handleSearch} className={cn("w-3/12 lg:w-1/5", styles.newFaqBlock__searchButton)}
                    dangerouslySetInnerHTML={{
                        __html: buttonText
                    }} />
            </div>
            <hr className="hidden lg:flex" style={{ backgroundColor: "#EFEFEF", height: "2px" }} />
            <div className={"flex flex-col justify-between max-w-screen-xl w-full mb-10 md:mb-14 lg:mb-40 lg:pt-24 my-0 lg:px-24 py-6"}>
                <div>
                    {filteredElements.length === 0 ? (
                        <div className="text-center text-gray-500 mt-4" dangerouslySetInnerHTML={{ __html: lackResults }} />
                    ) : (isMobile ? mobileVisibleElements : paginatedElements).map(({ question, answer }, index) => (
                        <div key={index} className={cn(styles.newFaqBlock__block, open === index && styles.newFaqBlock__open)}>
                            <div className={styles.newFaqBlock__question} onClick={() => handleClick(index)} dangerouslySetInnerHTML={{ __html: question }} />
                            <div ref={(el) => (answerRefs.current[index] = el)}
                                className={styles.newFaqBlock__answerContainer}
                                style={{ height: "0px", overflow: "hidden", transition: "height 0.3s ease-in-out" }}>
                                <div className={styles.newFaqBlock__answer} dangerouslySetInnerHTML={{ __html: answer }} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="hidden md:flex flex-row justify-between mb:px-24">
                <span></span>
                {filteredElements.length > 0 &&
                    <button onClick={() => handlePageChange(currentPage + 1)} className={styles.newFaqBlock__pagination}>{paginationText}</button>
                }
                <div className="">
                    {getPaginationRange().map((page, index) => (
                        <button
                            key={index}
                            onClick={() => typeof page === "number" && handlePageChange(page)}
                            className={cn(styles.newFaqBlock__pagination_numbers, {
                                [styles.newFaqBlock__pagination_numbers_active]: currentPage === page
                            })}
                            disabled={page === '...'}
                        >
                            {page}
                        </button>
                    ))}
                </div>
            </div>
            {visibleItems < filteredElements.length && (
                <button onClick={handleLoadMore} className={cn(styles.newFaqBlock__pagination_mobile, "flex md:hidden")}>
                    {paginationText}
                </button>
            )}
        </div>
    );
};

export const NewFaqBlockInfo: TypedBlockTypeInfo<NewFaqBlockProps> = {
    id: "NewFaqBlock",
    name: "FAQ",
    preview: preview,
    renderer: NewFaqBlock,
    initialData: {
        title: "",
        description: "",
        placeholder: "",
        buttonText: "",
        lackResults: "Результаты по вашему запросу не найдены",
        elements: [
            {
                answer: "string1",
                question: "string1",
            },
            {
                answer: "string2",
                question: "string2",
            },
            {
                answer: "string3",
                question: "string3",
            },
        ],
        paginationText: "Загрузить еще",
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "question",
                        name: "Question",
                        type: "Custom",
                        customType: "Html",
                    },
                    {
                        id: "answer",
                        name: "Answer",
                        type: "Custom",
                        customType: "Html",
                    },
                ],
            },
        },
        fields: [
            {
                id: "title",
                type: "Custom",
                customType: "Html",
                name: "Title",
            },
            {
                id: "description",
                type: "Custom",
                customType: "Html",
                name: "Description"
            },
            {
                id: "placeholder",
                type: "String",
                name: "Placeholder"
            },
            {
                id: "buttonText",
                type: "Custom",
                customType: "Html",
                name: "Button Text"
            },
            {
                id: "lackResults",
                type: "Custom",
                customType: "Html",
                name: "Lack Results",
            },
            {
                id: "elements",
                name: "Elements",
                type: "List",
                listType: "element",
            },
            {
                id: "paginationText",
                name: "Pagination Text",
                type: "String"
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};

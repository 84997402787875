//SelectedBlock
import React from "react";
import styles from "./SelectedBlock.module.css";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import cn from "classnames";

export interface SelectedBlockProps {
    text: string;
}

export const SelectedBlock = (props: SelectedBlockProps) => {
    return (
        <div className={cn("py-12 mt-12 max-w-screen-xl w-full px-2 md:px-8")}>
            <div className={cn(styles.text)}>{props.text}</div>
        </div>
    );
};

export const SelectedBlockInfo: TypedBlockTypeInfo<SelectedBlockProps> = {
    id: "selectedBlock",
    name: "SelectedBlock",
    preview: preview,
    renderer: SelectedBlock,
    initialData: {
        text: ""
    },
    definition: {
        fields: [
            {
                id: "text",
                type: "String",
                name: "Text",
            },
        ],
    },
};

import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./WithUsBlock.module.css";
import arrow from "./Arrow.svg"
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface WithUsBlockElement {
  title: string;
  highlight: string;
  texts: {
    text: string
  }[];
  bottomText: string
  bottomTextLink: string
  img: number | null;
  topMargin?: string;
  bottomMargin?: string;
  customMargin?: boolean;
}

const highlightMatchAsHtml = (text: string, highlight: string): string => {
  if (!highlight || !text.includes(highlight)) return text;

  return text.replace(
    new RegExp(`(${highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, "gi"),
    `<span style="background-color: #FA5354; color: #FFFFFF; padding: 0 5px;">$1</span>`
  );
};

export const WithUsBlock = (props: WithUsBlockElement) => {
  const marginStyles = props.customMargin
    ? {
      marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
      marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
    }
    : {};
  return (
    <div style={{ backgroundColor: "#F3D7E3" }} id="withUsBlock">
      <div className={cn("flex flex-col-reverse lg:flex-row justify-between mx-auto w-full max-w-screen-xl lg:gap-8 my-28 md:my-32 lg:my-40")} style={marginStyles}>
        <div className="mx-auto w-full lg:w-6/12 flex flex-col gap-5 lg:justify-between md:px-6 flex flex-col items-center lg:items-start py-8 lg:py-12">
          <div className="flex flex-col items-center lg:items-start">
            <h2 className={cn(style.title, "w-10/12 md:w-8/12 lg:w-full")} dangerouslySetInnerHTML={{
              __html: highlightMatchAsHtml(props.title, props.highlight)
            }} />
            <div className={cn(style.main_text, "w-10/12 lg:w-full")}>
              {props.texts && props.texts.map((text, index) => (
                <div className="mb-4" key={index} dangerouslySetInnerHTML={{
                  __html: text.text
                }} />
              ))}
            </div>
          </div>
          <div className={cn(style.main_text, "mb-4 lg:mb-0 w-10/12")}>
            {props.bottomText &&
              <a href={`${props.bottomTextLink}`} className={cn("mt-2 lg:mt-0 flex gap-2 font-semibold justify-center lg:justify-start")}>
                <div dangerouslySetInnerHTML={{
                  __html: props.bottomText
                }} />
                <img src={arrow} alt="" />
              </a>}
          </div>
        </div>
        <div className="w-full lg:w-6/12 mb-16 lg:mb-0">
          {props.img && <img className={`w-full h-full object-cover object-center ${style.image}`} src={`${ApiBaseUrl}/api/media/${props.img}`} alt="" />}
        </div>
      </div>
    </div>
  );
}

export const WithUsBlockInfo: TypedBlockTypeInfo<WithUsBlockElement> = {
  id: "WithUsBlock",
  name: "With Us",
  preview: preview,
  renderer: WithUsBlock,
  initialData: {
    title: "string",
    highlight: "string",
    texts: [{
      text: "string"
    }],
    bottomText: "string",
    bottomTextLink: "#",
    img: null,
    topMargin: "",
    bottomMargin: "",
    customMargin: false,
  },
  definition: {
    subTypes: {
      text: {
        fields: [
          {
            id: "text",
            type: "Custom",
            customType: "Html",
            name: "Text",
          }
        ]
      }
    },
    fields: [
      {
        id: "title",
        type: "Custom",
        customType: "Html",
        name: "Title",
      },
      {
        id: "highlight",
        type: "String",
        name: "Highlight"
      },
      {
        id: "texts",
        type: "List",
        listType: "text",
        name: "Texts",
      },
      {
        id: "bottomText",
        type: "Custom",
        customType: "Html",
        name: "Bottom Text",
      },
      {
        id: "bottomTextLink",
        type: "String",
        name: "Bottom Text Link",
      },
      {
        id: "img",
        type: "Custom",
        customType: "Image",
        name: "Img",
      },
      {
        id: "customMargin",
        type: "CheckBox",
        name: "Custom Margin",
      },
      {
        id: "topMargin",
        type: "String",
        name: "Top Margin (px)",
      },
      {
        id: "bottomMargin",
        type: "String",
        name: "Bottom Margin (px)",
      },
    ],
  },
};

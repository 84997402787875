import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./CompanyDescriptionBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";
import { HtmlPresenter } from "../../ui/HtmlPresenter/htmlPresenter";

export interface CompanyDescriptionBlockElement {
  title: string;
  text: string;
  icon: null | number;
  bottomText: string;
  image: null | number;
  topMargin?: string;
  bottomMargin?: string;
  customMargin?: boolean;
  textLocation: string;
  imageSide: "left" | "right";
}

export const CompanyDescriptionBlock = (props: CompanyDescriptionBlockElement) => {
  const marginStyles = props.customMargin
    ? {
      marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
      marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
    }
    : {};
  return (
    <div className={cn("flex flex-col lg:flex-row lg:p-12 p-4 max-w-screen-xl justify-between gap-10 mx-auto my-28 md:my-32 lg:my-40", props.imageSide === "right" ? "lg:flex-row" : "lg:flex-row-reverse")} style={marginStyles}>
      <div className={cn(styles.aboutCompanyBlock__info, "flex flex-col lg:items-start items-center", props.textLocation === "bottom" ? "justify-between" : props.textLocation === "middle" ? "justify-between" : "gap-4")}>
        {props.title && (
          <div className={"flex flex-col gap-5 items-center lg:items-start"}>
            <div className={cn(styles.companyDescriptionBlock__title)} dangerouslySetInnerHTML={{
              __html: props.title
            }} />
          </div>
        )}
        <div className={cn("flex flex-col gap-5 lg:items-start items-center", styles.companyDescriptionBlock__bottom)}>
          {props.icon && <img src={`${ApiBaseUrl}/api/media/${props.icon}`} alt="" className={cn(styles.companyDescriptionBlock__icon, "hidden lg:flex")} />}
          {props.text && (
            <div className={cn(styles.companyDescriptionBlock__text)} dangerouslySetInnerHTML={{
              __html: props.text
            }} />
          )}
          {props.bottomText && (
            <div className={cn(styles.companyDescriptionBlock__text)}><HtmlPresenter text={props.bottomText} className={styles.companyDescriptionBlock__text} /></div>
          )}
        </div>
        {props.textLocation === "middle" && <div></div>}
      </div>
      {props.image && (
        <div className={cn(styles.companyDescriptionBlock__image)}><img src={`${ApiBaseUrl}/api/media/${props.image}`} alt="" className={cn("object-cover object-center w-full h-full")} /></div>
      )}
    </div>
  )
}

export const CompanyDescriptionBlockInfo: TypedBlockTypeInfo<CompanyDescriptionBlockElement> = {
  id: "CompanyDescriptionBlock",
  name: "Company Description",
  preview: preview,
  renderer: CompanyDescriptionBlock,
  initialData: {
    title: "string",
    text: "",
    icon: null,
    bottomText: "",
    image: null,
    textLocation: "top",
    topMargin: "",
    bottomMargin: "",
    customMargin: false,
    imageSide: "right",
  },
  definition: {
    fields: [
      {
        id: "title",
        type: "Custom",
        customType: "Html",
        name: "Title",
      },
      {
        id: "text",
        type: "Custom",
        customType: "Html",
        name: "Text",
      },
      {
        id: "icon",
        type: "Custom",
        customType: "Image",
        name: "Icon",
      },
      {
        id: "bottomText",
        type: "Custom",
        customType: "Html",
        name: "Bottom Text",
      },
      {
        id: "image",
        type: "Custom",
        customType: "Image",
        name: "Image",
      },
      {
        id: "textLocation",
        name: "Text Location",
        type: "Radio",
        possibleValues: [
          {
            id: "top",
            name: "top"
          },
          {
            id: "middle",
            name: "middle"
          },
          {
            id: "bottom",
            name: "bottom"
          }
        ]
      },
      {
        id: "customMargin",
        type: "CheckBox",
        name: "Custom Margin",
      },
      {
        id: "topMargin",
        type: "String",
        name: "Top Margin (px)",
      },
      {
        id: "bottomMargin",
        type: "String",
        name: "Bottom Margin (px)",
      },
      {
        id: "imageSide",
        name: "Image Side",
        type: "Radio",
        possibleValues: [
          {
            id: "right",
            name: "right"
          },
          {
            id: "left",
            name: "left"
          }
        ]
      },
    ]
  }
}

import React from "react";
import styles from "./SelectedBlock.module.css";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import cn from "classnames";

export interface SelectedBlockProps {
    text: string;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const SelectedBlock = (props: SelectedBlockProps) => {
    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={cn("py-12 mt-12 max-w-screen-xl w-full px-2 md:px-8")} style={marginStyles}>
            <div className={cn(styles.text)}>{props.text}</div>
        </div>
    );
};

export const SelectedBlockInfo: TypedBlockTypeInfo<SelectedBlockProps> = {
    id: "selectedBlock",
    name: "Selected",
    preview: preview,
    renderer: SelectedBlock,
    initialData: {
        text: "",
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        fields: [
            {
                id: "text",
                type: "String",
                name: "Text",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};

import React, { FC, useEffect, useRef, useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./SupportBlock.module.css"
import preview from "./preview.png";
import cn from "classnames";
import { CurvedText } from "./CurvedText";

export interface SupportBlockElement {
  texts: {
    text: string
  }[];
  buttonText: string;
  buttonLink: string;
  curveText: string;
  topMargin?: string;
  bottomMargin?: string;
  customMargin?: boolean;
}

export const SupportBlock = (props: SupportBlockElement) => {
  const [isVisible, setIsVisible] = useState(false);
  const blockRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.7,
      }
    );

    if (blockRef.current) {
      observer.observe(blockRef.current);
    }

    return () => {
      if (blockRef.current) {
        observer.unobserve(blockRef.current);
      }
    };
  }, []);

  const marginStyles = props.customMargin
    ? {
      marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
      marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
    }
    : {};

  return (
    <div ref={blockRef} className={cn(styles.supportBlock, "flex justify-center px-12 my-28 md:my-32 lg:my-40")} style={marginStyles}>
      <div className={cn("h-full w-full max-w-screen-xl flex relative", styles.supportBlock__animation, { [styles.supportVisible]: isVisible })}>
        <div className={cn(styles.supportBlock__info, "lg:pt-2 pb-20 lg:pl-12")}>
          {props.texts && props.texts.map((text) => (
            <div className={cn(styles.supportBlock__text, "mb-3 ")} dangerouslySetInnerHTML={{
              __html: text.text
            }} />
          ))}
          {props.buttonText && <a href={`${props.buttonLink}` || "#"} className={styles.supportBlock__button}>
            <div dangerouslySetInnerHTML={{
              __html: props.buttonText
            }} />
          </a>}
        </div>
        <div className={cn(styles.supportBlock__curve, "hidden lg:flex")} >
          <CurvedText text={`${props.curveText}`} width={"115"} fontSize={"42px"} />
        </div>
        <div className={cn(styles.supportBlock__curve, "hidden md:flex lg:hidden")} >
          <CurvedText text={`${props.curveText}`} width={"100"} fontSize={"38px"} transform={"rotate(40 498.5 522)"} />
        </div>
      </div>
    </div>

  );
};

export const SupportBlockInfo: TypedBlockTypeInfo<SupportBlockElement> = {
  id: "SupportBlock",
  name: "Support",
  preview: preview,
  renderer: SupportBlock,
  initialData: {
    texts: [{
      text: "string"
    }],
    buttonText: "string",
    buttonLink: "#",
    curveText: "string",
    topMargin: "",
    bottomMargin: "",
    customMargin: false,
  },
  definition: {
    subTypes: {
      text: {
        fields: [
          {
            id: "text",
            type: "Custom",
            customType: "Html",
            name: "Text",
          }
        ]
      }
    },
    fields: [
      {
        id: "texts",
        type: "List",
        listType: "text",
        name: "Texts"
      },
      {
        id: "buttonText",
        type: "Custom",
        customType: "Html",
        name: "Button Text",
      },
      {
        id: "buttonLink",
        type: "String",
        name: "Button Link",
      },
      {
        id: "curveText",
        type: "String",
        name: "Curve Text",
      },
      {
        id: "customMargin",
        type: "CheckBox",
        name: "Custom Margin",
      },
      {
        id: "topMargin",
        type: "String",
        name: "Top Margin (px)",
      },
      {
        id: "bottomMargin",
        type: "String",
        name: "Bottom Margin (px)",
      },
    ],
  },
};

import ad from "../../assets/icons/flags/ad.svg";
import ae from "../../assets/icons/flags/ae.svg";
import af from "../../assets/icons/flags/af.svg";
import ag from "../../assets/icons/flags/ag.svg";
import al from "../../assets/icons/flags/al.svg";
import am from "../../assets/icons/flags/am.svg";
import ao from "../../assets/icons/flags/ao.svg";
import ar from "../../assets/icons/flags/ar.svg";
import at from "../../assets/icons/flags/at.svg";
import au from "../../assets/icons/flags/au.svg";
import aw from "../../assets/icons/flags/aw.svg";
import az from "../../assets/icons/flags/az.svg";
import ba from "../../assets/icons/flags/ba.svg";
import bb from "../../assets/icons/flags/bb.svg";
import bd from "../../assets/icons/flags/bd.svg";
import be from "../../assets/icons/flags/be.svg";
import bf from "../../assets/icons/flags/bf.svg";
import bg from "../../assets/icons/flags/bg.svg";
import bh from "../../assets/icons/flags/bh.svg";
import bi from "../../assets/icons/flags/bi.svg";
import bj from "../../assets/icons/flags/bj.svg";
import bn from "../../assets/icons/flags/bn.svg";
import bo from "../../assets/icons/flags/bo.svg";
import br from "../../assets/icons/flags/br.svg";
import bs from "../../assets/icons/flags/bs.svg";
import bt from "../../assets/icons/flags/bt.svg";
import bv from "../../assets/icons/flags/bv.svg";
import bw from "../../assets/icons/flags/bw.svg";
import by from "../../assets/icons/flags/by.svg";
import bz from "../../assets/icons/flags/bz.svg";
import ca from "../../assets/icons/flags/ca.svg";
import cd from "../../assets/icons/flags/cd.svg";
import cf from "../../assets/icons/flags/cf.svg";
import cg from "../../assets/icons/flags/cg.svg";
import ch from "../../assets/icons/flags/ch.svg";
import ci from "../../assets/icons/flags/ci.svg";
import cl from "../../assets/icons/flags/cl.svg";
import cm from "../../assets/icons/flags/cm.svg";
import cn from "../../assets/icons/flags/cn.svg";
import co from "../../assets/icons/flags/co.svg";
import cr from "../../assets/icons/flags/cr.svg";
import cu from "../../assets/icons/flags/cu.svg";
import cv from "../../assets/icons/flags/cv.svg";
import cw from "../../assets/icons/flags/cw.svg";
import cy from "../../assets/icons/flags/cy.svg";
import cz from "../../assets/icons/flags/cz.svg";
import de from "../../assets/icons/flags/de.svg";
import dj from "../../assets/icons/flags/dj.svg";
import dk from "../../assets/icons/flags/dk.svg";
import dm from "../../assets/icons/flags/dm.svg";
import dom from "../../assets/icons/flags/do.svg";
import dz from "../../assets/icons/flags/dz.svg";
import ec from "../../assets/icons/flags/ec.svg";
import ee from "../../assets/icons/flags/ee.svg";
import eg from "../../assets/icons/flags/eg.svg";
import er from "../../assets/icons/flags/er.svg";
import es from "../../assets/icons/flags/es.svg";
import et from "../../assets/icons/flags/et.svg";
import fi from "../../assets/icons/flags/fi.svg";
import fj from "../../assets/icons/flags/fj.svg";
import fm from "../../assets/icons/flags/fm.svg";
import fr from "../../assets/icons/flags/fr.svg";
import ga from "../../assets/icons/flags/ga.svg";
import gb from "../../assets/icons/flags/gb.svg";
import gd from "../../assets/icons/flags/gd.svg";
import ge from "../../assets/icons/flags/ge.svg";
import gh from "../../assets/icons/flags/gh.svg";
import gm from "../../assets/icons/flags/gm.svg";
import gn from "../../assets/icons/flags/gn.svg";
import gq from "../../assets/icons/flags/gq.svg";
import gr from "../../assets/icons/flags/gr.svg";
import gt from "../../assets/icons/flags/gt.svg";
import gw from "../../assets/icons/flags/gw.svg";
import gy from "../../assets/icons/flags/gy.svg";
import hk from "../../assets/icons/flags/hk.svg";
import hn from "../../assets/icons/flags/hn.svg";
import hr from "../../assets/icons/flags/hr.svg";
import ht from "../../assets/icons/flags/ht.svg";
import hu from "../../assets/icons/flags/hu.svg";
import id from "../../assets/icons/flags/id.svg";
import ie from "../../assets/icons/flags/ie.svg";
import il from "../../assets/icons/flags/il.svg";
import ind from "../../assets/icons/flags/in.svg";
import iq from "../../assets/icons/flags/iq.svg";
import ir from "../../assets/icons/flags/ir.svg";
import is from "../../assets/icons/flags/is.svg";
import it from "../../assets/icons/flags/it.svg";
import jm from "../../assets/icons/flags/jm.svg";
import jo from "../../assets/icons/flags/jo.svg";
import jp from "../../assets/icons/flags/jp.svg";
import ke from "../../assets/icons/flags/ke.svg";
import kg from "../../assets/icons/flags/kg.svg";
import kh from "../../assets/icons/flags/kh.svg";
import ki from "../../assets/icons/flags/ki.svg";
import km from "../../assets/icons/flags/km.svg";
import kp from "../../assets/icons/flags/kp.svg";
import kr from "../../assets/icons/flags/kr.svg";
import kw from "../../assets/icons/flags/kw.svg";
import kz from "../../assets/icons/flags/kz.svg";
import la from "../../assets/icons/flags/la.svg";
import lb from "../../assets/icons/flags/lb.svg";
import lc from "../../assets/icons/flags/lc.svg";
import li from "../../assets/icons/flags/li.svg";
import lk from "../../assets/icons/flags/lk.svg";
import lr from "../../assets/icons/flags/lr.svg";
import ls from "../../assets/icons/flags/ls.svg";
import lt from "../../assets/icons/flags/lt.svg";
import lu from "../../assets/icons/flags/lu.svg";
import lv from "../../assets/icons/flags/lv.svg";
import ly from "../../assets/icons/flags/ly.svg";
import ma from "../../assets/icons/flags/ma.svg";
import mc from "../../assets/icons/flags/mc.svg";
import md from "../../assets/icons/flags/md.svg";
import me from "../../assets/icons/flags/me.svg";
import mg from "../../assets/icons/flags/mg.svg";
import mh from "../../assets/icons/flags/mh.svg";
import mk from "../../assets/icons/flags/mk.svg";
import ml from "../../assets/icons/flags/ml.svg";
import mm from "../../assets/icons/flags/mm.svg";
import mn from "../../assets/icons/flags/mn.svg";
import mr from "../../assets/icons/flags/mr.svg";
import mt from "../../assets/icons/flags/my.svg";
import mu from "../../assets/icons/flags/mu.svg";
import mv from "../../assets/icons/flags/mv.svg";
import mw from "../../assets/icons/flags/mw.svg";
import mx from "../../assets/icons/flags/mx.svg";
import my from "../../assets/icons/flags/my.svg";
import mz from "../../assets/icons/flags/mz.svg";
import na from "../../assets/icons/flags/na.svg";
import ne from "../../assets/icons/flags/ne.svg";
import ng from "../../assets/icons/flags/ng.svg";
import ni from "../../assets/icons/flags/ni.svg";
import nl from "../../assets/icons/flags/nl.svg";
import no from "../../assets/icons/flags/no.svg";
import np from "../../assets/icons/flags/np.svg";
import nr from "../../assets/icons/flags/nr.svg";
import nz from "../../assets/icons/flags/nz.svg";
import om from "../../assets/icons/flags/om.svg";
import pa from "../../assets/icons/flags/pa.svg";
import pe from "../../assets/icons/flags/pe.svg";
import pg from "../../assets/icons/flags/pg.svg";
import ph from "../../assets/icons/flags/ph.svg";
import pk from "../../assets/icons/flags/pk.svg";
import pl from "../../assets/icons/flags/pl.svg";
import ps from "../../assets/icons/flags/ps.svg";
import pt from "../../assets/icons/flags/pt.svg";
import pw from "../../assets/icons/flags/pw.svg";
import py from "../../assets/icons/flags/py.svg";
import qa from "../../assets/icons/flags/qa.svg";
import ro from "../../assets/icons/flags/ro.svg";
import rs from "../../assets/icons/flags/rs.svg";
import ru from "../../assets/icons/flags/ru.svg";
import rw from "../../assets/icons/flags/rw.svg";
import sa from "../../assets/icons/flags/sa.svg";
import sb from "../../assets/icons/flags/sb.svg";
import sc from "../../assets/icons/flags/sc.svg";
import sd from "../../assets/icons/flags/sd.svg";
import se from "../../assets/icons/flags/se.svg";
import sg from "../../assets/icons/flags/sg.svg";
import si from "../../assets/icons/flags/si.svg";
import sk from "../../assets/icons/flags/sk.svg";
import sl from "../../assets/icons/flags/sl.svg";
import sm from "../../assets/icons/flags/sm.svg";
import sn from "../../assets/icons/flags/sn.svg";
import so from "../../assets/icons/flags/so.svg";
import sr from "../../assets/icons/flags/sr.svg";
import ss from "../../assets/icons/flags/ss.svg";
import st from "../../assets/icons/flags/st.svg";
import sv from "../../assets/icons/flags/sv.svg";
import sx from "../../assets/icons/flags/sx.svg";
import sy from "../../assets/icons/flags/sy.svg";
import sz from "../../assets/icons/flags/sz.svg";
import td from "../../assets/icons/flags/td.svg";
import tg from "../../assets/icons/flags/tg.svg";
import th from "../../assets/icons/flags/th.svg";
import tj from "../../assets/icons/flags/tj.svg";
import tl from "../../assets/icons/flags/tl.svg";
import tm from "../../assets/icons/flags/tm.svg";
import tn from "../../assets/icons/flags/tn.svg";
import to from "../../assets/icons/flags/to.svg";
import tr from "../../assets/icons/flags/tr.svg";
import tt from "../../assets/icons/flags/tt.svg";
import tv from "../../assets/icons/flags/tv.svg";
import tz from "../../assets/icons/flags/tz.svg";
import ua from "../../assets/icons/flags/ua.svg";
import ug from "../../assets/icons/flags/ug.svg";
import us from "../../assets/icons/flags/us.svg";
import uy from "../../assets/icons/flags/uy.svg";
import uz from "../../assets/icons/flags/uz.svg";
import va from "../../assets/icons/flags/va.svg";
import vc from "../../assets/icons/flags/vc.svg";
import ve from "../../assets/icons/flags/ve.svg";
import vi from "../../assets/icons/flags/vi.svg";
import vn from "../../assets/icons/flags/vn.svg";
import vu from "../../assets/icons/flags/vu.svg";
import ws from "../../assets/icons/flags/ws.svg";
import ye from "../../assets/icons/flags/ye.svg";
import za from "../../assets/icons/flags/za.svg";
import zm from "../../assets/icons/flags/zm.svg";
import zw from "../../assets/icons/flags/zw.svg";

export const chooseFlag = (key: string) => {
    switch(key.toLowerCase()) {
        case 'ad': return ad;
        case 'ae': return ae;
        case 'af': return af;
        case 'ag': return ag;
        case 'al': return al;
        case 'am': return am;
        case 'ao': return ao;
        case 'ar': return ar;
        case 'at': return at;
        case 'au': return au;
        case 'aw': return aw;
        case 'az': return az;

        case 'ba': return ba;
        case 'bb': return bb;
        case 'bd': return bd;
        case 'be': return be;
        case 'bf': return bf;
        case 'bg': return bg;
        case 'bh': return bh;
        case 'bi': return bi;
        case 'bj': return bj;
        case 'bn': return bn;
        case 'bo': return bo;
        case 'br': return br;
        case 'bs': return bs;
        case 'bt': return bt;
        case 'bv': return bv;
        case 'bw': return bw;
        case 'by': return by;
        case 'bz': return bz;

        case 'ca': return ca;
        case 'cd': return cd;
        case 'cf': return cf;
        case 'cg': return cg;
        case 'ch': return ch;
        case 'ci': return ci;
        case 'cl': return cl;
        case 'cm': return cm;
        case 'cn': return cn;
        case 'co': return co;
        case 'cr': return cr;
        case 'cu': return cu;
        case 'cv': return cv;
        case 'cw': return cw;
        case 'cy': return cy;
        case 'cz': return cz;

        case 'de': return de;
        case 'dj': return dj;
        case 'dk': return dk;
        case 'dm': return dm;
        case 'do': return dom;
        case 'dz': return dz;

        case 'ec': return ec;
        case 'ee': return ee;
        case 'eg': return eg;
        case 'er': return er;
        case 'es': return es;
        case 'et': return et;

        case 'fi': return fi;
        case 'fj': return fj;
        case 'fm': return fm;
        case 'fr': return fr;

        case 'ga': return ga;
        case 'gb': return gb;
        case 'gd': return gd;
        case 'ge': return ge;
        case 'gh': return gh;
        case 'gm': return gm;
        case 'gn': return gn;
        case 'gq': return gq;
        case 'gr': return gr;
        case 'gt': return gt;
        case 'gw': return gw;
        case 'gy': return gy;

        case 'hk': return hk;
        case 'hn': return hn;
        case 'hr': return hr;
        case 'ht': return ht;
        case 'hu': return hu;

        case 'id': return id;
        case 'ie': return ie;
        case 'il': return il;
        case 'in': return ind;
        case 'iq': return iq;
        case 'ir': return ir;
        case 'is': return is;
        case 'it': return it;

        case 'jm': return jm;
        case 'jo': return jo;
        case 'jp': return jp;

        case 'ke': return ke;
        case 'kg': return kg;
        case 'kh': return kh;
        case 'ki': return ki;
        case 'km': return km;
        case 'kp': return kp;
        case 'kr': return kr;
        case 'kw': return kw;
        case 'kz': return kz;

        case 'la': return la;
        case 'lb': return lb;
        case 'lc': return lc;
        case 'li': return li;
        case 'lk': return lk;
        case 'lr': return lr;
        case 'ls': return ls;
        case 'lt': return lt;
        case 'lu': return lu;
        case 'lv': return lv;
        case 'ly': return ly;

        case 'ma': return ma;
        case 'mc': return mc;
        case 'md': return md;
        case 'me': return me;
        case 'mg': return mg;
        case 'mh': return mh;
        case 'mk': return mk;
        case 'ml': return ml;
        case 'mm': return mm;
        case 'mn': return mn;
        case 'mr': return mr;
        case 'mt': return mt;
        case 'mu': return mu;
        case 'mv': return mv;
        case 'mw': return mw;
        case 'mx': return mx;
        case 'my': return my;
        case 'mz': return mz;

        case 'na': return na;
        case 'ne': return ne;
        case 'ng': return ng;
        case 'ni': return ni;
        case 'nl': return nl;
        case 'no': return no;
        case 'np': return np;
        case 'nr': return nr;
        case 'nz': return nz;

        case 'om': return om;

        case 'pa': return pa;
        case 'pe': return pe;
        case 'pg': return pg;
        case 'ph': return ph;
        case 'pk': return pk;
        case 'pl': return pl;
        case 'ps': return ps;
        case 'pt': return pt;
        case 'pw': return pw;
        case 'py': return py;

        case 'qa': return qa;

        case 'ro': return ro;
        case 'rs': return rs;
        case 'ru': return ru;
        case 'rw': return rw;

        case 'sa': return sa;
        case 'sb': return sb;
        case 'sc': return sc;
        case 'sd': return sd;
        case 'se': return se;
        case 'sg': return sg;
        case 'si': return si;
        case 'sk': return sk;
        case 'sl': return sl;
        case 'sm': return sm;
        case 'sn': return sn;
        case 'so': return so;
        case 'sr': return sr;
        case 'ss': return ss;
        case 'st': return st;
        case 'sv': return sv;
        case 'sx': return sx;
        case 'sy': return sy;
        case 'sz': return sz;

        case 'td': return td;
        case 'tg': return tg;
        case 'th': return th;
        case 'tj': return tj;
        case 'tl': return tl;
        case 'tm': return tm;
        case 'tn': return tn;
        case 'to': return to;
        case 'tr': return tr;
        case 'tt': return tt;
        case 'tv': return tv;
        case 'tz': return tz;

        case 'ua': return ua;
        case 'ug': return ug;
        case 'us': return us;
        case 'uy': return uy;
        case 'uz': return uz;

        case 'va': return va;
        case 'vc': return vc;
        case 've': return ve;
        case 'vi': return vi;
        case 'vn': return vn;
        case 'vu': return vu;

        case 'ws': return ws;

        case 'ye': return ye;

        case 'za': return za;
        case 'zm': return zm;
        case 'zw': return zw;

        default:
            return null;
    }
}

import React from "react";

import styles from "./EducationalDirectionsBlock.module.css";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import { ApiBaseUrl } from "../../api/apiClientBase";
import cn from "classnames";
import { CurvedText1 } from "./CurvedText1";
import { CurvedText2 } from "./CurvedText2";
import { CurvedText3 } from "./CurvedText3";
import { CurvedText4 } from "./CurvedText4";

export interface EducationalDirectionsProps {
  title: string;
  curveText: string;
  elements: {
    title: string;
    text: string;
    backgroundImg: number | null;
    adult: boolean;
    rowsTitle: string;
    rows: {
      title: string;
      price: string;
    }[]
  }[];
  topMargin?: string;
  bottomMargin?: string;
  customMargin?: boolean;
}

export const EducationalDirectionsBlock: React.FC<EducationalDirectionsProps> = ({ title, curveText, elements, topMargin, customMargin, bottomMargin }) => {
  const firstElement = elements[0];
  const restElements = elements.slice(1, 4);
  const marginTop = customMargin ? topMargin || "0" : "48";
  const marginBottom = customMargin ? bottomMargin || "0" : "48";
  return (
    <div style={{ marginTop: `${marginTop}px`, marginBottom: `${marginBottom}px` }}>
      <h2 className={styles.educationalDirectionsBlock__title + " text-center md:mb-6 mb-0"} dangerouslySetInnerHTML={{ __html: title }} />
      <div className={"flex flex-col justify-between max-w-screen-xl gap-5 w-full my-0 mx-auto py-6 px-2 md:px-8"}>
        <div className={cn(styles.educationalDirectionsBlock__topItem, "w-full p-7 relative overflow-hidden")}
          style={{ backgroundImage: `url(${ApiBaseUrl}/api/media/${firstElement.backgroundImg})` }}>
          <div className={styles.educationalDirectionsBlock__info}>
            <h3
              className={cn(styles.educationalDirectionsBlock__blockTitle, firstElement.adult ? styles.educationalDirectionsBlock__blockTitleAdult : "")}
              dangerouslySetInnerHTML={{ __html: firstElement.title }} />
            {firstElement.text && <div
              className={cn(styles.educationalDirectionsBlock__blockText, styles.educationalDirectionsBlock__styleText, styles.educationalDirectionsBlock__mobileHidden)}
              dangerouslySetInnerHTML={{ __html: firstElement.text }} />
            }
            {firstElement.rowsTitle && <div
              className={cn(styles.educationalDirectionsBlock__blockText)} dangerouslySetInnerHTML={{ __html: firstElement.rowsTitle }} />
            }
            {firstElement.rows.length > 0 && firstElement.rows.map(({ title, price }) =>
              <div
                className={cn(styles.educationalDirectionsBlock__row, "flex", !price && styles.educationalDirectionsBlock__rowNoBorder)}>
                <span dangerouslySetInnerHTML={{ __html: title }} />
                <span dangerouslySetInnerHTML={{ __html: price }} />
              </div>
            )}
          </div>
          <div className={cn(styles.educationalDirectionsBlock__curve1)}>
            <CurvedText1 text={`${curveText}`} color={`#FA5354`} />
          </div>
          <div className={cn(styles.educationalDirectionsBlock__curve2)}>
            <CurvedText2 text={`${curveText}`} color={`#FA5354`} />
          </div>
          <div className={cn(styles.educationalDirectionsBlock__curve3)}>
            <CurvedText3 text={`${curveText}`} color={`#FA5354`} />
          </div>
          <div className={cn(styles.educationalDirectionsBlock__curve4)}>
            <CurvedText4 text={`${curveText}`} color={`#FA5354`} />
          </div>
        </div>
        <div className={styles.educationalDirectionsBlock__bottomBlocks + " flex flex-col md:flex-row gap-5"}>
          {restElements.map(({ title, text, backgroundImg, adult, rowsTitle, rows }) => <div
            className={"p-7 bg-cover bg-no-repeat bg-bottom"}
            style={{ backgroundImage: `url(${ApiBaseUrl}/api/media/${backgroundImg})` }}>
            <div className={styles.educationalDirectionsBlock__info}>
              <h3
                className={cn(styles.educationalDirectionsBlock__blockTitle, adult ? styles.educationalDirectionsBlock__blockTitleAdult : "")}
                dangerouslySetInnerHTML={{ __html: title }} />
              {text &&
                <div className={styles.educationalDirectionsBlock__blockText} dangerouslySetInnerHTML={{ __html: text }} />
              }
              {rowsTitle && <div className={cn(styles.educationalDirectionsBlock__blockText)} dangerouslySetInnerHTML={{ __html: rowsTitle }} />
              }
              {rows.length > 0 && rows.map(({ title, price }) =>
                <div className={cn(styles.educationalDirectionsBlock__row, !price && styles.educationalDirectionsBlock__rowNoBorder)}>
                  <span dangerouslySetInnerHTML={{ __html: title }} />
                  <span dangerouslySetInnerHTML={{ __html: price }} />
                </div>
              )}
            </div>
          </div>)}
        </div>
      </div>
    </div>

  );
}

export const EducationalDirectionsBlockInfo: TypedBlockTypeInfo<EducationalDirectionsProps> = {
  id: "EducationalDirectionsBlock",
  name: "Educational Directions",
  preview: preview,
  renderer: EducationalDirectionsBlock,
  initialData: {
    title: "string",
    curveText: "string",
    elements: [
      {
        title: "string",
        text: "string",
        backgroundImg: null,
        adult: false,
        rowsTitle: "string",
        rows: [
          { title: 'США', price: "от $ 1260" },
          { title: 'Англия', price: "от £ 1056" },
          { title: 'Мальта', price: "от € 630" }
        ]
      },
      {
        title: "string",
        text: "string",
        backgroundImg: null,
        adult: false,
        rowsTitle: "string",
        rows: [
          { title: 'США', price: "от $ 1260" },
          { title: 'Англия', price: "от £ 1056" },
          { title: 'Мальта', price: "от € 630" }
        ]
      },
      {
        title: "string",
        adult: true,
        text: "string",
        backgroundImg: null,
        rowsTitle: "string",
        rows: [
          { title: 'США', price: "от $ 1260" },
          { title: 'Англия', price: "от £ 1056" },
          { title: 'Мальта', price: "от € 630" }
        ]
      },
      {
        title: "string",
        text: "string",
        backgroundImg: null,
        adult: false,
        rowsTitle: "string",
        rows: [
          { title: 'США', price: "от $ 1260" },
          { title: 'Англия', price: "от £ 1056" },
          { title: 'Мальта', price: "от € 630" }
        ]
      }
    ],
    topMargin: "",
    bottomMargin: "",
    customMargin: false,
  },
  definition: {
    subTypes: {
      rows: {
        fields: [
          {
            id: "title",
            type: "Custom",
            customType: "Html",
            name: "Title",
          },
          {
            id: "price",
            type: "Custom",
            customType: "Html",
            name: "Price",
          },
        ],
      },
      element: {
        fields: [
          {
            id: "title",
            name: "Title",
            type: "Custom",
            customType: "Html",
          },
          {
            id: "text",
            name: "Text",
            type: "Custom",
            customType: "Html",
          },
          {
            id: "adult",
            type: "CheckBox",
            name: "Adult",
          },
          {
            id: "backgroundImg",
            name: "Background Image",
            type: "Custom",
            customType: "Image",
          },
          {
            id: "rowsTitle",
            name: "Rows title",
            type: "String",
          },
          {
            id: "rows",
            name: "Rows",
            type: "List",
            listType: "rows",
          }
        ],
      },
    },
    fields: [
      {
        id: "title",
        type: "Custom",
        customType: "Html",
        name: "Title",
      },
      {
        id: "curveText",
        type: "String",
        name: "CurveText  ",
      },
      {
        id: "elements",
        name: "Elements",
        type: "List",
        listType: "element",
      },
      {
        id: "customMargin",
        type: "CheckBox",
        name: "Custom Margin",
      },
      {
        id: "topMargin",
        type: "String",
        name: "Top Margin (px)",
      },
      {
        id: "bottomMargin",
        type: "String",
        name: "Bottom Margin (px)",
      },
    ],
  },
};

import React, { FunctionComponent, useState, useRef, useEffect } from "react";
import { observer } from "mobx-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import HtmlPresenterStyle from "@project/components/src/ui/HtmlPresenter/htmlPresenter.module.css";

type PropsType = {
  className?: string;
  data: string;
  onChange: (value: string) => void;
};

export const HtmlEditor: FunctionComponent<PropsType> = observer(({ className, data, onChange }) => {
  const [type, setType] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [activePanel, setActivePanel] = useState("noneActive");
  const editorRef = useRef<HTMLDivElement>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (editorRef.current) {
      observer.observe(editorRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (editorRef.current && !editorRef.current.contains(event.target as Node)) {
        setActivePanel("noneActive");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePanelClick = (panel: string) => {
    setActivePanel(panel);
  };

  const handleEditorBlur = () => {
    setActivePanel("noneActive");
  };

  return (
    <div ref={editorRef} className={`flex flex-col`} style={{ width: "99%" }}>
      <button
        onClick={() => setType(!type)}
        className={`text-white font-bold py-1 px-2 rounded inline-block bg-red-600 hover:bg-red-900 ml-auto mb-4`}
      >
        {type ? "Edit on HTML" : "Edit on Editor"}
      </button>
      {type ? (
        <div
          className={`${HtmlPresenterStyle.text} ${className} ${type ? "" : "hidden"} rounded border-2 border-grey-800 outline-none`}
          onClick={() => handlePanelClick("editor")}
        >
          {isVisible && (
            <>
              <div
                ref={toolbarRef}
                style={{
                  position: "sticky",
                  top: 0,
                  right: 0,
                  background: "#fff",
                  zIndex: activePanel === "editor" ? 100 : 0,
                  padding: "5px",
                  borderBottom: "1px solid #ccc",
                  width: "auto",
                  maxWidth: "40vw",
                  minWidth: "20vw",
                }}
                onClick={() => setActivePanel("editor")}
              />
              <CKEditor
                editor={DecoupledEditor}
                data={data}
                onChange={(event, editor) => {
                  onChange(editor.getData());
                  setActivePanel("editor");
                }}
                onReady={(editor) => {
                  if (toolbarRef.current) {
                    toolbarRef.current.innerHTML = "";
                    toolbarRef.current.appendChild(editor.ui.view.toolbar.element);
                  }
                }}
                onBlur={handleEditorBlur}
              />
            </>
          )}
        </div>
      ) : (
        <textarea
          value={data}
          className={`flex border-2 border-indigo-600 outline-none ${!type ? "" : "hidden"}`}
          style={{ minHeight: "200px" }}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </div>
  );
});

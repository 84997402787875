import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./ReasonsBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface ReasonsBlockElement {
  title: string;
  texts: {
    text: string
  }[];
  buttonText: string
  buttonLink: string
  img: number | null;
  topMargin?: string;
  bottomMargin?: string;
  customMargin?: boolean;
}

export const ReasonsBlock = (props: ReasonsBlockElement) => {
  const marginStyles = props.customMargin
    ? {
      marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
      marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
    }
    : {};
  return (
    <div style={{ backgroundColor: "#CEE1F3", ...marginStyles }} className={cn("my-28 md:my-32 lg:my-40")}>
      <div className="flex flex-col lg:flex-row justify-between mx-auto w-full max-w-screen-xl lg:gap-8">
        <div className="w-full lg:w-6/12 mb-16 lg:mb-0">
          {props.img && <img className={`w-full h-full object-cover object-center ${style.image}`} src={`${ApiBaseUrl}/api/media/${props.img}`} alt="" />}
        </div>
        <div className="mx-auto w-full lg:w-6/12 flex flex-col gap-10 md:px-6 flex flex-col items-center lg:items-start py-8 lg:py-12">
          <div className="flex flex-col items-center lg:items-start">
            <h2 className={cn(style.title, "w-10/12 md:w-8/12 lg:w-full")} dangerouslySetInnerHTML={{
              __html: props.title
            }} />
            <div className={cn(style.main_text, "w-10/12 lg:w-full")}>
              {props.texts && props.texts.map((text) => (
                <div className={"mb-4"} dangerouslySetInnerHTML={{
                  __html: text.text
                }} />
              ))}
            </div>
          </div>
          <div className={cn(style.main_text, "mb-8 lg:mb-0 w-10/12")}>
            {props.buttonText &&
              <a href={`${props.buttonLink}`} className={style.button}>
                <div dangerouslySetInnerHTML={{
                  __html: props.buttonText
                }} />
              </a>}
          </div>
        </div>
      </div>
    </div>
  );
}

export const ReasonsBlockInfo: TypedBlockTypeInfo<ReasonsBlockElement> = {
  id: "ReasonsBlock",
  name: "Reasons",
  preview: preview,
  renderer: ReasonsBlock,
  initialData: {
    title: "string",
    texts: [{
      text: "string"
    }],
    buttonText: "string",
    buttonLink: "#",
    img: null,
    topMargin: "",
    bottomMargin: "",
    customMargin: false,
  },
  definition: {
    subTypes: {
      text: {
        fields: [
          {
            id: "text",
            type: "Custom",
            customType: "Html",
            name: "Text",
          }
        ]
      }
    },
    fields: [
      {
        id: "title",
        type: "Custom",
        customType: "Html",
        name: "Title",
      },
      {
        id: "texts",
        type: "List",
        listType: "text",
        name: "Texts",
      },
      {
        id: "buttonText",
        type: "Custom",
        customType: "Html",
        name: "Button Text",
      },
      {
        id: "buttonLink",
        type: "String",
        name: "Button Link",
      },
      {
        id: "img",
        type: "Custom",
        customType: "Image",
        name: "Img",
      },
      {
        id: "customMargin",
        type: "CheckBox",
        name: "Custom Margin",
      },
      {
        id: "topMargin",
        type: "String",
        name: "Top Margin (px)",
      },
      {
        id: "bottomMargin",
        type: "String",
        name: "Bottom Margin (px)",
      },
    ],
  },
};

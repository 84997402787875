import React, { useEffect, useRef, useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./ExtraStatisticsBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface ExtraStatisticsBlockElement {
    header: string;
    highlightedTextHeader: string,
    elements: { title: string; text: string; icon: number | null }[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

const highlightMatchAsHtml = (text: string, highlight: string): string => {
    if (!highlight || !text.includes(highlight)) return text;

    return text.replace(
        new RegExp(`(${highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, "gi"),
        `<span style="background-color: #CEE1F3; color: #00309D; padding: 0 5px;">$1</span>`
    );
};


export const ExtraStatisticsBlock = (props: ExtraStatisticsBlockElement) => {
    const [isVisible, setIsVisible] = useState(false);
    const blockRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.7,
            }
        );

        if (blockRef.current) {
            observer.observe(blockRef.current);
        }

        return () => {
            if (blockRef.current) {
                observer.unobserve(blockRef.current);
            }
        };
    }, []);

    const getFadeInClass = (delay: number) => ({
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(20px)",
        transition: `opacity 1.5s ease-out ${delay}s, transform 1.5s ease-out ${delay}s`
    });

    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    const highlightedTextHtml = highlightMatchAsHtml(props.header, props.highlightedTextHeader);
    return (
        <div ref={blockRef} className={cn(style.extraStatisticsBlock__body)} style={marginStyles}>
            <div className={cn("flex flex-col justify-between max-w-screen-xl w-full my-0 mx-auto px-4 lg:px-14 relative", style.extraStatisticsBlock__animation, { [style.extraStatisticsBlockVisible]: isVisible })}>
                <div className="flex items-center lg:items-start flex-col">
                    <div className={cn(style.extraStatisticsBlock__header)} style={{ ...getFadeInClass(0) }} dangerouslySetInnerHTML={{
                        __html: highlightedTextHtml
                    }} />
                </div>
                <div className={cn(
                    style.extraStatisticsBlock__elements,
                    "grid text-left"
                )}>
                    {props.elements.map((el, index) => (
                        <div key={index} className={cn(style.extraStatisticsBlock__element, "flex flex-col gap-2")}>
                            <img src={`${ApiBaseUrl}/api/media/${el.icon}`} alt="" className={cn(style.extraStatisticsBlock__elementIcon)} />
                            <div className={"flex flex-col gap-1 md:gap-3"}>
                                <div className={style.extraStatisticsBlock__elementHeader} style={{ ...getFadeInClass(0.2) }} dangerouslySetInnerHTML={{
                                    __html: el.title
                                }} />
                                <div className={style.extraStatisticsBlock__elementText} style={{ ...getFadeInClass(0.3) }} dangerouslySetInnerHTML={{
                                    __html: el.text
                                }} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export const ExtraStatisticsBlockInfo: TypedBlockTypeInfo<ExtraStatisticsBlockElement> = {
    id: "ExtraStatisticsBlock",
    name: "Extra Statistics",
    preview: preview,
    renderer: ExtraStatisticsBlock,
    initialData: {
        header: "Header",
        highlightedTextHeader: "",
        elements: [
            {
                title: "string",
                text: "string",
                icon: null
            },
        ],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "title",
                        type: "Custom",
                        customType: "Html",
                        name: "Title"
                    },
                    {
                        id: "text",
                        type: "Custom",
                        customType: "Html",
                        name: "Text"
                    },
                    {
                        id: "icon",
                        type: "Custom",
                        customType: "Image",
                        name: "Icon"
                    },
                ],
            },
        },
        fields: [
            {
                id: "header",
                type: "Custom",
                customType: "Html",
                name: "Header",
            },
            {
                id: "highlightedTextHeader",
                type: "String",
                name: "Highlighted Text Header",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements"
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ]
    }
}

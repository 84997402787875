import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./CostBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface CostBlockElement {
    title: string;
    text: string;
    extraText: string;
    elements: {
        conditions: {
            icon: number | null;
            text: string;
        }[];
        price: string;
    }[];
    buttonText: string;
    buttonLink: string;
}

export const CostBlock: React.FC<CostBlockElement> = ({ title, text, extraText, elements, buttonText, buttonLink }) => {
    const formatPrice = (price: string) => {
        const match = price.match(/([€$£¥]\s?\d+([.,-]\d+)?)/);
        if (match) {
            const [matchedText] = match;
            const [before, after] = price.split(matchedText);
            return (
                <>
                    {before}
                    <strong className={cn(styles.price)}>{matchedText}</strong>
                    {after}
                </>
            );
        }
        return price;
    };
    return(
        <div className={cn("px-10 py-20 mx-auto w-full max-w-screen-xl items-center flex flex-col")}>
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.text}>{text}</p>
            <p className={styles.extraText}>{extraText}</p>
            <div className={styles.elementsContainer}>
                {elements.map((element, index) => (
                    <div key={index} className={styles.elementRow}>
                        <div className={styles.conditions}>
                            {element.conditions.map((condition, idx) => (
                                <div key={idx} className={styles.condition}>
                                    {condition.icon && <img src={`${ApiBaseUrl}/api/media/${condition.icon}`} alt="" className={styles.icon} />}
                                    <span className={cn(styles.condition)}>{condition.text}</span>
                                </div>
                            ))}
                        </div>
                        <div className={styles.aroundPrice}>
                            <span>{formatPrice(element.price)}</span>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.buttonContainer}>
                <a href={buttonLink} className={cn(styles.button)}>{buttonText}</a>
            </div>
        </div>
    )
}

export const CostBlockInfo: TypedBlockTypeInfo<CostBlockElement> = {
    id: "CostBlock",
    name: "CostBlock",
    preview: preview,
    renderer: CostBlock,
    initialData: {
        title: "",
        text: "",
        extraText: "",
        elements: [
            {
                conditions: [{
                    icon: null,
                    text: "",
                }],
                price: "",
            },
        ],
        buttonText: "",
        buttonLink: "",
    },
    definition: {
        subTypes: {
            condition: {
                fields: [
                    {
                        id: "icon",
                        type: "Custom",
                        customType: "Image",
                        name: "Icon"
                    },
                    {
                        id: "text",
                        type: "String",
                        name: "Text",
                    }
                ]
            },
            element: {
                fields: [
                    {
                        id: "conditions",
                        name: "Conditions",
                        type: "List",
                        listType: "condition",
                    },
                    {
                        id: "price",
                        type: "String",
                        name: "Price",
                    },
                ]
            }
        },
        fields: [
            {
                id: "title",
                name: "Title",
                type: "String",
            },
            {
                id: "text",
                name: "Text",
                type: "String",
            },
            {
                id: "extraText",
                name: "Extra Text",
                type: "String",
            },
            {
                id: "elements",
                name: "Elements",
                type: "List",
                listType: "element",
            },
            {
                id: "buttonText",
                name: "Button Text",
                type: "String",
            },
            {
                id: "buttonLink",
                name: "Button Link",
                type: "String",
            },
        ],
    },
};
import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./HabitationBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";
import Slider from "../../ui/Slider/Slider";

export interface HabitationBlockElement {
    title: string;
    text: string;
    elements: {
        icon: null | number,
        title: string,
        text: string
    }[]
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const HabitationBlock = (props: HabitationBlockElement) => {
    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={cn("flex flex-col gap-0 md:gap-12 items-center max-w-screen-xl mx-auto md:py-12 overflow-x-hidden my-28 md:my-32 lg:my-40")} style={marginStyles}>
            <div className={cn("flex flex-col text-center gap-7")}>
                <div className={cn(styles.habitationBlock__title, "")} dangerouslySetInnerHTML={{
                    __html: props.title
                }} />
                <div className={cn(styles.habitationBlock__text, "px-4")} dangerouslySetInnerHTML={{
                    __html: props.text
                }} />
            </div>
            <div className={cn("flex flex-col gap-3 md:gap-10 items-center px-4", styles.habitationBlock__itemsContainer)}>
                <div className={cn("hidden grid-cols-2 md:grid gap-5 w-full flex-wrap justify-center")}>
                    {props.elements && props.elements.map((element, index) => (
                        <div className={cn("flex flex-col", styles.habitationBlock__element)} key={index}>
                            <img src={`${ApiBaseUrl}/api/media/${element.icon}`} alt="" className={cn(styles.habitationBlock__icon)} />
                            <div className={cn(styles.habitationBlock__title)} dangerouslySetInnerHTML={{
                                __html: element.title
                            }} />
                            <div className={cn(styles.habitationBlock__elText)} dangerouslySetInnerHTML={{
                                __html: element.text
                            }} />
                        </div>
                    ))}
                </div>
                <div className={cn("flex md:hidden")}>
                    <Slider backgroundColor="white">
                        {props.elements && props.elements.map((element, index) => (
                            <div className={cn("flex flex-col", styles.habitationBlock__element)} key={index}>
                                <img src={`${ApiBaseUrl}/api/media/${element.icon}`} alt="" className={cn(styles.habitationBlock__icon)} />
                                <p className={cn(styles.habitationBlock__title)} dangerouslySetInnerHTML={{
                                    __html: element.title
                                }} />
                                <p className={cn(styles.habitationBlock__elText)} dangerouslySetInnerHTML={{
                                    __html: element.text
                                }} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export const HabitationBlockInfo: TypedBlockTypeInfo<HabitationBlockElement> = {
    id: "HabitationBlock",
    name: "Habitation",
    preview: preview,
    renderer: HabitationBlock,
    initialData: {
        title: "string",
        text: "string",
        elements: [{
            icon: null,
            title: "string",
            text: "string"
        }],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "icon",
                        type: "Custom",
                        customType: "Image",
                        name: "Icon",
                    },
                    {
                        id: "title",
                        type: "Custom",
                        customType: "Html",
                        name: "Title"
                    },
                    {
                        id: "text",
                        type: "Custom",
                        customType: "Html",
                        name: "Text"
                    }
                ]
            }
        },
        fields: [
            {
                id: "title",
                type: "Custom",
                customType: "Html",
                name: "Title",
            },
            {
                id: "text",
                type: "Custom",
                customType: "Html",
                name: "Text",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ]
    }
}

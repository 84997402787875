import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./ForWhomBlock.module.css";
import cn from "classnames";
import {ApiBaseUrl} from "../../api/apiClientBase";
import Slider from "../../ui/Slider/Slider";

export interface ForWhomBlockElement {
    title: string;
    text: string;
    secondTitle: string;
    elements: {
        icon: null | number,
        text: string
    }[]
}

export const ForWhomBlock = (props: ForWhomBlockElement) => {
    return (
        <div className="flex flex-col gap-8 md:gap-12 items-center my-10 max-w-screen-xl mx-auto md:py-12 mb-28 md:mb-32 lg:mb-40 overflow-x-hidden">
            <div className={cn("flex flex-col text-center")}>
                <div className={cn(styles.forWhomBlock__title, "mb-6 md:mb-6")}>
                    {props.title}
                </div>
                <div className={cn(styles.forWhomBlock__text, "px-4")}>
                    {props.text}
                </div>
            </div>
            <div className={cn("flex flex-col gap-3 md:gap-10 items-center w-full")}>
                <div className={cn("text-center", styles.forWhomBlock__secondTitle)}>{props.secondTitle}</div>
                <div className={cn("hidden md:flex gap-5 px-5 w-full overflow-x-auto",
                    props.elements.length < 5 && "lg:justify-center", props.elements.length === 5 && "lg:ml-20",
                    styles.scrollContainer)}>
                    {props.elements && props.elements.map((element, index) => (
                        <div className={cn("flex flex-col", styles.forWhomBlock__element)} key={index}>
                            <img src={`${ApiBaseUrl}/api/media/${element.icon}`} alt="" className={cn(styles.forWhomBlock__elIcon)} />
                            <p className={cn(styles.forWhomBlock__elText)}>{element.text}</p>
                        </div>
                    ))}
                </div>
                <div className={cn("flex md:hidden")}>
                    <Slider backgroundColor="white">
                        {props.elements.map((element, index) => (
                            <div className={cn("flex flex-col mx-auto my-0 w-full", styles.forWhomBlock__element)} key={index}>
                                <img src={`${ApiBaseUrl}/api/media/${element.icon}`} alt="" className={cn(styles.forWhomBlock__elIcon)} />
                                <p className={cn(styles.forWhomBlock__elText)}>{element.text}</p>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export const ForWhomBlockInfo: TypedBlockTypeInfo<ForWhomBlockElement> = {
    id: "ForWhomBlock",
    name: "ForWhomBlock",
    preview: preview,
    renderer: ForWhomBlock,
    initialData: {
        title: "string",
        text: "string",
        secondTitle: "string",
        elements: [{
            icon: null,
            text: "string"
        }]
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "icon",
                        type: "Custom",
                        customType: "Image",
                        name: "Icon"
                    },
                    {
                        id: "text",
                        type: "String",
                        name: "Text"
                    }
                ]
            }
        },
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "text",
                type: "String",
                name: "Text",
            },
            {
                id: "secondTitle",
                type: "String",
                name: "Second Title",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements",
            },
        ]
    }
}

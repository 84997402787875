import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./CostBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface CostBlockElement {
    title: string;
    text: string;
    extraText: string;
    elements: {
        conditions: {
            icon: number | null;
            text: string;
        }[];
        price: string;
    }[];
    buttonText: string;
    buttonLink: string;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const CostBlock: React.FC<CostBlockElement> = ({ title, text, extraText, elements, buttonText, buttonLink, customMargin, topMargin, bottomMargin }) => {
    const formatPrice = (price: string) => {
        const match = price.match(/([€$£¥]?\s?\d{1,5}(?:[.,\s]?\d{3})*|\d{4,})\s?[€$£¥]?/g);

        if (match) {
            const [matchedText] = match;
            const [before, after] = price.split(matchedText);

            return `${before}<strong class="${styles.price}">${matchedText}</strong>${after}`;
        }

        return price;
    };

    const marginStyles = customMargin
        ? {
            marginTop: topMargin ? `${topMargin}px` : undefined,
            marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={cn("px-10 my-20 mx-auto w-full max-w-screen-xl items-center flex flex-col")} style={marginStyles}>
            <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
            <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
            <div className={styles.extraText} dangerouslySetInnerHTML={{ __html: extraText }} />
            <div className={styles.elementsContainer}>
                {elements.map((element, index) => (
                    <div key={index} className={styles.elementRow}>
                        <div className={styles.conditions}>
                            {element.conditions.map((condition, idx) => (
                                <div key={idx} className={styles.condition}>
                                    {condition.icon && <img src={`${ApiBaseUrl}/api/media/${condition.icon}`} alt="" className={styles.icon} />}
                                    <span className={cn(styles.condition)} dangerouslySetInnerHTML={{ __html: condition.text }} />
                                </div>
                            ))}
                        </div>
                        <div className={styles.aroundPrice}>
                            <span dangerouslySetInnerHTML={{ __html: formatPrice(element.price) }} />
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.buttonContainer}>
                <a href={buttonLink} className={cn(styles.button)} dangerouslySetInnerHTML={{ __html: buttonText }} />
            </div>
        </div>
    )
}

export const CostBlockInfo: TypedBlockTypeInfo<CostBlockElement> = {
    id: "CostBlock",
    name: "Cost",
    preview: preview,
    renderer: CostBlock,
    initialData: {
        title: "",
        text: "",
        extraText: "",
        elements: [
            {
                conditions: [{
                    icon: null,
                    text: "",
                }],
                price: "",
            },
        ],
        buttonText: "",
        buttonLink: "",
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            condition: {
                fields: [
                    {
                        id: "icon",
                        type: "Custom",
                        customType: "Image",
                        name: "Icon"
                    },
                    {
                        id: "text",
                        type: "Custom",
                        customType: "Html",
                        name: "Text",
                    }
                ]
            },
            element: {
                fields: [
                    {
                        id: "conditions",
                        name: "Conditions",
                        type: "List",
                        listType: "condition",
                    },
                    {
                        id: "price",
                        type: "Custom",
                        customType: "Html",
                        name: "Price",
                    },
                ]
            }
        },
        fields: [
            {
                id: "title",
                name: "Title",
                type: "Custom",
                customType: "Html",
            },
            {
                id: "text",
                name: "Text",
                type: "Custom",
                customType: "Html",
            },
            {
                id: "extraText",
                name: "Extra Text",
                type: "Custom",
                customType: "Html",
            },
            {
                id: "elements",
                name: "Elements",
                type: "List",
                listType: "element",
            },
            {
                id: "buttonText",
                name: "Button Text",
                type: "Custom",
                customType: "Html",
            },
            {
                id: "buttonLink",
                name: "Button Link",
                type: "String",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};
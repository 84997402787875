import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./ProgramsBlock.module.css";
import cn from "classnames";
import {ApiBaseUrl} from "../../api/apiClientBase";

export interface ProgramsBlockElement {
    title: string;
    ageIcon: null | number;
    languageIcon: null | number;
    elements: {
        programName: string,
        age: string,
        languageLevel: string,
        price: string
    }[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const ProgramsBlock = (props: ProgramsBlockElement) => {
    const formatPrice = (price: string) => {
        const match = price.match(/([€$£¥]\s?\d+([.,-]\d+)?)/);
        if (match) {
            const [matchedText] = match;
            const [before, after] = price.split(matchedText);
            return (
                <>
                    {before}
                    <strong className={cn(styles.programsBlock__price)}>{matchedText}</strong>
                    {after}
                </>
            );
        }
        return price;
    };
    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className="flex flex-col py-12 px-4 md:px-12 lg:px-20 max-w-screen-xl justify-center gap-10 mx-auto my-0" style={marginStyles}>
            <div className={"flex flex-col gap-7 items-center text-center"}>
                <div className={cn(styles.programsBlock__title, "mb-8")}>
                    {props.title}
                </div>
                <div className={cn("flex flex-col w-full mb-5")}>
                    <hr className={cn("mb-5 w-full")}/>
                    {props.elements && props.elements.map((element) =>
                        <div className={cn("flex flex-col")}>
                            <div className={cn("flex flex-col md:flex-row gap-5 md:gap-0 md:justify-between")}>
                                <div className={cn("flex flex-col md:flex-row gap-5 md:gap-7 xl:gap-10")}>
                                    <div className={cn(styles.programsBlock__programName, "self-start md:self-center")}>{element.programName}</div>
                                    <div className={cn("flex gap-2")}>
                                        <img src={`${ApiBaseUrl}/api/media/${props.ageIcon}`} alt="" className={cn(styles.programsBlock__icon)}/>
                                        <p className={cn(styles.programsBlock__text, "self-start md:self-center")}>{element.age}</p>
                                    </div>
                                    <div className={cn("flex gap-2")}>
                                        <img src={`${ApiBaseUrl}/api/media/${props.languageIcon}`} alt="" className={cn(styles.programsBlock__icon)}/>
                                        <p className={cn(styles.programsBlock__text, "self-start md:self-center")}>{element.languageLevel}</p>
                                    </div>
                                </div>
                                <div className={cn(styles.programsBlock__aroundPrice, "self-start md:self-center")}>{formatPrice(element.price)}</div>
                            </div>
                            <hr className={cn("my-5 w-full")}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export const ProgramsBlockInfo: TypedBlockTypeInfo<ProgramsBlockElement> = {
    id: "ProgramsBlock",
    name: "Programs",
    preview: preview,
    renderer: ProgramsBlock,
    initialData: {
        title: "string",
        ageIcon: null,
        languageIcon: null,
        elements: [{
            programName: "string",
            age: "17+",
            languageLevel: "string",
            price: "string"
        }],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "programName",
                        type: "String",
                        name: "Program Name",
                    },
                    {
                        id: "age",
                        type: "String",
                        name: "Age",
                    },
                    {
                        id: "languageLevel",
                        type: "String",
                        name: "Language Level",
                    },
                    {
                        id: "price",
                        type: "String",
                        name: "Price",
                    },
                ]
            }
        },
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "ageIcon",
                type: "Custom",
                customType: "Image",
                name: "Age Icon",
            },
            {
                id: "languageIcon",
                type: "Custom",
                customType: "Image",
                name: "Language Icon",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ]
    }
}

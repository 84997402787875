import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./WithUsBlock.module.css";
import arrow from "./Arrow.svg"
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface WithUsBlockElement {
    title: string;
    highlight: string;
    texts: {
      text: string
    }[];
    bottomText: string
    bottomTextLink: string
    img: number | null;
}

const normalizeText = (text: string): string => {
    return text.toLowerCase().replace(/\s+/g, ' ').trim();
};

const highlightMatch = (title: string, highlight: string): JSX.Element[] => {
    if (!highlight) return [<span key="0">{title}</span>];

    const normalizedTitle = normalizeText(title);
    const normalizedHighlight = normalizeText(highlight);

    const regex = new RegExp(`(${normalizedHighlight.split(' ').join('\\s*')})`, "gi");
    const parts = normalizedTitle.split(regex);

    let originalIndex = 0;

    return parts.map((part, index) => {
        const originalPart = title.substring(originalIndex, originalIndex + part.length);
        originalIndex += part.length;

        return regex.test(part) ? (
            <span key={index} style={{backgroundColor: "#FA5354", color: "#FFFFFF"}}>
                {originalPart}
            </span>
        ) : (
            <span key={index}>{originalPart}</span>
        );
    });
};

export const WithUsBlock = (props: WithUsBlockElement) => {
    return (
      <div style={{ backgroundColor: "#F3D7E3" }}>
        <div className="flex flex-col-reverse lg:flex-row justify-between mx-auto w-full max-w-screen-xl lg:gap-8">
          <div className="mx-auto w-full lg:w-6/12 flex flex-col gap-5 lg:justify-between md:px-6 flex flex-col items-center lg:items-start py-8 lg:py-12">
            <div className="flex flex-col items-center lg:items-start">
              <h2 className={cn(style.title, "w-10/12 md:w-8/12 lg:w-full")}>{highlightMatch(props.title, props.highlight)}</h2>
              <p className={cn(style.main_text, "w-10/12 lg:w-full")}>
                {props.texts && props.texts.map((text) => (
                  <p className={"mb-4"}>{text.text}</p>
                ))}
              </p>
            </div>
            <div className={cn(style.main_text, "mb-4 lg:mb-0 w-10/12")}>
              {props.bottomText &&
                <a href={`${props.bottomTextLink}`} className={cn("mt-2 lg:mt-0 flex gap-2 font-semibold justify-center lg:justify-start")}>
                  {props.bottomText} <img src={arrow} alt=""/>
                </a>}
            </div>
          </div>
          <div className="w-full lg:w-6/12 mb-16 lg:mb-0">
            {props.img && <img className={`w-full h-full object-cover object-center ${style.image}`} src={`${ApiBaseUrl}/api/media/${props.img}`} alt="" />}
          </div>
        </div>
      </div>
    );
}

export const WithUsBlockInfo: TypedBlockTypeInfo<WithUsBlockElement> = {
    id: "WithUsBlock",
    name: "WithUsBlock",
    preview: preview,
    renderer: WithUsBlock,
    initialData: {
        title: "string",
        highlight: "string",
        texts: [{
          text: "string"
        }],
        bottomText: "string",
        bottomTextLink: "#",
        img: null,
    },
    definition: {
        subTypes: {
          text: {
            fields: [
              {
                id: "text",
                type: "String",
                name: "Text",
              }
            ]
          }
        },
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "highlight",
                type: "String",
                name: "Highlight"
            },
            {
                id: "texts",
                type: "List",
                listType: "text",
                name: "Texts",
            },
            {
                id: "bottomText",
                type: "String",
                name: "Bottom Text",
            },
            {
              id: "bottomTextLink",
              type: "String",
              name: "Bottom Text Link",
            },
            {
              id: "img",
              type: "Custom",
              customType: "Image",
              name: "Img",
            },
        ],
    },
};

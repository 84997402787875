import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./ListServiceBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface ListServiceBlockElement {
    header: string;
    img: number | null;
    listTop: { textTop: string }[];
    titleBottom: string;
    listBottom: { textBottom: string }[];
}

export const ListServiceBlock: React.FC<ListServiceBlockElement> = ({ header, img, listTop, titleBottom, listBottom }) => {
    return (
        <div className={cn(style.container, "mb-28 md:mb-32 lg:mb-40")}>
            <div className={cn(`flex flex-col lg:flex-row justify-between mx-auto w-full max-w-screen-xl`, style.components)}>
                {img && <img src={`${ApiBaseUrl}/api/media/${img}`} className={cn("w-full lg:w-6/12 object-cover object-center")} alt="" />}
                <div className={cn(style.content, "flex flex-col justify-around")}>
                    <div className="w-full">
                        <h2>{header}</h2>
                        <ul>
                            {listTop.map((item, index) => (
                                <li key={index}>{item.textTop}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="w-full">
                        <h3>{titleBottom}</h3>
                        <ul>
                            {listBottom.map((item, index) => (
                                <li key={index}>{item.textBottom}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ListServiceBlockInfo: TypedBlockTypeInfo<ListServiceBlockElement> = {
    id: "ListServiceBlock",
    name: "ListServiceBlock",
    preview: preview,
    renderer: ListServiceBlock,
    initialData: {
        header: "string",
        img: null,
        listTop: [
            {
                textTop: "string",
            },
        ],
        titleBottom: "string",
        listBottom: [
            {
                textBottom: "string"
            },
        ],
    },
    definition: {
        subTypes: {
            listTop: {
                fields: [
                    {
                        id: "textTop",
                        name: "Text",
                        type: "String",
                    },
                ],
            },
            listBottom: {
                fields: [
                    {
                        id: "textBottom",
                        name: "Text",
                        type: "String",
                    }
                ]
            }
        },
        fields: [
            {
                id: "header",
                type: "String",
                name: "Header",
            },
            {
                id: "listTop",
                name: "List Top",
                type: "List",
                listType: "listTop",
            },
            {
                id: "img",
                name: "Image",
                type: "Custom",
                customType: "Image",
            },
            {
                id: "titleBottom",
                name: "Title Bottom",
                type: "String",
            },
            {
                id: "listBottom",
                name: "List Bottom",
                type: "List",
                listType: "listBottom",
            },
        ],
    },
};

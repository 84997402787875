import React, { useContext, useEffect } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./AdditionalInformationBlock.module.css";
import cn from "classnames";
import { Slider } from "../../ui/Slider/Slider";
import { ComponentHostContext } from "../index";

export interface AdditionalInformationBlockElement {
    title: string;
    subtitle: string;
    elements: { title: string; text: string }[];
    textButton: string;
    bottomItems: { name: string }[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

const splitTitle = (title: string) => {
    const parts = title.split(' ');
    if (parts.length > 1) {
        return (
            <span>
                {parts[0]} <span className={styles.additionalInformation__elementHeader}>{parts.slice(1).join(' ')}</span>
            </span>
        );
    }
    return <span>{title}</span>;
};

export const AdditionalInformationBlock = (props: AdditionalInformationBlockElement) => {
    useEffect(() => {
        const headerElement = document.getElementById("header_nav");
        const updateHeaderColor = () => {
            if (headerElement) {
                if (window.innerWidth <= 1024) {
                    headerElement.classList.add(styles.blueBackground);
                } else {
                    headerElement.classList.remove(styles.blueBackground);
                }
            }
        };
        updateHeaderColor();
        window.addEventListener("resize", updateHeaderColor);
        return () => {
            window.removeEventListener("resize", updateHeaderColor);
        };
    }, []);
    const cl = useContext(ComponentHostContext);
    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={cn(styles.additionalInformation__body)} style={marginStyles}>
            <div className="flex justify-between flex-col lg:flex-row lg:items-start max-w-screen-xl h-full w-full my-0 mx-auto p-9 pt-20 lg:py-20">
                <div className="flex flex-col md:h-full justify-start gap-10 md:gap-5 md:justify-between items-center lg:items-start">
                    <div className={cn(styles.additionalInformation__title)} dangerouslySetInnerHTML={{
                        __html: props.title
                    }} />
                    <div className={cn(styles.additionalInformation__subtitle, "w-3/5 md:w-4/5 lg:w-4/6")} dangerouslySetInnerHTML={{
                        __html: props.subtitle
                    }} />
                    <button className={cn(styles.additionalInformation__button)} onClick={() => cl?.showContactUsFormServicePink()} dangerouslySetInnerHTML={{
                        __html: props.textButton
                    }} />
                </div>
                <div className={cn(styles.additionalInformation__elements, "hidden md:flex lg:flex-col justify-between lg:h-full")}>
                    {props.elements.map((el, index) => (
                        <div key={index} className={cn(styles.additionalInformation__element)}>
                            <div className={styles.additionalInformation__elementHeader} dangerouslySetInnerHTML={{
                                __html: el.title
                            }} />
                            <div className={styles.additionalInformation__elementText} dangerouslySetInnerHTML={{
                                __html: el.text
                            }} />
                        </div>
                    ))}
                </div>
                <div className={cn(styles.sliderContainer, "flex my-8 text-center md:hidden")}>
                    <Slider>
                        {props.elements.map((el, index) => (
                            <div key={index} className={styles.slider__element}>
                                <div className={styles.additionalInformation__elementHeader} dangerouslySetInnerHTML={{
                                    __html: el.title
                                }} />

                                <div className={styles.additionalInformation__elementText} dangerouslySetInnerHTML={{
                                    __html: el.text
                                }} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            <div className={cn("w-full overflow-x-auto", styles.bottomContainer)}>
                <div className={cn("flex justify-center gap-2 flex-nowrap px-4", styles.scrollContainer)} style={{ minWidth: "max-content" }}>
                    {props.bottomItems.map((item, index) => (
                        <div key={index} className={styles.bottomItem}
                            onClick={() => {
                                const target = document.getElementById(item.name.replace(/\s+/g, '-').toLowerCase());
                                if (target) {
                                    target.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                }
                            }}>
                            {item.name.toUpperCase()}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export const AdditionalInformationBlockInfo: TypedBlockTypeInfo<AdditionalInformationBlockElement> = {
    id: "AdditionalInformationBlock",
    name: "Additional Information",
    preview: preview,
    renderer: AdditionalInformationBlock,
    initialData: {
        title: "string",
        subtitle: "string",
        elements: [{
            title: "string",
            text: "string"
        }],
        textButton: "string",
        bottomItems: [{
            name: "string"
        }],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "title",
                        type: "Custom",
                        customType: "Html",
                        name: "Title"
                    },
                    {
                        id: "text",
                        type: "Custom",
                        customType: "Html",
                        name: "Text"
                    },
                ],
            },
            bottomItem: {
                fields: [
                    {
                        id: "name",
                        type: "String",
                        name: "Name"
                    }
                ]
            }
        },
        fields: [
            {
                id: "title",
                type: "Custom",
                customType: "Html",
                name: "Title",
            },
            {
                id: "subtitle",
                type: "Custom",
                customType: "Html",
                name: "Subtitle",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements"
            },
            {
                id: "textButton",
                type: "Custom",
                customType: "Html",
                name: "Text button",
            },
            {
                id: "bottomItems",
                type: "List",
                listType: "bottomItem",
                name: "Bottom Items"
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ]
    }
}

import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./SolidTeamBlock.module.css";
import preview from "./preview.png";

export interface SolidTeamBlockElement {
  title: string;
  text: string;
  highlightedText: string;
  subtext: string;
  hideButton?: boolean;
  buttonText: string;
  buttonLink: string;
  videoLink: string;
  topMargin?: string;
  bottomMargin?: string;
  customMargin?: boolean;
}

export const SolidTeamBlock = ({
  title,
  text,
  subtext,
  highlightedText,
  buttonText,
  buttonLink,
  videoLink,
  hideButton,
  topMargin,
  bottomMargin,
  customMargin
}: SolidTeamBlockElement) => {
  const textBefore = text.slice(0, text.indexOf(highlightedText));
  const textAfter =
    textBefore.length !== text.length ? text.slice(text.indexOf(highlightedText) + highlightedText.length) : "";
  const marginStyles = customMargin
    ? {
      marginTop: topMargin ? `${topMargin}px` : undefined,
      marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
    }
    : {};
  return (
    <div className="my-12" style={marginStyles}>
      <div className={styles.solidTeamBlock__title + " text-center mx-auto md:mb-6 mb-3"}>{title}</div>
      <div className={styles.solidTeamBlock__text + " text-center"}>
        {textBefore}
        {highlightedText && <span>{highlightedText}</span>}
        {textAfter}
      </div>
      <div className={styles.solidTeamBlock__subtext + " text-center md:mb-6 mb-3"}>{subtext}</div>
      {!hideButton && (
        <div className="flex justify-center md:mt-6">
          <a href={buttonLink} className={styles.solidTeamBlock__button}>
            <span>{buttonText}</span>
          </a>
        </div>
      )}
      {videoLink && (
        <div className={styles.solidTeamBlock__video}>
          <div style={{ minWidth: "100%" }}>
            <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
              <iframe
                src={videoLink}
                allow="autoplay; encrypted-media; screen-wake-lock"
                allowFullScreen
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const SolidTeamBlockInfo: TypedBlockTypeInfo<SolidTeamBlockElement> = {
  id: "SolidTeamBlock",
  name: "Solid Team",
  preview: preview,
  renderer: SolidTeamBlock,
  initialData: {
    title: "string",
    text: "this is string, not a number",
    highlightedText: "string",
    subtext: "string",
    hideButton: false,
    buttonText: "string",
    buttonLink: "#",
    videoLink: "",
    topMargin: "",
    bottomMargin: "",
    customMargin: false,
  },
  definition: {
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "text",
        type: "String",
        name: "Text",
      },
      {
        id: "highlightedText",
        type: "String",
        name: "Highlighted Text",
      },
      {
        id: "subtext",
        type: "String",
        name: "Subtext",
      },
      {
        id: "hideButton",
        type: "CheckBox",
        name: "Hide Button",
      },
      {
        id: "buttonText",
        type: "String",
        name: "Button Text",
      },
      {
        id: "buttonLink",
        type: "String",
        name: "Button Link",
      },
      {
        id: "videoLink",
        type: "String",
        name: "Video Link",
      },
      {
        id: "customMargin",
        type: "CheckBox",
        name: "Custom Margin",
      },
      {
        id: "topMargin",
        type: "String",
        name: "Top Margin (px)",
      },
      {
        id: "bottomMargin",
        type: "String",
        name: "Bottom Margin (px)",
      },
    ],
  },
};

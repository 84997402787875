import React, { useRef } from "react";
import styles from "./ReviewsIntroBlock.module.css";
import preview from "./preview.png";
import cn from "classnames";
import { ApiBaseUrl } from "../../../api/apiClientBase";
import { PageTraitDto } from "web-app/src/interfaces/pagesDto";
import fr from "../../../assets/icons/flags/fr.svg";
import { useIntl } from "react-intl";
import { useEffect, useState, useContext } from "react";
import { useRouter } from "next/router";
import { chooseFlag } from "../../UniversityOrSchoolBlock/flags";
import { type } from "os";
import { BlockApiClient } from "../../../api/blockApiClient";
import { useLocation } from "react-router-dom";
import { ComponentHostContext } from "../../index";
import Arrow from "../../../assets/icons/white_arrow.svg";
import Slider from "../../../ui/Slider/Slider";
import Calendar from "../../../assets/icons/calendar-2.svg";
import Qoute from "../../../assets/icons/qoute.svg";

export interface ReviewsIntroBlockElement {
    name: string;
    logo: number;
    avatar: number;
    description: string;
    date: string;
    review: string;
    qoute: string;
    tags: PageTraitDto[] | null;
    gallery: {
        videoLink: string;
        image: number | null;
    }[];
    cityName: string;
    cityFlag: string;
    countPhoto: string;
    countVideo: string;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export interface CityType {
    info?: string | null,
    namesJson: { en?: string, cn?: string, esp?: string, fr?: string, ru?: string }
}

interface AdminFlagData {
    names: string[];
}

interface GroupedAdminFlags {
    [key: string]: AdminFlagData;
}

const apiClient = new BlockApiClient();

function groupFlagsByInfo(flags: CityType[], lang: string) {
    return flags?.reduce((acc, flag) => {
        const infoKey = flag.info;
        const nameValue = flag.namesJson[lang as keyof typeof flag.namesJson];

        if (!infoKey || !nameValue) return acc;

        if (!acc[infoKey]) {
            acc[infoKey] = { names: [] };
        }

        acc[infoKey].names.push(nameValue);
        return acc;
    }, {} as Record<string, { names: string[] }>);
}

export const ReviewsIntroBlock: React.FC<ReviewsIntroBlockElement> = ({ name, logo, avatar, description, date, review, qoute, tags, gallery, topMargin, bottomMargin, customMargin }) => {
    const router = useRouter()
    const lang = useIntl().locale || "en";
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);

    const useSafeLocation = () => {
        try {
            return useLocation();
        } catch (error) {
            return null;
        }
    };

    const location = useSafeLocation();

    const [groupedAdminFlags, setGroupedAdminFlags] = useState<GroupedAdminFlags>({})

    useEffect(() => {
        if (!location) {
            return;
        }

        const fetchData = async () => {
            try {
                const match = location?.pathname.match(/\d+$/);
                const result = await apiClient.getAdminCities(match ? match[0] : '');
                setGroupedAdminFlags(groupFlagsByInfo(result, lang));
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [location]);

    let flags: CityType[] = []
    let groupedFlags: GroupedAdminFlags = {}
    const { query } = router || {};
    const cl = useContext(ComponentHostContext);
    const routePath = Array.isArray(query?.route) ? query.route.join("/") : query?.route || "";
    const encodedRoutePath = encodeURIComponent(routePath);
    flags = cl?.filters.useCities(encodedRoutePath) || [];

    console.log("flags", flags)
    groupedFlags = groupFlagsByInfo(flags, lang);

    const [activeFlag, setActiveFlag] = useState<string | null>(null);

    const handleFlagClick = (infoKey: string) => {
        setActiveFlag(activeFlag === infoKey ? null : infoKey);
    };

    useEffect(() => {
        const headerElement = document.getElementById("header_nav");
        const updateHeaderColor = () => {
            if (headerElement) {
                if (window.innerWidth <= 1024) {
                    headerElement.classList.add(styles.transparentBackground)
                } else {
                    headerElement.classList.remove(styles.transparentBackground)
                }
            }
        }
        updateHeaderColor()
        window.addEventListener("resize", updateHeaderColor)
        return () => {
            window.removeEventListener("resize", updateHeaderColor)
        }
    }, [])

    const cityInfoKey = Object.keys(groupedFlags || groupedAdminFlags || {})[0];
    const cityName = cityInfoKey ? groupedFlags?.[cityInfoKey]?.names[0] || groupedAdminFlags?.[cityInfoKey]?.names[0] : "";
    const cityFlag = cityInfoKey ? chooseFlag(cityInfoKey) : "";
    console.log(cityFlag, " ", cityName)

    const openModal = (imageUrl: string, index: number) => {
        setSelectedImage(imageUrl);
        setCurrentIndex(index);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setCurrentIndex(null);
    };

    const showNextImage = () => {
        if (currentIndex !== null && gallery.length > 0) {
            const nextIndex = (currentIndex + 1) % gallery.length;
            const nextImage = gallery[nextIndex].image ? `${ApiBaseUrl}/api/media/${gallery[nextIndex].image}` : null;
            if (nextImage) {
                setSelectedImage(nextImage);
                setCurrentIndex(nextIndex);
            }
        }
    };

    const showPrevImage = () => {
        if (currentIndex !== null && gallery.length > 0) {
            const prevIndex = (currentIndex - 1 + gallery.length) % gallery.length;
            const prevImage = gallery[prevIndex].image ? `${ApiBaseUrl}/api/media/${gallery[prevIndex].image}` : null;
            if (prevImage) {
                setSelectedImage(prevImage);
                setCurrentIndex(prevIndex);
            }
        }
    };
    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft -= 360;
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 360;
        }
    };

    const marginStyles = customMargin
        ? {
            marginTop: topMargin ? `${topMargin}px` : undefined,
            marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={cn("my-12 flex flex-col items-center justify-between max-w-screen-xl w-full mx-auto px-4 md:px-6")} style={marginStyles}>
            <div className={cn("w-full", styles.containerTop)}>
                <div className="flex flex-row justify-between w-full">
                    <div className="flex flex-col md:flex-row items-start md:items-center w-full">
                        <div className="flex md:hidden flex-row justify-between w-full items-start ">
                            {avatar && <img src={`${ApiBaseUrl}/api/media/${avatar}`} className={cn(styles.avatar)} />}
                            {logo && <img src={`${ApiBaseUrl}/api/media/${logo}`} className={cn(styles.logo)} />}
                        </div>
                        {avatar &&
                            <img src={`${ApiBaseUrl}/api/media/${avatar}`} className={cn(styles.avatar, "hidden md:flex")} />
                        }
                        <div className="flex flex-col">
                            <span dangerouslySetInnerHTML={{ __html: name }} className={cn(styles.name)} />
                            <span dangerouslySetInnerHTML={{ __html: description }} className={cn(styles.description, "mb-3 lg:mb-4")} />
                            <div className="flex flex-row">
                                <div className="flex flex-row items-center">
                                    <img src={Calendar} width={16} height={16} alt="date" className="mr-2" />
                                    <span dangerouslySetInnerHTML={{ __html: date }} className={cn(styles.date, "mr-6")} />
                                </div>
                                {groupedFlags && typeof groupedFlags === 'object' && Object.keys(groupedFlags).length > 0 ? (
                                    <div>
                                        {Object.entries(groupedFlags || {}).map(([infoKey, data]) => (
                                            <div
                                                key={infoKey}
                                                className={cn("flex flex-row items-center")}
                                                onClick={() => handleFlagClick(infoKey)}
                                            >
                                                <div>
                                                    <img
                                                        src={chooseFlag(infoKey) || fr}
                                                        width={16} height={16}
                                                        className={cn("mr-2")}
                                                        alt="Flag Icon"
                                                    />
                                                </div>
                                                <div>
                                                    {data.names.map((name, index) => (
                                                        <React.Fragment key={index}>
                                                            <span className={cn(styles.city)}>{name}</span>
                                                            {index < data.names.length - 1 && (
                                                                <span className="font-bold"> · </span>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : groupedAdminFlags && typeof groupedAdminFlags === 'object' && Object.keys(groupedAdminFlags).length > 0 ? (
                                    <div>
                                        {Object.entries(groupedAdminFlags || {}).map(([infoKey, data]) => (
                                            <div
                                                key={infoKey}
                                                className={cn()}
                                                onClick={() => handleFlagClick(infoKey)}
                                            >
                                                <div>
                                                    <img
                                                        src={chooseFlag(infoKey) || fr}
                                                        alt="Flag Icon"
                                                    />
                                                </div>
                                                <p>
                                                    {data.names.map((name, index) => (
                                                        <React.Fragment key={index}>
                                                            {name}
                                                            {index < data.names.length - 1 && (
                                                                <span className="font-bold"> · </span>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className={cn("hidden md:flex flex-col items-end lg:justify-end", styles.tagsContainer)}>
                        {logo &&
                            <img src={`${ApiBaseUrl}/api/media/${logo}`} />
                        }
                        <div className={cn("h-4/6 hidden lg:flex flex-row flex-wrap items-end content-end gap-2 justify-end")}>
                            {tags &&
                                tags.map((tag, i) => (
                                    <span className={cn(styles.tags)} key={tag.id + i}>
                                        {tag.name}
                                    </span>
                                ))}
                        </div>
                    </div>
                </div>
                <div className={cn("h-4/6 lg:hidden flex w-full flex-row flex-wrap items-end content-end gap-2")} style={{ marginTop: "30px" }}>
                    {tags &&
                        tags.map((tag, i) => (
                            <span className={cn(styles.tags)} key={tag.id + i}>
                                {tag.name}
                            </span>
                        ))}
                </div>
            </div>
            <div className={cn("flex flex-col lg:flex-row", styles.containerBottom)}>
                <div className="lg:w-2/5 flex flex-col justify-between">
                    <div>
                        <img src={Qoute} width={40} height={40} alt="qoute" className="mb-4 hidden lg:flex" />
                        <span dangerouslySetInnerHTML={{ __html: qoute }} className={cn(styles.qoute)} />
                    </div>
                    <div className={"hidden lg:flex gap-5 justify-start mt-12"}>
                        <button onClick={scrollLeft} className={styles.ReviewsIntroBlock__arrowButton}><img src={Arrow} alt="left button" /></button>
                        <button onClick={scrollRight} className={cn("transform rotate-180", styles.ReviewsIntroBlock__arrowButton)}>
                            <img src={Arrow} alt="right button" />
                        </button>
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: review }} className={cn("lg:w-3/5", styles.review)} />
            </div>
            <div ref={scrollContainerRef} className={cn("hidden md:flex justify-start gap-8" +
                " w-full mx-auto", styles.scrollContainer)}>
                {gallery &&
                    gallery.map(({ videoLink, image }, index) => (
                        <div
                            className={cn(styles.ReviewsIntroBlock__element, "overflow-x-auto")}
                            key={index}
                        >
                            {videoLink && (
                                <iframe
                                    src={videoLink}
                                    allow="autoplay; encrypted-media; screen-wake-lock"
                                    allowFullScreen
                                    style={{ width: "100%", height: "100%" }}
                                ></iframe>
                            )}
                            {image && (
                                <img src={`${ApiBaseUrl}/api/media/${image}`} width={"100%"} height={"100%"} alt="" onClick={() => openModal(`${ApiBaseUrl}/api/media/${image}`, index)} />
                            )}
                        </div>
                    ))}
            </div>
            {selectedImage && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
                    <button className={cn("absolute top-2 right-2 bg-white text-black rounded-full px-3 py-1 text-lg", styles.closeModal)} onClick={closeModal}>✖</button>
                    <button className={cn("absolute left-5 md:left-12 lg:left-40 bg-white text-black rounded-full p-3 text-2xl", styles.arrowModal)} onClick={showPrevImage}><img src={Arrow} alt="left button" style={{ filter: "invert(100%)" }} /></button>

                    <img src={selectedImage} alt="Full Size" className="max-w-1/2 rounded-lg shadow-lg" style={{ height: "90vh", width: "70vw" }} />

                    <button className={cn("absolute right-5 md:right-12 lg:right-40 bg-white text-black rounded-full p-3 text-2xl transform rotate-180", styles.arrowModal)} onClick={showNextImage}> <img src={Arrow} alt="right button" style={{ filter: "invert(100%)" }} /></button>
                </div>
            )}
            <div className={cn("flex my-8 mx-auto md:hidden")}>
                <Slider backgroundColor="white">
                    {gallery &&
                        gallery.map(({ videoLink, image }, index) => (
                            <div
                                className={cn("overflow-hidden h-44 w-full flex items-center")}
                                key={index}
                            >
                                {videoLink && (
                                    <iframe
                                        src={videoLink}
                                        allow="autoplay; encrypted-media; screen-wake-lock"
                                        allowFullScreen
                                        style={{ width: "100%", height: "100%" }}
                                    ></iframe>
                                )}
                                {image && (
                                    <img src={`${ApiBaseUrl}/api/media/${image}`} width={"100%"} height={"100%"} alt="" className={cn("object-cover object-center")} onClick={() => openModal(`${ApiBaseUrl}/api/media/${image}`, index)} />
                                )}
                            </div>
                        ))}
                </Slider>
            </div>
        </div>
    );
};

export const ReviewsIntroBlockInfo = {
    id: "ReviewsIntroBlock",
    name: "Review Intro",
    preview: preview,
    renderer: ReviewsIntroBlock,
    initialData: {
        name: "",
        logo: null,
        avatar: null,
        description: "",
        date: "",
        review: "",
        qoute: "",
        gallery: [
            {
                videoLink: "",
                image: null,
            },
        ],
        countPhoto: "",
        countVideo: "",
        tags: [],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            tagsLinks: {
                fields: [
                    {
                        id: "name",
                        type: "String",
                        name: "Name",
                    },
                    {
                        id: "link",
                        type: "String",
                        name: "Link",
                    },
                ],
            },
            gallery: {
                fields: [
                    {
                        id: "videoLink",
                        type: "String",
                        name: "Video Link",
                    },
                    {
                        id: "image",
                        name: "Image",
                        type: "Custom",
                        customType: "Image",
                    },
                ]
            }
        },
        fields: [
            {
                id: "name",
                type: "Custom",
                customType: "Html",
                name: "Name",
            },
            {
                id: "logo",
                name: "Logo",
                type: "Custom",
                customType: "Image",
            },
            {
                id: "avatar",
                name: "Avatar",
                type: "Custom",
                customType: "Image",
            },
            {
                id: "description",
                type: "Custom",
                customType: "Html",
                name: "Description",
            },
            {
                id: "date",
                type: "Custom",
                customType: "Html",
                name: "Date",
            },
            {
                id: "review",
                type: "Custom",
                customType: "Html",
                name: "Review",
            },
            {
                id: "qoute",
                type: "Custom",
                customType: "Html",
                name: "Qoute",
            },
            {
                id: "gallery",
                name: "Gallery",
                type: "List",
                listType: "gallery",
            },
            {
                id: "countPhoto",
                type: "String",
                name: "Count Photo (for preview)",
            },
            {
                id: "countVideo",
                type: "String",
                name: "Count Video (for preview)",
            },
            {
                id: "tags",
                name: "Tags",
                type: "List",
                listType: "tagsLinks",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};

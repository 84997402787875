import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./CourseProgramsBlock.module.css";
import cn from "classnames";
import Date from "./Date.svg"
import Flag from "./Flag.svg"
import Language from "./Language.svg"
import Person from "./Person.png"
import Timing from "./Timing.svg"

export interface CourseProgramsBlockElement {
  title: string;
  text: string;
  elements: {
    programName: string,
    level: string,
    start: string,
    duration: string,
    age: string,
    price: string,
    lessons: string,
  }[];
  button: boolean;
  buttonText: string;
  buttonLink: string
}

export const CourseProgramsBlock = (props: CourseProgramsBlockElement) => {
  const formatPrice = (price: string) => {
    const match = price.match(/([€$£¥]\s?\d+([.,-]\d+)?)/);
    if (match) {
      const [matchedText] = match;
      const [before, after] = price.split(matchedText);
      return (
        <>
          {before}
          <strong className={cn(styles.courseProgramsBlock__price)}>{matchedText}</strong>
          {after}
        </>
      );
    }
    return price;
  };
  return (
    <div className="flex flex-col py-12 px-4 md:px-12 lg:px-20 max-w-screen-xl justify-center gap-10 mx-auto my-0 ">
      <div className={"flex flex-col gap-7 items-center text-center"}>
        <div className={cn(styles.courseProgramsBlock__title)}>
          {props.title}
        </div>
        <div className={cn(styles.courseProgramsBlock__topText, "mb-8")}>
          {props.text}
        </div>
        <div className={cn("flex flex-col w-full mb-5")}>
          <hr className={cn("mb-5 w-full")}/>
          {props.elements && props.elements.map((element) =>
            <div className={cn("flex flex-col")}>
              <div className={cn("flex flex-col md:flex-row gap-5 md:gap-0 md:justify-between")}>
                <div className={cn("flex flex-col md:flex-row justify-between w-full ", styles.courseProgramsBlock__table)}>
                  <div className={cn(styles.courseProgramsBlock__programName, "self-start ")}>{element.programName}</div>
                  <div className={"flex flex-col lg:flex-row w-full lg:w-full gap-4 lg:gap-12"}>
                    <div className={"flex flex-col gap-4 w-full lg:w-7/12"}>
                      <div className={cn("flex gap-2 self-start w-full", styles.courseProgramsBlock__row)}>
                        <img src={Language} alt="" className={cn(styles.courseProgramsBlock__icon)}/>
                        <p className={cn(styles.courseProgramsBlock__text, "self-start flex")}>{element.level}</p>
                      </div>
                      <div className={cn("flex gap-2 self-start", styles.courseProgramsBlock__row)}>
                        <img src={Flag} alt="" className={cn(styles.courseProgramsBlock__icon)}/>
                        <p className={cn(styles.courseProgramsBlock__text, "self-start")}>{element.start}</p>
                      </div>
                    </div>
                    <div className={"flex flex-col gap-4 w-full lg:w-2/4"}>
                      <div className={cn("flex gap-2 w-full", styles.courseProgramsBlock__row)}>
                        <img src={Person} alt="" className={cn(styles.courseProgramsBlock__icon)}/>
                        <p className={cn(styles.courseProgramsBlock__text, "self-start")}>{element.age}</p>
                      </div>
                      <div className={cn("flex gap-2 w-full", styles.courseProgramsBlock__row)}>
                        <img src={Date} alt="" className={cn(styles.courseProgramsBlock__icon)}/>
                        <p className={cn(styles.courseProgramsBlock__text, "self-start")}>{element.duration}</p>
                      </div>
                    </div>
                  </div>
                  <div className={"flex flex-col gap-4 w-7/12 lg:w-2/4"}>
                    <p className={cn(styles.courseProgramsBlock__aroundPrice, "self-start")}>{formatPrice(element.price)}</p>
                    <div className={cn("flex gap-2", styles.courseProgramsBlock__row)}>
                      <img src={Timing} alt="" className={cn(styles.courseProgramsBlock__icon)}/>
                      <p className={cn(styles.courseProgramsBlock__text, styles.courseProgramsBlock__blueText, "self-start")}>{element.lessons}</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr className={cn("my-5 w-full")}/>
            </div>
          )}
        </div>
        {props.button &&
          <div className={"flex w-full justify-center"}>
            <a type={"button"} href={`/${props.buttonLink}`} className={styles.courseProgramsBlock__button}>{props.buttonText}</a>
          </div>
        }
      </div>
    </div>
  )
}

export const CourseProgramsBlockInfo: TypedBlockTypeInfo<CourseProgramsBlockElement> = {
  id: "CourseProgramsBlock",
  name: "CourseProgramsBlock",
  preview: preview,
  renderer: CourseProgramsBlock,
  initialData: {
    title: "string",
    text: "string",
    elements: [{
      programName: "string",
      level: "string",
      start: "string",
      duration: "string",
      age: "string",
      price: "string",
      lessons: "string",
    }],
    button: false,
    buttonText: "",
    buttonLink: "#"
  },
  definition: {
    subTypes: {
      element: {
        fields: [
          {
            id: "programName",
            type: "String",
            name: "Program Name",
          },
          {
            id: "level",
            type: "String",
            name: "Level",
          },
          {
            id: "start",
            type: "String",
            name: "Start",
          },
          {
            id: "duration",
            type: "String",
            name: "Duration",
          },
          {
            id: "age",
            type: "String",
            name: "Age",
          },
          {
            id: "price",
            type: "String",
            name: "Price",
          },
          {
            id: "lessons",
            type: "String",
            name: "Lessons",
          },
        ]
      }
    },
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "text",
        type: "String",
        name: "Text",
      },
      {
        id: "elements",
        type: "List",
        listType: "element",
        name: "Elements",
      },
      {
        id: "button",
        type: "CheckBox",
        name: "Button",
      },
      {
        id: "buttonText",
        type: "String",
        name: "Button Text",
      },
      {
        id: "buttonLink",
        type: "String",
        name: "Button Link",
      },
    ]
  }
}

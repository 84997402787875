"use client";

import { YMapMarkerProps, YMapProps, YMap as YMapType, LngLat, LngLatBounds } from "@yandex/ymaps3-types";
import React, { PropsWithChildren, ReactNode, forwardRef, useEffect, useRef, useState } from "react";
import { useMapComponents } from "../../utils/MapComponentsProvider";
import cn from "classnames";
import styles from "./Map.module.css";

export interface MapProps extends YMapProps, PropsWithChildren<any> {
  isGeolocationEnabled?: boolean;
  onGeolocatePosition?: (posititon: LngLat) => void;
  children?: ReactNode;
  onResize?: (asdf: any) => void;
  onZoomChange?: (zoom: number) => void;
  onBoundsChange?: (bounds: LngLatBounds) => void;
  onCenterChange?: (center: LngLat) => void;
}

export type MapRef = YMapType;

export * from "../../utils/MapComponentsProvider";

export const Map = forwardRef<MapRef, MapProps>(
  ({ onResize, onZoomChange, onBoundsChange, onCenterChange, children, className, isGeolocationEnabled = true, onGeolocatePosition, ...props }, ref) => {
    
    const prevZoomRef = useRef<number | null>(null);
    const components = useMapComponents();
    
    if (!components) {
      return null;
    }

    const {
      YMap,
      YMapControls,
      YMapDefaultFeaturesLayer,
      YMapDefaultSchemeLayer,
      YMapGeolocationControl,
      YMapZoomControl,
      YMapListener,
    } = components;

    const handleUpdate = (event: any) => {
      if (ref && 'current' in ref && ref.current) {
        const mapInstance = ref.current;
        
        if (onZoomChange && typeof mapInstance.zoom !== 'undefined') {
          const currentZoom = Math.round(mapInstance.zoom);
          if (prevZoomRef.current !== currentZoom) {
            prevZoomRef.current = currentZoom;
            onZoomChange(currentZoom);
          }
        }
        if (onBoundsChange && mapInstance.bounds) {
          onBoundsChange(mapInstance.bounds);
        }

        if (onCenterChange && mapInstance.center) {
          onCenterChange([...mapInstance.center] as LngLat);
        }
      }
    };

    const handleResize = (event: any) => {
      console.log('Resize event:', event); 
      if (onResize) {
        onResize(event);
      }
    };

    return (
      <div className={cn("relative overflow-hidden", styles.map, className)}>
        <YMap {...props} ref={ref}>
          <YMapDefaultSchemeLayer />
          <YMapDefaultFeaturesLayer />

          <YMapControls orientation={"vertical"} position={"right"}>
            {isGeolocationEnabled ? <YMapGeolocationControl onGeolocatePosition={onGeolocatePosition} /> : null}
            <YMapZoomControl />
          </YMapControls>
          
          {children}
          <YMapListener 
            onUpdate={handleUpdate}
            onResize={handleResize}
          />
        </YMap>
      </div>
    );
  }
);

Map.displayName = "Map";

export interface MapPointProps extends YMapMarkerProps {
  info?: ReactNode;
  shortInfo?: ReactNode;
  isActive?: boolean;
  zIndex?: number;
  centerPin?: boolean;
  clusterStyle?: React.CSSProperties;
}

export function MapPoint({ zIndex = 1, shortInfo, info, isActive = false, centerPin = false, clusterStyle, ...props }: MapPointProps) {
  const components = useMapComponents();

  if (!components) {
    return null;
  }

  const { YMapMarker } = components;

  const markerClass = cn(styles.marker, {
    [styles.active]: info && isActive,
    [styles.centerPin]: centerPin,
    [`${info && !isActive ? "cursor-pointer" : ""}`]: true,
  });
  
  return (
    <YMapMarker zIndex={isActive ? 999 : 1} {...props}>
      <div style={clusterStyle}>
        <div className={cn(markerClass)} />
        {shortInfo && !isActive ? <div className={styles.shortInfo}>{shortInfo}</div> : null}
        {info && isActive ? <div className={styles.shortInfo}>{info}</div> : null}
      </div>
    </YMapMarker>
  );
}
export default Map;

import React, { FC } from "react";
import { useObserver } from "mobx-react";
import { AdminInputBox } from "src/components/common/AdminInputBox";
import { GlobalSettingsPageStore } from "../../../../stores/pages/adminStores/globalSettings/globalSettingsPageStore";
import { GlobalContactWayList, GlobalLink } from "../common/commonGlobal";

export const ReviewsSettings: FC<{ s: GlobalSettingsPageStore }> = ({ s }) => {
  return useObserver(() => (
    <div>
      <GlobalLink value={s.reviewsBredcrumb} name={`Bredcrumbs`} />
      <div className="my-8">
        <span className="text-gray-700 text-xl font-bold my-8">Top block</span>
        <AdminInputBox
          value={s.reviewsTitleTop}
          label="Title"
          onChange={(e) => (s.reviewsTitleTop = e.target.value)}
        />
        <AdminInputBox
          value={s.reviewsDescriptionTop}
          label="Description"
          onChange={(e) => (s.reviewsDescriptionTop = e.target.value)}
        />
        <AdminInputBox
          value={s.reviewsButtonTop}
          label="Button"
          onChange={(e) => (s.reviewsButtonTop = e.target.value)}
        />
      </div>
      <div>
        <span className="text-gray-700 text-xl font-bold my-8">Request For Consultation</span>
        <AdminInputBox
          value={s.reviewsRequestTitle}
          label="Title"
          onChange={(e) => (s.reviewsRequestTitle = e.target.value)}
        />
        <AdminInputBox
          value={s.reviewsRequestContactWayPlaceholder}
          label="Contact Way Placeholder"
          onChange={(e) => (s.reviewsRequestContactWayPlaceholder = e.target.value)}
        />
        <GlobalContactWayList value={s.reviewsRequestContactWayItems} name="Contact Way Items" />
        <AdminInputBox
          value={s.reviewsRequestPhonePlaceholder}
          label="Phone Placeholder"
          onChange={(e) => (s.reviewsRequestPhonePlaceholder = e.target.value)}
        />
        <AdminInputBox
          value={s.reviewsRequestEmailPlaceholder}
          label="Email Placeholder"
          onChange={(e) => (s.reviewsRequestEmailPlaceholder = e.target.value)}
        />
        <AdminInputBox
          value={s.reviewsRequestNamePlaceholder}
          label="Name Placeholder"
          onChange={(e) => (s.reviewsRequestNamePlaceholder = e.target.value)}
        />
        <AdminInputBox
          value={s.reviewsRequestButtonText}
          label="Button Text"
          onChange={(e) => (s.reviewsRequestButtonText = e.target.value)}
        />
        <AdminInputBox
          value={s.reviewsRequestButtonDescription}
          label="Button Description"
          onChange={(e) => (s.reviewsRequestButtonDescription = e.target.value)}
        />
        <AdminInputBox
          value={s.reviewsRequestLinkedTextInButtonDescription}
          label="Linked Text In Button Description"
          onChange={(e) => (s.reviewsRequestLinkedTextInButtonDescription = e.target.value)}
        />
        <AdminInputBox
          value={s.reviewsRequestCurveText}
          label="Curve Text"
          onChange={(e) => (s.reviewsRequestCurveText = e.target.value)}
        />
        <div className="flex items-center mt-4 mb-4 ">
          <label htmlFor="customMargin" className="mr-2 text-gray-700 text-xl">
            Custom Margin
          </label>
          <input
            type="checkbox"
            id="customMargin"
            checked={s.reviewsRequestCustomMargin}
            onChange={(e) => (s.reviewsRequestCustomMargin = e.target.checked)}
            className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
          />
        </div>
        <AdminInputBox
          value={s.reviewsRequestTopMargin}
          label="Top Margin"
          onChange={(e) => (s.reviewsRequestTopMargin = e.target.value)}
        />
         <AdminInputBox
          value={s.reviewsRequestBottomMargin}
          label="Bottom Margin"
          onChange={(e) => (s.reviewsRequestBottomMargin = e.target.value)}
        />
      </div>
    </div>
  ));
};

import React, { useState } from "react";

import styles from "./QuestionAndAnswerBlock.module.css";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import cn from "classnames";

export interface QuestionAndAnswerProps {
  title: string;
  videoLink: string;
  elements: {
    question: string;
    answer: string;
  }[];
}

export const QuestionAndAnswerBlock: React.FC<QuestionAndAnswerProps> = ({ title, videoLink, elements }) => {
  const [open, setOpen] = useState(-1);

  const handleClick = (index: number) => {
    index === open ? setOpen(-1) : setOpen(index);
  };

  return (
    <div className="py-12">
      <h2 className={styles.questionAndAnswerBlock__title + " text-center md:mb-6 mb-0"}>{title}</h2>
      <div className={"flex flex-col justify-between max-w-screen-xl w-full my-0 mx-auto py-6 px-2 md:px-8"}>
        {videoLink && (
          <div className={styles.questionAndAnswerBlock__video}>
            <div style={{ minWidth: "100%" }}>
              <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
                <iframe
                  src={videoLink}
                  allow="autoplay; encrypted-media; screen-wake-lock"
                  allowFullScreen
                  style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                ></iframe>
              </div>
            </div>
          </div>
        )}
        <div>
          {elements.map(({ question, answer }, index) => (
            <div
              className={cn(
                styles.questionAndAnswerBlock__block,
                open === index && styles.questionAndAnswerBlock__open
              )}
            >
              <p className={styles.questionAndAnswerBlock__question} onClick={() => handleClick(index)}>
                {question}
              </p>
              <p className={styles.questionAndAnswerBlock__answer}>{answer}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const QuestionAndAnswerBlockInfo: TypedBlockTypeInfo<QuestionAndAnswerProps> = {
  id: "QuestionAndAnswerBlock",
  name: "QuestionAndAnswerBlock",
  preview: preview,
  renderer: QuestionAndAnswerBlock,
  initialData: {
    title: "string",
    videoLink: "",
    elements: [
      {
        answer: "string1",
        question: "string1",
      },
      {
        answer: "string2",
        question: "string2",
      },
      {
        answer: "string3",
        question: "string3",
      },
    ],
  },
  definition: {
    subTypes: {
      element: {
        fields: [
          {
            id: "question",
            name: "Question",
            type: "String",
          },
          {
            id: "answer",
            name: "Answer",
            type: "String",
          },
        ],
      },
    },
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "videoLink",
        type: "String",
        name: "Video Link",
      },
      {
        id: "elements",
        name: "Elements",
        type: "List",
        listType: "element",
      },
    ],
  },
};

import React, { useRef } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./AdvantagesBlock.module.css";
import { ApiBaseUrl } from "../../api/apiClientBase";
import preview from "./preview.png";
import Arrow from "../../assets/icons/arrow.svg";
import cn from "classnames";
import { Slider } from "../../ui/Slider/Slider";

export interface AdvantagesBlockElement {
  title: string;
  text: string;
  highlightedText: string;
  elements: {
    icon: number | null;
    title: string;
    text: string;
    backgroundColor: string;
  }[];
}

export const AdvantagesBlock = ({ title, text, highlightedText, elements }: AdvantagesBlockElement) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 260;
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 260;
    }
  };

  const textBefore = text.slice(0, text.indexOf(highlightedText));
  const textAfter =
    textBefore.length !== text.length ? text.slice(text.indexOf(highlightedText) + highlightedText.length) : "";

  return (
    <div className="py-12">
      <div className={styles.advantagesBlock__title + " text-center mx-auto md:mb-6 mb-3"}>{title}</div>
      <div className={styles.advantagesBlock__text + " text-center md:mb-6 mb-3"}>
        {textBefore}
        {highlightedText && <span>{highlightedText}</span>}
        {textAfter}
      </div>
      <div className={"hidden lg:flex gap-5 justify-center mb-6 mt-6 mb-0"}>
        <button onClick={scrollLeft}>
          <img src={Arrow} alt="left button" />
        </button>
        <button onClick={scrollRight} className="transform rotate-180">
          <img src={Arrow} alt="right button" />
        </button>
      </div>
      <div
        ref={scrollContainerRef}
        className="hidden md:flex justify-start max-w-screen-xl gap-8 w-full my-0 mx-auto py-6 px-2 md:px-8 overflow-x-hidden"
      >
        {elements &&
          elements.map(({ icon, title, text, backgroundColor }, index) => (
            <div
              className={cn(
                styles.advantagesBlock__card,
                backgroundColor === "blue" && styles.advantagesBlock__blue,
                backgroundColor === "white" && styles.advantagesBlock__white,
                backgroundColor === "red" && styles.advantagesBlock__red
              )}
              key={index}
            >
              <div className="flex flex-col">
                <span className={styles.advantagesBlock__imageSpan}>
                  {icon && <img src={`${ApiBaseUrl}/api/media/${icon}`} alt="" />}
                </span>
                <h3 className={styles.advantagesBlock__blockTitle}>{title}</h3>
              </div>
              <p className={styles.advantagesBlock__blockText}>{text}</p>
            </div>
          ))}
      </div>
      <div className={cn("flex my-8 mx-auto md:hidden")}>
        <Slider backgroundColor="white">
          {elements.map(({ icon, title, text, backgroundColor }, index) => (
            <div
              className={cn(
                styles.advantagesBlock__card,
                backgroundColor === "blue" && styles.advantagesBlock__blue,
                backgroundColor === "white" && styles.advantagesBlock__white,
                backgroundColor === "red" && styles.advantagesBlock__red
              )}
              key={index}
            >
              <div className="flex flex-col">
                <span className={styles.advantagesBlock__imageSpan}>
                  {icon && <img src={`${ApiBaseUrl}/api/media/${icon}`} alt="" />}
                </span>
                <h3 className={styles.advantagesBlock__blockTitle}>{title}</h3>
              </div>
              <p className={styles.advantagesBlock__blockText}>{text}</p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export const AdvantagesBlockInfo: TypedBlockTypeInfo<AdvantagesBlockElement> = {
  id: "AdvantagesBlock",
  name: "AdvantagesBlock",
  preview: preview,
  renderer: AdvantagesBlock,
  initialData: {
    title: "string",
    text: "this is string, not a number",
    highlightedText: "string",
    elements: [
      {
        icon: null,
        title: "string",
        text: "string",
        backgroundColor: "blue",
      },
    ],
  },
  definition: {
    subTypes: {
      element: {
        fields: [
          {
            id: "icon",
            type: "Custom",
            customType: "Image",
            name: "Icon",
          },
          {
            id: "title",
            type: "String",
            name: "Title",
          },
          {
            id: "text",
            type: "String",
            name: "Text",
          },
          {
            id: "backgroundColor",
            type: "Select",
            name: "Background Color",
            possibleValues: [
              {
                id: "blue",
                name: "Blue",
              },
              {
                id: "white",
                name: "White",
              },
              {
                id: "red",
                name: "Red",
              },
            ],
          },
        ],
      },
    },
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "text",
        type: "String",
        name: "Text",
      },
      {
        id: "highlightedText",
        type: "String",
        name: "Highlighted Text",
      },
      {
        id: "elements",
        type: "List",
        listType: "element",
        name: "Elements",
      },
    ],
  },
};

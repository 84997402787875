import React, { useContext, useEffect, useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./UniversityOrSchoolBlock.module.css";
import cn from "classnames";
import { useRouter } from "next/router";
import { ApiBaseUrl } from "../../api/apiClientBase";
import locationIconWhite from "../../assets/icons/location_white.svg";
import fr from "../../assets/icons/flags/fr.svg";
import union from "../../assets/icons/union.svg";
import logo from "./logo.svg";
import { ComponentHostContext } from "../index";
import { useIntl } from "react-intl";
import { chooseFlag } from "./flags";
import { useLocation } from "react-router-dom";
import { BlockApiClient } from "../../api/blockApiClient";

export interface UniversityOrSchoolBlockElement {
    backgroundImage: number | null,
    universityName: string;
    programName: string;
    universityIcon: number | null;
    price: string;
    description: string;
    specifications: { icon: number | null; text: string; }[];
    showPartnerElement: boolean;
    partnerDescription: string;
    partnerText: string;
    shortPartnerText: string;
    locationText: string;
    showLocationText: boolean;
    whiteButtonText: string;
    showWhiteButton: boolean;
    redButtonText: string;
    showRedButton: boolean;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
    popular: boolean;
}

export interface CityType {
    info?: string | null,
    namesJson: { en?: string, cn?: string, esp?: string, fr?: string, ru?: string }
}

interface AdminFlagData {
    names: string[];
}

interface GroupedAdminFlags {
    [key: string]: AdminFlagData;
}

function groupFlagsByInfo(flags: CityType[], lang: string) {
    return flags?.reduce((acc, flag) => {
        const infoKey = flag.info;
        const nameValue = flag.namesJson[lang as keyof typeof flag.namesJson];

        if (!infoKey || !nameValue) return acc;

        if (!acc[infoKey]) {
            acc[infoKey] = { names: [] };
        }

        acc[infoKey].names.push(nameValue);
        return acc;
    }, {} as Record<string, { names: string[] }>);
}

const apiClient = new BlockApiClient();

export const UniversityOrSchoolBlock = (props: UniversityOrSchoolBlockElement) => {
    const router = useRouter()
    const lang = useIntl().locale || "en";

    const useSafeLocation = () => {
        try {
            return useLocation();
        } catch (error) {
            return null;
        }
    };

    const location = useSafeLocation();

    const [groupedAdminFlags, setGroupedAdminFlags] = useState<GroupedAdminFlags>({})

    useEffect(() => {
        if (!location) {
            return;
        }

        const fetchData = async () => {
            try {
                const match = location?.pathname.match(/\d+$/);
                const result = await apiClient.getAdminCities(match ? match[0] : '');
                setGroupedAdminFlags(groupFlagsByInfo(result, lang));
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [location]);

    let flags: CityType[] = []
    let groupedFlags: GroupedAdminFlags = {}
    const { query } = router || {};
    const cl = useContext(ComponentHostContext);
    console.log(query?.route);
    const routePath = Array.isArray(query?.route) ? query.route.join("/") : query?.route || "";
    const encodedRoutePath = encodeURIComponent(routePath);
    flags = cl?.filters.useCities(encodedRoutePath) || [];

    console.log("flags", flags)
    groupedFlags = groupFlagsByInfo(flags, lang);

    const [activeFlag, setActiveFlag] = useState<string | null>(null);

    const handleFlagClick = (infoKey: string) => {
        setActiveFlag(activeFlag === infoKey ? null : infoKey);
    };

    console.log("cl: ", cl);
    useEffect(() => {
        const headerElement = document.getElementById("header_nav");
        const updateHeaderColor = () => {
            if (headerElement) {
                if (window.innerWidth <= 1024) {
                    headerElement.classList.add(styles.transparentBackground)
                } else {
                    headerElement.classList.remove(styles.transparentBackground)
                }
            }
        }
        updateHeaderColor()
        window.addEventListener("resize", updateHeaderColor)
        return () => {
            window.removeEventListener("resize", updateHeaderColor)
        }
    }, [])

    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};

    return (
        <div className={cn(styles.introEducationalBlock)}
            style={{ backgroundImage: `url(${ApiBaseUrl}/api/media/${props.backgroundImage})`, backgroundColor: "rgba(0, 0, 0, 0.5)", backgroundBlendMode: "overlay" }}>
            <div
                className={cn("flex justify-between flex-col gap-10 lg:gap-20 lg:items-start max-w-screen-xl w-full my-0 mx-auto p-4 md:p-10 lg:p-6 pt-12 lg:py-20", styles.container)}
                style={marginStyles}>
                <div className="flex flex-col justify-between w-full h-full">
                    <div className="flex justify-between">
                        <div className="md:w-8/12 lg:w-full">
                            <div className="md:flex md:flex-col-reverse lg:flex-col">
                                <h1 className={styles.title}>{props.universityName}</h1>
                            </div>
                            <div className={styles.specifications}>
                                {props.specifications.map((spec, index) => (
                                    <div key={index} className={styles.specification}>
                                        {spec.icon && <img src={`${ApiBaseUrl}/api/media/${spec.icon}`} width={'16px'}
                                            height={'16px'} alt={`spec-icon-${index}`}
                                            className={styles.icon} />}
                                        <span>{spec.text}</span>
                                    </div>
                                ))}
                                {props.locationText && <div className="flex-row flex lg:hidden gap-1 items-baseline">
                                    <img src={locationIconWhite} className="" />
                                    <p className={cn(styles.locationText, "mt-0.5")}>{props.locationText}</p>
                                </div>}
                            </div>
                        </div>
                        <div className="flex flex-col justify-start mb-10 md:mb-0 md:justify-start md:items-end ">
                            {props.universityIcon &&
                                <img src={`${ApiBaseUrl}/api/media/${props.universityIcon}`} alt="logo" className={styles.logo} />}
                        </div>
                    </div>
                    <div
                        className={cn({ ["flex flex-col justify-between items-start md:items-end md:mt-24 md:mb-16 md:flex-row lg:mt-16 lg:mb-2  "]: !props.showRedButton },
                            { ["flex flex-col-reverse md:flex-row items-start md:items-end justify-between md:mt-24 md:mb-2 lg:mt-16 lg:mb-2"]: props.showRedButton })}>
                        <div>
                            {props.showPartnerElement &&
                                <div className={"flex flex-row-reverse md:flex-col gap-3 w-full"}>
                                    <div className={cn("text-left", styles.description)} dangerouslySetInnerHTML={{ __html: props.partnerDescription }} />
                                    <div className={cn("flex gap-3", styles.partnerElement)}>
                                        <img className={"hidden md:flex"} src={logo} alt="" />
                                        <p className={"hidden md:flex"}>{props.partnerText?.toUpperCase()}</p>
                                        <p className={"flex md:hidden h-3"}>{props.shortPartnerText?.toUpperCase()}</p>
                                    </div>
                                </div>
                            }
                        </div>
                        <div
                            className={cn({ ["flex flex-col mb-10 md:mb-0 justify-center w-full md:w-max"]: !props.showRedButton },
                                { ["flex gap-2 flex-col mb-10 md:mb-0 w-full md:w-max"]: props.showRedButton })}>
                            {props.showWhiteButton && props.whiteButtonText &&
                                <a style={{ whiteSpace: "nowrap" }} href="#withUsBlock"
                                    className={cn(styles.whiteButton, "flex gap-1 justify-center items-center")}>{props.whiteButtonText}</a>}
                            {props.showRedButton && props.showWhiteButton && props.redButtonText &&
                                <button style={{ whiteSpace: "nowrap" }}
                                    onClick={() => cl?.showContactUsFormBlue(true)}
                                    className={cn(styles.redButton, "flex justify-center items-center gap-2 ")}>{props.redButtonText}<img
                                        src={union} className={styles.unionIcon} /></button>}
                            {props.locationText && <div className="flex-row items-baseline mt-3 hidden lg:flex gap-3">
                                <p className={styles.locationText}>{props.locationText}</p>
                                <img src={locationIconWhite} className="" />
                            </div>}
                        </div>
                        {props.showRedButton && !props.showWhiteButton &&
                            <div className={cn("flex w-full md:w-2/5 lg:w-auto")}>
                                <button onClick={() => cl?.showContactUsFormBlue(true)}
                                    className={cn(styles.redButton, "flex")}><span
                                        className={cn("w-full text-center")}>{props.redButtonText}</span></button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {groupedFlags && typeof groupedFlags === 'object' && Object.keys(groupedFlags).length > 0 ? (
                <div className={styles.flagsLine}>
                    {Object.entries(groupedFlags || {}).map(([infoKey, data]) => (
                        <div
                            key={infoKey}
                            className={cn(styles.flag, { active: activeFlag === infoKey })}
                            onClick={() => handleFlagClick(infoKey)}
                        >
                            <div className={styles.flagIconContainer}>
                                <img
                                    src={chooseFlag(infoKey) || fr}
                                    className={cn(styles.flagImage, activeFlag === infoKey && styles.active)}
                                    alt="Flag Icon"
                                />
                            </div>
                            <p className={styles.flagsText}>
                                {data.names.map((name, index) => (
                                    <React.Fragment key={index}>
                                        {name}
                                        {index < data.names.length - 1 && (
                                            <span className="font-bold"> · </span>
                                        )}
                                    </React.Fragment>
                                ))}
                            </p>
                        </div>
                    ))}
                </div>
            ) : groupedAdminFlags && typeof groupedAdminFlags === 'object' && Object.keys(groupedAdminFlags).length > 0 ? (
                <div className={styles.flagsLine}>
                    {Object.entries(groupedAdminFlags || {}).map(([infoKey, data]) => (
                        <div
                            key={infoKey}
                            className={cn(styles.flag, { active: activeFlag === infoKey })}
                            onClick={() => handleFlagClick(infoKey)}
                        >
                            <div className={styles.flagIconContainer}>
                                <img
                                    src={chooseFlag(infoKey) || fr}
                                    className={cn(styles.flagImage, activeFlag === infoKey && styles.active)}
                                    alt="Flag Icon"
                                />
                            </div>
                            <p className={styles.flagsText}>
                                {data.names.map((name, index) => (
                                    <React.Fragment key={index}>
                                        {name}
                                        {index < data.names.length - 1 && (
                                            <span className="font-bold"> · </span>
                                        )}
                                    </React.Fragment>
                                ))}
                            </p>
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    )
}

export const UniversityOrSchoolBlockInfo: TypedBlockTypeInfo<UniversityOrSchoolBlockElement> = {
    id: "UniversityOrSchoolBlock",
    name: "University or School",
    preview: preview,
    renderer: UniversityOrSchoolBlock,
    initialData: {
        backgroundImage: null,
        universityName: "string",
        programName: "",
        universityIcon: null,
        price: "",
        description: "",
        specifications: [{ icon: null, text: "string" }],
        showPartnerElement: false,
        partnerDescription: "",
        partnerText: "",
        shortPartnerText: "",
        locationText: "",
        showLocationText: false,
        whiteButtonText: "",
        showWhiteButton: false,
        redButtonText: "",
        showRedButton: false,
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
        popular: false,
    },
    definition: {
        subTypes: {
            specifications: {
                fields: [
                    {
                        id: "icon",
                        type: "Custom",
                        name: "icon",
                        customType: "Image"

                    },
                    {
                        id: "text",
                        type: "String",
                        name: "Text"
                    },
                ],
            },
        },
        fields: [
            {
                id: "backgroundImage",
                type: "Custom",
                name: "Background Image",
                customType: "Image",
            },
            {
                id: "universityName",
                type: "String",
                name: "University Name",
            },
            {
                id: "programName",
                name: "Program Name (for preview)",
                type: "String",
            },
            {
                id: "universityIcon",
                name: "Logo",
                type: "Custom",
                customType: "Image",
            },
            {
                id: "price",
                name: "Price (for preview)",
                type: "Custom",
                customType: "Html"
            },
            {
                id: "description",
                name: "Description (for preview)",
                type: "Custom",
                customType: "Html"
            },
            {
                id: "specifications",
                type: "List",
                listType: "specifications",
                name: "Specifications"
            },
            {
                id: "showPartnerElement",
                type: "CheckBox",
                name: "Show Partner Element",
            },
            {
                id: "partnerDescription",
                type: "Custom",
                customType: "Html",
                name: "Partner Description",
            },
            {
                id: "partnerText",
                type: "String",
                name: "Partner Text",
            },
            {
                id: "shortPartnerText",
                type: "String",
                name: "Short Partner Text",
            },
            {
                id: "locationText",
                type: "String",
                name: "Location Text",
            },
            {
                id: "showLocationText",
                type: "CheckBox",
                name: "Show Location Text",
            },
            {
                id: "whiteButtonText",
                type: "String",
                name: "White Button Text",
            },
            {
                id: "showWhiteButton",
                type: "CheckBox",
                name: "Show White Button",
            },
            {
                id: "redButtonText",
                type: "String",
                name: "Red Button Text",
            },
            {
                id: "showRedButton",
                type: "CheckBox",
                name: "Show Red Button",
            },
            {
                id: "popular",
                name: "Popular",
                type: "CheckBox"
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },

        ]
    }
}

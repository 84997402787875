import React, { FC, useEffect, useRef, useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./RequestForConsultationBlock.module.css"
import preview from "./preview.png";
import phoneWhite from "@project/components/src/assets/icons/phoneWhite.svg";
import phoneRed from "@project/components/src/assets/icons/phoneRed.svg"
import cn from "classnames";
import { CurvedText } from "./CurvedText";
import Select from "../../ui/Select/Select";
import { SubmitHandler, useForm } from "react-hook-form";
import { BlockApiClient } from "../../api/blockApiClient";

export interface RequestForConsultationBlockElement {
    purposeOfForm: string,
    title: string,
    showTitle: boolean,
    price: string,
    priceSubtitle: string,
    contactWayPlaceholder: string,
    contactWayItems: {
        name: string
    }[]
    phonePlaceholder: string,
    emailPlaceHolder: string,
    namePlaceholder: string,
    commentPlaceholder: string,
    buttonText: string,
    buttonDescription: string,
    linkedTextInButtonDescription: string,
    documentLink: string,
    contactPhone: string,
    specialistName: string,
    specialistDescription: string,
    showPhone: boolean,
    backgroundColor: "pink" | "blue" | "light blue";
    curveText: string
    curveColor: "white" | "blue" | "red";
    curveTextColor: "white" | "blue";
}

export type ContactUsFormPropsType = {
    onDismiss: () => void;
    onSuccess: () => void;
}

const apiClient = new BlockApiClient();


type Inputs = {
    name: string;
    email: string;
    tel: string;
    contactWay?: string;
    com?: string;
};

export const RequestForConsultationBlock = (props: RequestForConsultationBlockElement) => {
    const newContactWayItems = props.contactWayItems.map((item, index) => ({
        id: index + 1,
        name: item.name
    }));
    const backgroundColor = props.backgroundColor === "pink" ? "#F3D7E3" : props.backgroundColor === "blue" ? "#00309D" : "#CEE1F3"
    const curveColor = props.curveColor === "white" ? "#FFFFFF" : props.curveColor === "blue" ? "#00309D" : "#FA5354"
    const curveTextColor = props.curveTextColor === "white" ? "#FFFFFF" : "#00309D"
    const descriptionColor = props.backgroundColor === "blue" ? "#C3C3C3" : "#6E6E6E"
    const startIndex = props.buttonDescription.lastIndexOf(props.linkedTextInButtonDescription);
    const titleColor =
        props.backgroundColor === "blue"
            ? "#FFFFFF"
            : "#000000";

    let beforeText = props.buttonDescription;
    let highlightedText = '';

    if (startIndex !== -1 && startIndex + props.linkedTextInButtonDescription.length === props.buttonDescription.length) {
        beforeText = props.buttonDescription.substring(0, startIndex);
        highlightedText = props.linkedTextInButtonDescription;
    }

    const [contactWay, setContactWay] = useState({ name: props.contactWayPlaceholder, id: -1 });

    const {
        register,
        handleSubmit,
        formState: { errors, dirtyFields },
    } = useForm<Inputs>({
        mode: 'onChange',
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        const requestData: any = {
            firstName: data.name,
            email: data.email,
            phone: data.tel,
        };

        if (contactWay.id && contactWay.id > -1) {
            requestData.contactWay = contactWay.id;
        }

        if (data.com && data.com.trim().length > 0) {
            requestData.comment = data.com;
        }

        try {
            await apiClient.sendConsultationRequest(requestData);
            console.log("Request sent successfully");
          } catch (error) {
            console.error("Error sending request", error);
          }
    };

    const [isVisible, setIsVisible] = useState(false);
    const blockRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.7,
            }
        );

        if (blockRef.current) {
            observer.observe(blockRef.current);
        }

        return () => {
            if (blockRef.current) {
                observer.unobserve(blockRef.current);
            }
        };
    }, []);

    const getFadeInClass = (delay: number) => ({
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(20px)",
        transition: `opacity 1.5s ease-out ${delay}s, transform 1.5s ease-out ${delay}s`
    });
    return (
        <div ref={blockRef} style={{ backgroundColor: backgroundColor }} className={cn(styles.requestForConsultation, "flex justify-center")}>
            <div className={cn("h-full w-full max-w-screen-xl flex relative", styles.requestForConsultation__animation, { [styles.requestForConsultationVisible]: isVisible })}>
                <div className={cn(styles.requestForConsultation__form)}>
                    {props.showTitle && <div className={styles.requestForConsultation__title} style={{ color: titleColor, ...getFadeInClass(0) }}>{props.title}</div>}
                    {props.price && props.priceSubtitle &&
                        <div className={cn("flex flex-col items-center lg:flex-row", styles.requestForConsultation__priceContainer)} style={{ ...getFadeInClass(0.1) }}>
                            <div className={styles.requestForConsultation__price} style={{ color: titleColor }}>{props.price}</div>
                            <div className={styles.requestForConsultation__priceSubtitle} style={{ color: titleColor }}>{props.priceSubtitle}</div>
                        </div>
                    }
                    <form className={"flex flex-col gap-2"}
                        onSubmit={handleSubmit(onSubmit)}
                        style={getFadeInClass(0.2)}>
                        <div className={"flex flex-col gap-2"}>
                            {props.contactWayItems.length > 0 && (
                                <div className={cn(styles.requestForConsultation__select, "flex flex-col mb-1")}>
                                    <Select
                                        value={contactWay.name}
                                        options={newContactWayItems}
                                        selectChange={(name, ind) => {
                                            const id = typeof ind == "number" ? ind : parseInt(ind);
                                            setContactWay({ name, id });
                                        }}
                                    />
                                </div>
                            )}
                            <label className={"w-full mb-1"}>
                                <input
                                    className={cn(styles.requestForConsultation__input, "w-full", {
                                        error: errors.name,
                                        correct: !errors.name && dirtyFields.name,
                                    })}
                                    {...register("name", { required: true })}
                                    placeholder={props.namePlaceholder}
                                />
                            </label>
                            <label className={"w-full mb-1"}>
                                <input
                                    className={cn(styles.requestForConsultation__input, "w-full", {
                                        error: errors.tel,
                                        correct: !errors.tel && dirtyFields.tel,
                                    })}
                                    {...register("tel", { required: true, pattern: /^\+?[0-9]{10,}$/ })}
                                    placeholder={props.phonePlaceholder}
                                />
                            </label>
                            <label className={"w-full mb-1"}>
                                <input
                                    className={cn(styles.requestForConsultation__input, "w-full", {
                                        error: errors.email,
                                        correct: !errors.email && dirtyFields.email,
                                    })}
                                    {...register("email", { required: true })}
                                    placeholder={props.emailPlaceHolder}
                                />
                            </label>
                            {props.commentPlaceholder && (
                                <label className={"w-full mb-1"}>
                                    <textarea
                                        className={cn(styles.requestForConsultation__input, "w-full", {
                                            error: errors.com,
                                            correct: !errors.com && dirtyFields.com,
                                        })}
                                        {...register("com")}
                                        placeholder={props.commentPlaceholder}
                                    />
                                </label>
                            )}
                        </div>
                        <button className={cn(styles.requestForConsultation__button, "flex self-start")} type="submit">
                            {props.buttonText}
                        </button>
                    </form>
                    <div style={{ color: `${descriptionColor}`, ...getFadeInClass(0.3) }} className={cn(styles.requestForConsultation__description)}>
                        {beforeText}
                        {highlightedText && (
                            <a href={props.documentLink} style={{ textDecoration: "underline" }}>{highlightedText}</a>
                        )}
                    </div>

                    {props.showPhone &&
                        <div className={cn("flex mt-10 gap-5 lg:gap-2 flex-col lg:flex-row items-center", styles.phoneBlock)} style={getFadeInClass(0.4)}>
                            <img
                                src={props.backgroundColor === 'pink' ? phoneRed : phoneWhite}
                                className={cn(styles.phoneIcon)}
                            />
                            <div className={cn("flex flex-col gap-2 lg:gap-0 lg:justify-evenly h-full")}>
                                <div className={cn(styles.phoneNumber, { [styles.blackText]: props.backgroundColor === "pink" })}>
                                    {props.contactPhone}</div>
                                <div className={cn(styles.specialistName)}><span className={cn({ [styles.blackText]: props.backgroundColor === "pink" })}>{props.specialistName + " "}</span>
                                    <span className={cn(styles.specialistDescription, { [styles.greyText]: props.backgroundColor === "pink" })}>{props.specialistDescription}</span>
                                </div>
                            </div>
                        </div>
                    }
                    {/*<button type="submit">*/}
                    {/*    /!*<LocalizedText id="contactUs_applyButton" />*!/*/}
                    {/*</button>*/}
                </div>
                <div className={cn(styles.requestForConsultation__curve, "hidden lg:flex")} style={getFadeInClass(0.5)}>
                    <CurvedText text={`${props.curveText}`} color={`${curveTextColor}`} backgroundColor={`${curveColor}`} />
                </div>

                {/*<img src={snake} className={cn(styles.requestForConsultation__snake, "hidden lg:flex")}/>*/}
            </div>
        </div>

    );
};

export const RequestForConsultationBlockInfo: TypedBlockTypeInfo<RequestForConsultationBlockElement> = {
    id: "RequestForConsultationBlock",
    name: "RequestForConsultationBlock",
    preview: preview,
    renderer: RequestForConsultationBlock,
    initialData: {
        purposeOfForm: "requestForConsultation",
        title: "string",
        showTitle: false,
        price: "string",
        priceSubtitle: "string",
        contactWayPlaceholder: "string",
        contactWayItems: [{ name: "string" }],
        phonePlaceholder: "string",
        emailPlaceHolder: "string",
        namePlaceholder: "string",
        commentPlaceholder: "string",
        buttonText: "string",
        buttonDescription: "string",
        linkedTextInButtonDescription: "#",
        documentLink: "string",
        contactPhone: "string",
        specialistName: "string",
        specialistDescription: "string",
        showPhone: false,
        backgroundColor: "pink",
        curveText: "string",
        curveColor: "blue",
        curveTextColor: "white"
    },
    definition: {
        subTypes: {
            contactWay: {
                fields: [
                    {
                        id: "name",
                        type: "String",
                        name: "Name",
                    }
                ]
            }
        },
        fields: [
            {
                id: "purposeOfForm",
                type: "Radio",
                name: "Purpose Of Form",
                possibleValues: [
                    {
                        id: "requestForConsultation",
                        name: "Request For Consultation",
                    },
                    {
                        id: "orderService",
                        name: "Order Service",
                    },
                ],
            },
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "showTitle",
                type: "CheckBox",
                name: "Show Title",
            },
            {
                id: "price",
                type: "String",
                name: "Price",
            },
            {
                id: "priceSubtitle",
                type: "String",
                name: "Price Subtitle",
            },
            {
                id: "contactWayPlaceholder",
                type: "String",
                name: "Contact Way/Select Service Placeholder",
            },
            {
                id: "contactWayItems",
                type: "List",
                listType: "contactWay",
                name: "Contact Way Items"
            },
            {
                id: "phonePlaceholder",
                type: "String",
                name: "Phone Placeholder",
            },
            {
                id: "emailPlaceHolder",
                type: "String",
                name: "Email PlaceHolder",
            },
            {
                id: "namePlaceholder",
                type: "String",
                name: "Name Placeholder",
            },
            {
                id: "commentPlaceholder",
                type: "String",
                name: "Comment Placeholder",
            },
            {
                id: "buttonText",
                type: "String",
                name: "Button Text",
            },
            {
                id: "buttonDescription",
                type: "String",
                name: "Button Description",
            },
            {
                id: "linkedTextInButtonDescription",
                type: "String",
                name: "Linked Text In Button Description",
            },
            {
                id: "documentLink",
                type: "String",
                name: "Document Link",
            },
            {
                id: "contactPhone",
                type: "String",
                name: "Contact Phone",
            },
            {
                id: "specialistName",
                type: "String",
                name: "Specialist Name",
            },
            {
                id: "specialistDescription",
                type: "String",
                name: "Specialist Description",
            },
            {
                id: "showPhone",
                type: "CheckBox",
                name: "Show Phone",
            },
            {
                id: "backgroundColor",
                type: "Radio",
                name: "Background Color",
                possibleValues: [
                    {
                        id: "pink",
                        name: "pink",
                    },
                    {
                        id: "blue",
                        name: "blue",
                    },
                    {
                        id: "light blue",
                        name: "light blue",
                    },
                ],
            },
            {
                id: "curveText",
                type: "String",
                name: "Curve Text",
            },
            {
                id: "curveColor",
                type: "Radio",
                name: "Curve Color",
                possibleValues: [
                    {
                        id: "white",
                        name: "white",
                    },
                    {
                        id: "blue",
                        name: "blue",
                    },
                    {
                        id: "red",
                        name: "red",
                    },
                ],
            },
            {
                id: "curveTextColor",
                type: "Radio",
                name: "Curve Text Color",
                possibleValues: [
                    {
                        id: "white",
                        name: "white",
                    },
                    {
                        id: "blue",
                        name: "blue",
                    },
                ],
            },
        ],
    },
};

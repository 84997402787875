import React, { useContext } from "react";
import styles from "./AdditionalServiceBlock.module.css"
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import { ApiBaseUrl } from "../../api/apiClientBase";
import cn from "classnames";
import { ComponentHostContext } from "../index";

interface ServiceOption {
    serviceIcon: number | null;
    serviceTitle: string;
    serviceText: string;
    backgroundImg: number | null;
    servicePrice: string;
    serviceButtonText: string;
    textAndButtonColor: string;
    link: string;
}

export interface AdditionalServiceBlockProps {
    title: string;
    subtitle: string;
    highlightedText: string;
    highlightedTextColor: 'blue' | 'red';
    text: string;
    services: ServiceOption[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

const highlightMatchAsHtml = (text: string, highlightedText: string, highlightedTextColor: 'blue' | 'red'): string => {
    if (!highlightedText || !text.includes(highlightedText)) return text;

    const highlightColor = highlightedTextColor === 'blue' ? "rgba(206, 225, 243, 1)" : "rgba(243, 215, 227, 1)";
    const textColor = highlightedTextColor === 'blue' ? "rgba(0, 48, 157, 1)" : "rgba(250, 83, 84, 1)";

    return text.replace(
        new RegExp(`(${highlightedText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, "gi"),
        `<span style="background-color: ${highlightColor}; color: ${textColor}; padding: 0 5px;">$1</span>`
    );
};

export const AdditionalServiceBlock: React.FC<AdditionalServiceBlockProps> = ({ title, subtitle, highlightedText, highlightedTextColor, text, services, topMargin, bottomMargin, customMargin }) => {
    const textColor = (color: string) => {
        return color === "black" ? ["#373737", "#FFFFFF"] : ["#FFFFFF", "#373737"];
    };
    const highlightedSubtitleHtml = highlightMatchAsHtml(subtitle, highlightedText, highlightedTextColor);
    const cl = useContext(ComponentHostContext);
    const handleServiceClick = (service: ServiceOption) => {
        cl?.setSelectedService({ id: service.serviceIcon || 0, name: service.serviceTitle });
        cl?.showContactUsFormServiceBlue("rgba(0, 48, 157, 1)");
    };
    const marginStyles = customMargin
        ? {
            marginTop: topMargin ? `${topMargin}px` : undefined,
            marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div id={title.replace(/\s+/g, '-').toLowerCase()} className={cn("py-12 align-center mx-auto my-28 md:my-32 lg:my-40", styles.additionalServiceBlock)} style={marginStyles}>
            <div className={styles.additionalServiceBlock__title + " text-center mb-6"} dangerouslySetInnerHTML={{
                __html: title,
            }} />
            <div className={styles.additionalServiceBlock__subtitle + " text-center md:mb-6 mb-0"} dangerouslySetInnerHTML={{
                __html: highlightedSubtitleHtml,
            }} />
            <div className={cn("text-center md:mb-6 mb-0 mx-auto", styles.additionalServiceBlock__text)} dangerouslySetInnerHTML={{
                __html: text,
            }} />
            <div className={"flex flex-col justify-between max-w-screen-xl w-full gap-5 my-0 mx-auto py-6 px-2 md:px-8"}>
                <a href={services[0]?.link || "#"} className={cn(styles.additionalServiceBlock__card, "flex flex-col justify-between p-7 relative overflow-hidden")}>
                    <div
                        className={cn(styles.additionalServiceBlock__cardImageWrapper, "absolute inset-0")}
                    >
                        <div
                            className={cn(styles.additionalServiceBlock__cardImage)}
                            style={{
                                backgroundImage: `url(${ApiBaseUrl}/api/media/${services[0]?.backgroundImg})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(239, 239, 239, 1)'
                            }}
                        />
                    </div>
                    <div style={{ color: textColor(services[0]?.textAndButtonColor)[0] }} className="flex flex-col justify-between flex-grow relative z-10">
                        <div>
                            <div className={styles.additionalServiceBlock__imageTitle} dangerouslySetInnerHTML={{
                                __html: services[0]?.serviceTitle
                            }} />
                            <div className={styles.additionalServiceBlock__imageText} dangerouslySetInnerHTML={{
                                __html: services[0]?.serviceText
                            }} />
                        </div>
                        <div>
                            <div className={styles.additionalServiceBlock__imagePrice} dangerouslySetInnerHTML={{
                                __html: services[0]?.servicePrice
                            }} />
                            <button
                                className={cn(styles.additionalServiceBlock__imageButtonFirst, services[0]?.textAndButtonColor === "black" ? styles.blackButton : styles.whiteButton)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    services[0] && handleServiceClick(services[0])
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: services[0]?.serviceButtonText
                                }}
                            />
                        </div>
                    </div>
                </a>
                <a href={services[1]?.link || "#"} style={{
                    backgroundImage: `url(${ApiBaseUrl}/api/media/${services[1]?.backgroundImg})`,
                    color: textColor(services[1]?.textAndButtonColor)[0]
                }}
                    className={cn(styles.additionalServiceBlock__firstItem, styles.additionalServiceBlock__card, "hidden md:flex lg:hidden flex-col justify-between p-7", { [styles.additionalServiceBlock__cardNoImage]: !services[1]?.backgroundImg, })}>
                    <div>
                        <div className={styles.additionalServiceBlock__imageTitle} dangerouslySetInnerHTML={{
                            __html: services[1]?.serviceTitle
                        }} />
                        <div className={styles.additionalServiceBlock__imageText} dangerouslySetInnerHTML={{
                            __html: services[1]?.serviceText
                        }} />
                    </div>
                    <div>
                        <div className={styles.additionalServiceBlock__imagePrice} dangerouslySetInnerHTML={{
                            __html: services[1]?.servicePrice
                        }} />
                        <button className={cn(styles.additionalServiceBlock__imageButtonFirst, services[1]?.textAndButtonColor === "black" ? styles.blackButton : styles.whiteButton)}
                            onClick={(e) => {
                                e.preventDefault();
                                services[1] && handleServiceClick(services[1])
                            }}
                            dangerouslySetInnerHTML={{
                                __html: services[1]?.serviceButtonText
                            }}
                        />
                    </div>
                </a>
                <div className={cn(styles.additionalServiceBlock__secondRow, "w-full hidden lg:flex flex-wrap justify-between gap-5")}>
                    {services.slice(1, 3)?.map((service, index) => (
                        <a
                            href={service?.link || "#"}
                            key={index}
                            className={cn("flex-1 flex flex-col justify-between p-7 relative overflow-hidden", styles.additionalServiceBlock__secItem, {
                                [styles.additionalServiceBlock__cardNoImage]: !service.backgroundImg,
                            })}
                            style={{ color: textColor(service?.textAndButtonColor)[0] }}
                        >
                            <div
                                className={cn(styles.additionalServiceBlock__cardImageWrapper, "absolute inset-0")}
                            >
                                <div
                                    className={cn(styles.additionalServiceBlock__cardImage)}
                                    style={{
                                        backgroundImage: `url(${ApiBaseUrl}/api/media/${service?.backgroundImg})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(239, 239, 239, 1)'
                                    }}
                                />
                            </div>
                            <div className="flex flex-col justify-between flex-grow relative z-10">
                                <div>
                                    {service.serviceIcon && <img
                                        src={`${ApiBaseUrl}/api/media/${service.serviceIcon}`}
                                        className={cn(styles.serviceIcon, index % 2 === 0 ? "flex md:hidden" : "lg:hidden")}
                                        alt=""
                                    />}
                                    <div className={styles.additionalServiceBlock__imageTitle} dangerouslySetInnerHTML={{
                                        __html: service.serviceTitle
                                    }} />
                                    <div className={styles.additionalServiceBlock__imageText} dangerouslySetInnerHTML={{
                                        __html: service.serviceText
                                    }} />
                                </div>
                                <div>
                                    <div className={styles.additionalServiceBlock__imagePrice} dangerouslySetInnerHTML={{
                                        __html: service.servicePrice
                                    }} />
                                    <button
                                        className={cn(styles.additionalServiceBlock__imageButton, service?.textAndButtonColor === "black" ? styles.blackButton : styles.whiteButton)}
                                        style={{
                                            zIndex: 30,
                                            position: 'relative',
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleServiceClick(service)
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: service.serviceButtonText
                                        }}
                                    />
                                </div>
                            </div>
                        </a>
                    ))}
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                    {services.slice(1, services.length)?.map((service, index) => (
                        <a href={service.link || "#"} key={index} className={cn(styles.additionalServiceBlock__miniItem, styles.additionalServiceBlock__card, {
                            [styles.additionalServiceBlock__cardNoImage]: !service.backgroundImg,
                        }, "flex-col justify-between p-7 flex md:hidden")}>
                            <div className="text-content">
                                {service.serviceIcon && <img src={`${ApiBaseUrl}/api/media/${service.serviceIcon}`} className={styles.serviceIcon} alt="" />}
                                <div className={styles.additionalServiceBlock__imageTitle} style={{
                                    width: service.serviceTitle.length < 30 ? '60%' : '90%'
                                }}
                                    dangerouslySetInnerHTML={{
                                        __html: service.serviceTitle
                                    }} />
                                <div className={cn(styles.additionalServiceBlock__imageText, styles.styleText, "flex")} dangerouslySetInnerHTML={{
                                    __html: service.serviceText
                                }} />
                            </div>
                            <div>
                                <div className={styles.additionalServiceBlock__imagePrice} dangerouslySetInnerHTML={{
                                    __html: service.servicePrice
                                }} />
                                <button
                                    className={cn(styles.additionalServiceBlock__imageButton, styles.blackButton)}
                                    style={{
                                        zIndex: 30,
                                        position: 'relative',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleServiceClick(service)
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: service.serviceButtonText
                                    }}
                                />
                            </div>
                        </a>
                    ))}

                    {services.slice(2, services.length)?.map((service, index) => (
                        <a href={service.link || "#"} key={index} className={cn(styles.additionalServiceBlock__miniItem, styles.additionalServiceBlock__card, {
                            [styles.additionalServiceBlock__cardNoImage]: !service.backgroundImg,
                        }, "flex-col justify-between p-7 hidden md:flex lg:hidden")} style={{ color: textColor(service.textAndButtonColor)[0] }} >
                            <div className="text-content">
                                {service.serviceIcon && <img src={`${ApiBaseUrl}/api/media/${service.serviceIcon}`} className={styles.serviceIcon} alt="" />}
                                <div className={styles.additionalServiceBlock__imageTitle} style={{
                                    width: service.serviceTitle.length < 30 ? '60%' : '90%'
                                }} dangerouslySetInnerHTML={{
                                    __html: service.serviceTitle
                                }} />
                                <div className={cn(styles.additionalServiceBlock__imageText, styles.styleText, "flex")} dangerouslySetInnerHTML={{
                                    __html: service.serviceText
                                }} />
                            </div>
                            <div>
                                <div className={styles.additionalServiceBlock__imagePrice} dangerouslySetInnerHTML={{
                                    __html: service.servicePrice
                                }} />
                                <button
                                    className={cn(styles.additionalServiceBlock__imageButton, styles.blackButton)}
                                    style={{
                                        zIndex: 30,
                                        position: 'relative',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleServiceClick(service)
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: service.serviceButtonText
                                    }}
                                />
                            </div>
                        </a>
                    ))}

                    {services.slice(3, services.length)?.map((service, index) => (
                        <a href={service.link || "#"} key={index} className={cn(styles.additionalServiceBlock__miniItem, styles.additionalServiceBlock__card, {
                            [styles.additionalServiceBlock__cardNoImage]: !service.backgroundImg,
                        }, "flex-col justify-between p-7 flex hidden lg:flex")} style={{ color: textColor(service.textAndButtonColor)[0] }} >
                            <div className="text-content">
                                {service.serviceIcon && <img src={`${ApiBaseUrl}/api/media/${service.serviceIcon}`} className={styles.serviceIcon} alt="" />}
                                <div className={styles.additionalServiceBlock__imageTitle} style={{
                                    width: service.serviceTitle.length < 30 ? '60%' : '90%'
                                }} dangerouslySetInnerHTML={{
                                    __html: service.serviceTitle
                                }} />
                                <div className={cn(styles.additionalServiceBlock__imageText, styles.styleText, "flex")} dangerouslySetInnerHTML={{
                                    __html: service.serviceText
                                }} />
                            </div>
                            <div>
                                <div className={styles.additionalServiceBlock__imagePrice} dangerouslySetInnerHTML={{
                                    __html: service.servicePrice
                                }} />
                                <button
                                    className={cn(styles.additionalServiceBlock__imageButton, styles.blackButton)}
                                    style={{
                                        zIndex: 30,
                                        position: 'relative',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleServiceClick(service)
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: service.serviceButtonText
                                    }}
                                />
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
};

export const AdditionalServiceBlockInfo: TypedBlockTypeInfo<AdditionalServiceBlockProps> = {
    id: "AdditionalServiceBlock",
    name: "Additional Service",
    preview: preview,
    renderer: AdditionalServiceBlock,
    initialData: {
        title: "string",
        subtitle: "string",
        highlightedText: "string",
        highlightedTextColor: "blue",
        text: "string",
        services: [
            {
                serviceIcon: null,
                serviceTitle: "string",
                serviceText: "string",
                backgroundImg: null,
                servicePrice: "string",
                serviceButtonText: "string",
                textAndButtonColor: "black",
                link: "#"
            },
        ],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            service: {
                fields: [
                    {
                        id: "serviceIcon",
                        name: "Service Icon",
                        type: "Custom",
                        customType: "Image",
                    },
                    {
                        id: "serviceTitle",
                        name: "Service Title",
                        type: "Custom",
                        customType: "Html",
                    },
                    {
                        id: "serviceText",
                        name: "Service Text",
                        type: "Custom",
                        customType: "Html",
                    },
                    {
                        id: "backgroundImg",
                        name: "Background Image",
                        type: "Custom",
                        customType: "Image",
                    },
                    {
                        id: "servicePrice",
                        name: "Service Price",
                        type: "Custom",
                        customType: "Html",
                    },
                    {
                        id: "serviceButtonText",
                        name: "Service Button Text",
                        type: "Custom",
                        customType: "Html",
                    },
                    {
                        id: "textAndButtonColor",
                        name: "Text And Button Color",
                        type: "Radio",
                        possibleValues: [
                            {
                                id: "black",
                                name: "black"
                            },
                            {
                                id: "white",
                                name: "white"
                            }
                        ]
                    },
                    {
                        id: "link",
                        name: "Service Link",
                        type: "String"
                    }
                ],
            },
        },
        fields: [
            {
                id: "title",
                type: "Custom",
                customType: "Html",
                name: "Title",
            },
            {
                id: "subtitle",
                type: "Custom",
                customType: "Html",
                name: "Subtitle",
            },
            {
                id: "highlightedText",
                type: "String",
                name: "Highlighted Text",
            },
            {
                id: "highlightedTextColor",
                type: "Radio",
                name: "Highlighted Text Color",
                possibleValues: [
                    {
                        id: "blue",
                        name: "Blue"
                    },
                    {
                        id: "red",
                        name: "Red",
                    },
                ]
            },
            {
                id: "text",
                type: "Custom",
                customType: "Html",
                name: "Text",
            },
            {
                id: "services",
                name: "Services",
                type: "List",
                listType: "service",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};

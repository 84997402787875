import React, { useEffect, useRef, useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./AcademicSupportBlock.module.css";
import cn from "classnames";
import { Slider } from "../../ui/Slider/Slider";
import {ApiBaseUrl} from "../../api/apiClientBase";

export interface AcademicSupportBlockElement {
    header: string;
    text: string;
    secText: string;
    elements: { icon: number | null; text: string }[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const AcademicSupportBlock = (props: AcademicSupportBlockElement) => {
    const [isVisible, setIsVisible] = useState(false);
    const blockRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.7,
            }
        );

        if (blockRef.current) {
            observer.observe(blockRef.current);
        }

        return () => {
            if (blockRef.current) {
                observer.unobserve(blockRef.current);
            }
        };
    }, []);

    const getFadeInClass = (delay: number) => ({
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(20px)",
        transition: `opacity 1.5s ease-out ${delay}s, transform 1.5s ease-out ${delay}s`
    });
    const marginStyles = props.customMargin
    ? {
        marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
        marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
    }
    : {};
    return (
        <div ref={blockRef} className={cn(style.academicSupport__body)} style={marginStyles}>
            <div className={cn("flex gap-5 lg:gap-10 flex-col justify-center lg:items-start max-w-screen-xl w-full my-0 mx-auto px-4 lg:px-14 relative", style.academicSupport__animation, { [style.academicSupportVisible]: isVisible })}>
                <div className="flex items-center lg:items-start flex-col">
                    <div className={cn("pt-14 mb-5", style.academicSupport__header)} style={{...getFadeInClass(0)}}>
                        <h1>{props.header}</h1>
                    </div>
                    <div className={cn(style.academicSupport__text, "w-11/12 md:w-4/5 lg:w-4/6")} style={{...getFadeInClass(0.1)}}>
                        {props.text}
                    </div>
                    <div className={cn(style.academicSupport__text, "w-11/12 md:w-4/5 lg:w-4/6")} style={{...getFadeInClass(0.1)}}>
                        {props.secText}
                    </div>
                </div>
                <div className={cn(
                    style.academicSupport__elements,
                    "flex flex-col lg:grid lg:mt-5 text-left"
                )}>
                    {props.elements.map((el, index) => (
                        <div key={index} className={cn(style.academicSupport__element, "flex flex-col gap-5")}>
                            <div style={{...getFadeInClass(0.2)}}>
                                <img src={`${ApiBaseUrl}/api/media/${el.icon}`} alt={""}/>
                            </div>
                            <div className={style.academicSupport__elementText} style={{...getFadeInClass(0.3)}}>
                                {el.text}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={cn("flex my-8 text-center lg:hidden")}>
                    <Slider backgroundColor={"white"}>
                        {props.elements.map((el, index) => (
                            <div key={index} className={cn(style.slider__element, "flex flex-col gap-5 items-center")}>
                                <div className={cn("flex justify-center")}>
                                    <img src={`${ApiBaseUrl}/api/media/${el.icon}`} alt={""}/>
                                </div>
                                <div className={style.academicSupport__elementText}>
                                    {el.text}
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export const AcademicSupportBlockInfo: TypedBlockTypeInfo<AcademicSupportBlockElement> = {
    id: "academicSupportBlock",
    name: "Academic Support",
    preview: preview,
    renderer: AcademicSupportBlock,
    initialData: {
        header: "Header",
        text: "",
        secText: "",
        elements: [
            {
                icon: null,
                text: "string",
            },
        ],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "icon",
                        type: "Custom",
                        customType: "Image",
                        name: "Icon"
                    },
                    {
                        id: "text",
                        type: "String",
                        name: "Text"
                    },
                ],
            },
        },
        fields: [
            {
                id: "header",
                type: "String",
                name: "Header",
            },
            {
                id: "text",
                type: "String",
                name: "Text",
            },
            {
                id: "secText",
                type: "String",
                name: "Second Text"
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements"
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ]
    }
}

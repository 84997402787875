//IntroEducationalBlock
import React, { useContext, useEffect } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./IntroEducationalBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";
import localtionIconWhite from "../../assets/icons/location_white.svg";
import localtionIconBlack from "../../assets/icons/location_black.svg";
import rating from "../../assets/icons/rating.svg";
import { ComponentHostContext } from "../index";

export interface IntroEducationalBlockElement {
    background: "blue" | "image",
    backgroundImage: number | null,
    title: string;
    logo: number | null;
    subtitle: string;
    specifications: { icon: number | null; text: string; }[];
    rating: string;
    price: string;
    locationText: string;
    whiteButtonText: string;
    whiteButtonLink: string;
    showWhiteButton: boolean;
    redButtonText: string;
    showRedButton: boolean;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const IntroEducationalBlock = (props: IntroEducationalBlockElement) => {
    const backgroundStyle = props.background === "image" && props.backgroundImage
        ? { backgroundImage: `url(${ApiBaseUrl}/api/media/${props.backgroundImage})` }
        : {};
    useEffect(() => {
        const headerElement = document.getElementById("header_nav");
        const updateHeaderColor = () => {
            if (headerElement) {
                if (window.innerWidth <= 1024) {
                    if (props.background === "image" && props.backgroundImage) headerElement.classList.add(styles.transparentBackground);
                    else headerElement.classList.add(styles.blueBackground);
                } else {
                    headerElement.classList.remove(styles.blueBackground);
                    headerElement.classList.remove(styles.transparentBackground);
                }
            }
        };
        updateHeaderColor();
        window.addEventListener("resize", updateHeaderColor);
        return () => {
            window.removeEventListener("resize", updateHeaderColor);
        };
    }, []);
    const cl = useContext(ComponentHostContext);
    const formatPrice = (price: string) => {
        const match = price.match(/([€$£¥]\s?\d+([.,-]\d+)?)/);
        if (match) {
            const [matchedText] = match;
            const [before, after] = price.split(matchedText);
            return (
                <>
                    {before}
                    <strong className={cn(styles.contentPrice)}>{matchedText}</strong>
                    {after}
                </>
            );
        }
        return price;
    };
    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={cn(styles.introEducationalBlock, {
            [styles.blueBackground]: props.background === "blue",
            [styles.imageBackground]: props.background === "image",
        })} style={{...backgroundStyle, ...marginStyles}}>
            <div className="flex justify-between flex-col gap-10 lg:gap-20 lg:items-start max-w-screen-xl h-full w-full my-0 mx-auto p-6 md:p-10 lg:p-6 pt-20 lg:py-20">
                <div className="flex flex-col justify-between w-full h-full">
                    <div className="flex flex-col-reverse md:flex-row justify-between">
                        <div className="md:w-8/12 lg:w-full">
                            <div className="md:flex md:flex-col-reverse lg:flex-col">
                                <h1 className={styles.title}>{props.title}</h1>
                                {props.subtitle && <h2 className={cn("hidden md:flex", styles.subtitle)}>{props.subtitle}</h2>}
                            </div>
                            <div className={styles.specifications}>
                                {props.specifications.map((spec, index) => (
                                    <div key={index} className={styles.specification}>
                                        {spec.icon && <img src={`${ApiBaseUrl}/api/media/${spec.icon}`} width={'16px'} height={'16px'} alt={`spec-icon-${index}`} className={styles.icon} />}
                                        <span>{spec.text}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-row justify-between mb-10 md:mb-0 md:justify-start md:flex-col md:items-end ">
                            {props.logo && <img src={`${ApiBaseUrl}/api/media/${props.logo}`} alt="logo" className={styles.logo} />}
                            {props.rating && <div className={cn(styles.ratingContent)}>
                                <img src={rating} width={"16px"} height={"16px"} />
                                <span className="pl-2 h-min">{props.rating}</span>
                            </div>
                            }
                        </div>
                    </div>
                    <div className={cn({ ["flex flex-col justify-between items-start md:items-end md:mt-24 md:mb-16 md:flex-row lg:mt-36 lg:mb-2 "]: !props.showRedButton },
                        { ["flex flex-col lg:flex-row items-start lg:items-end justify-between md:mt-24 md:mb-16 lg:mt-36 lg:mb-2"]: props.showRedButton })}>
                        <div>
                            <div className={styles.textAroundPrice}>
                                {props.price && <p className={styles.price}>{formatPrice(props.price)}</p>}
                            </div>
                            {props.locationText && <div className="flex flex-row items-baseline">
                                <img src={localtionIconWhite} className="hidden md:flex" />
                                <p className={styles.locationText}>{props.locationText}</p>
                            </div>}
                        </div>
                        <div className={cn({ ["flex lg:flex-col mb-10 md:mb-0 justify-center w-full md:w-max"]: !props.showRedButton },
                            { ["flex gap-2 md:mt-12 flex-col mb-10 md:mb-0 md:flex-row lg:flex-col w-full lg:w-max"]: props.showRedButton })}>
                            {props.showWhiteButton && props.whiteButtonText && <a href={props.whiteButtonLink} className={cn(styles.whiteButton, "flex gap-1 justify-center items-center")}><img src={localtionIconBlack} />{props.whiteButtonText}</a>}
                            {props.showRedButton && props.showWhiteButton && props.redButtonText && <button onClick={() =>cl?.showContactUsFormServicePink()} className={cn(styles.redButton, "flex justify-center items-center")}>{props.redButtonText}</button>}
                        </div>
                        {props.showRedButton && !props.showWhiteButton &&
                            <div className={cn("flex w-full md:w-2/5 lg:w-auto")}>
                                <button onClick={() =>cl?.showContactUsFormServicePink()} className={cn(styles.redButton, "flex")}><span className={cn("w-full text-center")}>{props.redButtonText}</span></button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export const IntroEducationalBlockInfo: TypedBlockTypeInfo<IntroEducationalBlockElement> = {
    id: "IntroEducationalBlock",
    name: "Intro Educational",
    preview: preview,
    renderer: IntroEducationalBlock,
    initialData: {
        background: "blue",
        backgroundImage: null,
        title: "",
        logo: null,
        subtitle: "",
        specifications: [
            {
                icon: null,
                text: "",

            }],
        rating: "",
        price: "",
        locationText: "",
        whiteButtonText: "",
        whiteButtonLink: "",
        showWhiteButton: false,
        redButtonText: "",
        showRedButton: false,
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            specifications: {
                fields: [
                    {
                        id: "icon",
                        type: "Custom",
                        name: "icon",
                        customType: "Image"

                    },
                    {
                        id: "text",
                        type: "String",
                        name: "Text"
                    },
                ],
            },
        },
        fields: [
            {
                id: "background",
                name: "Background",
                type: "Radio",
                possibleValues: [
                    {
                        id: "blue",
                        name: "Blue"
                    },
                    {
                        id: "image",
                        name: "Image",
                    },
                ]
            },
            {
                id: "backgroundImage",
                type: "Custom",
                name: "Background Image",
                customType: "Image",
            },
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "subtitle",
                type: "String",
                name: "Subtitle",
            },
            {
                id: "logo",
                name: "Logo",
                type: "Custom",
                customType: "Image",
            },
            {
                id: "specifications",
                type: "List",
                listType: "specifications",
                name: "Specifications"
            },
            {
                id: "rating",
                type: "String",
                name: "Rating",
            },
            {
                id: "price",
                type: "String",
                name: "Price",
            },
            {
                id: "locationText",
                type: "String",
                name: "Location Text",
            },
            {
                id: "whiteButtonText",
                type: "String",
                name: "White Button Text",
            },
            {
                id: "whiteButtonLink",
                type: "String",
                name: "White Button Link",
            },
            {
                id: "showWhiteButton",
                type: "CheckBox",
                name: "Show White Button",
            },
            {
                id: "redButtonText",
                type: "String",
                name: "Red Button Text",
            },
            {
                id: "showRedButton",
                type: "CheckBox",
                name: "Show Red Button",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ]
    }
}

import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./ListServiceBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface ListServiceBlockElement {
    header: string;
    img: number | null;
    listTop: { textTop: string }[];
    topText: string;
    titleBottom: string;
    listBottom: { textBottom: string }[];
    bottomText: string;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
    imageSide: "left" | "right";
}

export const ListServiceBlock: React.FC<ListServiceBlockElement> = ({ header, img, listTop, topText, titleBottom, listBottom, bottomText, bottomMargin, topMargin, customMargin, imageSide }) => {
    const marginStyles = customMargin
        ? {
            marginTop: topMargin ? `${topMargin}px` : undefined,
            marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
        }
        : {};
    const resolvedImageSide = imageSide ?? "left";
    return (
        <div className={cn(style.container, "my-28 md:my-32 lg:my-40")} style={marginStyles}>
            <div className={cn(`flex flex-col lg:flex-row justify-between mx-auto w-full max-w-screen-xl`, resolvedImageSide === "left" ? "lg:flex-row" : "lg:flex-row-reverse", style.components)}>
                {img && <img src={`${ApiBaseUrl}/api/media/${img}`} className={cn("w-full lg:w-6/12 object-cover object-center")} alt="" />}
                <div className={cn(style.content, "flex flex-col justify-around")}>
                    <div className="w-full">
                        {header &&
                            <h2 dangerouslySetInnerHTML={{
                                __html: header,
                            }} />}
                        {listTop && !topText && <ul>
                            {listTop.map((item, index) => (
                                <li key={index} dangerouslySetInnerHTML={{
                                    __html: item.textTop,
                                }} />
                            ))}
                        </ul>}
                        {topText && <div dangerouslySetInnerHTML={{
                            __html: topText,
                        }} />}
                    </div>
                    <div className="w-full">
                        {titleBottom &&
                            <h3 dangerouslySetInnerHTML={{
                                __html: titleBottom
                            }} />
                        }
                        {listBottom && !bottomText &&
                            <ul>
                                {listBottom.map((item, index) => (
                                    <li key={index} dangerouslySetInnerHTML={{
                                        __html: item.textBottom
                                    }} />
                                ))}
                            </ul>
                        }
                        {bottomText && <div dangerouslySetInnerHTML={{
                            __html: bottomText,
                        }} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ListServiceBlockInfo: TypedBlockTypeInfo<ListServiceBlockElement> = {
    id: "ListServiceBlock",
    name: "List Service",
    preview: preview,
    renderer: ListServiceBlock,
    initialData: {
        header: "string",
        img: null,
        imageSide: "left",
        listTop: [
            {
                textTop: "string",
            },
        ],
        topText: "",
        titleBottom: "string",
        listBottom: [
            {
                textBottom: "string"
            },
        ],
        bottomText: "",
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            listTop: {
                fields: [
                    {
                        id: "textTop",
                        name: "Text",
                        type: "Custom",
                        customType: "Html",
                    },
                ],
            },
            listBottom: {
                fields: [
                    {
                        id: "textBottom",
                        name: "Text",
                        type: "Custom",
                        customType: "Html",
                    }
                ]
            }
        },
        fields: [
            {
                id: "header",
                type: "Custom",
                customType: "Html",
                name: "Header",
            },
            {
                id: "listTop",
                name: "List Top",
                type: "List",
                listType: "listTop",
            },
            {
                id: "topText",
                name: "Top Text instead of a list",
                type: "Custom",
                customType: "Html",
            },
            {
                id: "img",
                name: "Image",
                type: "Custom",
                customType: "Image",
            },
            {
                id: "imageSide",
                name: "Image Side",
                type: "Radio",
                possibleValues: [
                    {
                        id: "left",
                        name: "Left",
                    },
                    {
                        id: "right",
                        name: "Right",
                    },
                ],
            },
            {
                id: "titleBottom",
                name: "Title Bottom",
                type: "Custom",
                customType: "Html",
            },
            {
                id: "listBottom",
                name: "List Bottom",
                type: "List",
                listType: "listBottom",
            },
            {
                id: "bottomText",
                name: "Bottom Text instead of a list",
                type: "Custom",
                customType: "Html",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};

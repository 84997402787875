import React, { useRef } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./RecommendationsBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";
import Arrow from "../../assets/icons/arrow.svg"
import Slider from "../../ui/Slider/Slider";

export interface RecommendationsBlockElement {
    title: string;
    programs: {
        title: string;
        specifications: {
            icon: number | null;
            text: string;
        }[];
        price: string;
        description: string;
    }[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const RecommendationsBlock: React.FC<RecommendationsBlockElement> = ({ title, programs, customMargin, topMargin, bottomMargin }) => {
    const formatPrice = (price: string) => {
        const match = price.match(/([€$£¥]\s?\d+([.,-]\d+)?)/);
        if (match) {
            const [matchedText] = match;
            const [before, after] = price.split(matchedText);
            return (
                <>
                    {before}
                    <strong className={cn(styles.price)}>{matchedText}</strong>
                    {after}
                </>
            );
        }
        return price;
    };
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft -= 360;
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 360;
        }
    };
    const marginStyles = customMargin
    ? {
        marginTop: topMargin ? `${topMargin}px` : undefined,
        marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
    }
    : {};
    return (
        <div className={"md:px-10 mt-20 mb-28 md:mb-32 lg:mb-40 mx-auto w-full max-w-screen-xl items-center flex flex-col"} style={marginStyles}>
            <h2 className={styles.title}>{title}</h2>
            <div className={"hidden lg:flex gap-5 justify-center mb-12 mt-12"}>
                <button onClick={scrollLeft}><img src={Arrow} alt="left button" /></button>
                <button onClick={scrollRight} className="transform rotate-180">
                    <img src={Arrow} alt="right button" />
                </button>
            </div>
            <div ref={scrollContainerRef} className={cn("hidden md:flex justify-start max-w-screen-xl" +
                " w-full my-0 mx-auto py-6 px-2 md:px-8", styles.scrollContainer)}>
                {programs.map((program, index) => (
                    <div key={index} className={styles.programCard}>
                        <div className={styles.topDescription}>
                            <h3 className={styles.subtitle}>{program.title}</h3>
                            <div className={cn(styles.specifications)}>
                                {program.specifications.map((spec, specIndex) => (
                                    <div key={specIndex} className={styles.specification}>
                                        {spec.icon && <img src={`${ApiBaseUrl}/api/media/${spec.icon}`} alt="" className={styles.icon} />}
                                        <span>{spec.text}</span>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.aroundPrice}>
                                <span>{formatPrice(program.price)}</span>
                            </div>
                        </div>
                        <div className={styles.description}>
                            {program.description}
                        </div>
                    </div>
                ))}
            </div>
            <div className={cn("flex my-8 justify-content overflow-x-hidden md:hidden", styles.mobileSlider)}>
                <Slider backgroundColor="white">
                    {programs.map((program, index) => (
                        <div key={index} className={styles.programCard}>
                            <div className={styles.topDescription}>
                                <h3 className={styles.subtitle}>{program.title}</h3>
                                <div className={cn(styles.specifications)}>
                                    {program.specifications.map((spec, specIndex) => (
                                        <div key={specIndex} className={styles.specification}>
                                            {spec.icon && <img src={`${ApiBaseUrl}/api/media/${spec.icon}`} alt="" className={styles.icon} />}
                                            <span>{spec.text}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.aroundPrice}>
                                    <span>{formatPrice(program.price)}</span>
                                </div>
                            </div>
                            <div className={styles.description}>
                                {program.description}
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export const RecommendationsBlockInfo: TypedBlockTypeInfo<RecommendationsBlockElement> = {
    id: "RecommendationsBlock",
    name: "Recommendations",
    preview: preview,
    renderer: RecommendationsBlock,
    initialData: {
        title: "string",
        programs: [
            {
                title: "string",
                specifications: [{
                    icon: null,
                    text: "string",
                }],
                price: "string",
                description: "string",
            },
        ],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            specification: {
                fields: [
                    {
                        id: "icon",
                        type: "Custom",
                        customType: "Image",
                        name: "Icon"
                    },
                    {
                        id: "text",
                        type: "String",
                        name: "Text",
                    }
                ]
            },
            program: {
                fields: [
                    {
                        id: "title",
                        name: "Title",
                        type: "String",
                    },
                    {
                        id: "specifications",
                        name: "Specifications",
                        type: "List",
                        listType: "specification",
                    },
                    {
                        id: "price",
                        type: "String",
                        name: "Price",
                    },
                    {
                        id: "description",
                        type: "String",
                        name: "Description",
                    },
                ]
            }
        },
        fields: [
            {
                id: "title",
                name: "Title",
                type: "String",
            },
            {
                id: "programs",
                name: "Programs",
                type: "List",
                listType: "program",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};

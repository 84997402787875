import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./LineAndImageBlock.module.css";
import { ApiBaseUrl } from "../../api/apiClientBase";
import { CurveText1 } from "./CurveText1";
import { CurveText2 } from "./CurveText2";
import { CurveText3 } from "./CurveText3";
import { CurveText4 } from "./CurveText4";
import { CurveText5 } from "./CurveText5";
import { CurveText6 } from "./CurveText6";
import { CurveText7 } from "./CurveText7";
import { CurveText8 } from "./CurveText8";
import { CurveText9 } from "./CurveText9";
import { CurveText10 } from "./CurveText10";


export interface LineAndImageBlockElement {
    curveText: string;
    curveType: "1" | "2" | "3" | "4" | "5";
    backgroundImg: number | null;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const LineAndImageBlock = (props: LineAndImageBlockElement) => {
    const backgroundStyle = props.backgroundImg ? {
        backgroundImage: `url(${ApiBaseUrl}/api/media/${props.backgroundImg})`
    } : {};
    let CurveTextComponents: JSX.Element[] = [];
    switch (props.curveType) {
        case "1":
            CurveTextComponents = [
                <CurveText1 key="1" text={props.curveText} color="rgba(0, 48, 157, 1)" />,
                <CurveText2 key="2" text={props.curveText} color="rgba(0, 48, 157, 1)" />
            ];
            break;
        case "2":
            CurveTextComponents = [
                <CurveText3 key="3" text={props.curveText} color="rgba(0, 48, 157, 1)" />,
                <CurveText4 key="4" text={props.curveText} color="rgba(0, 48, 157, 1)" />
            ];
            break;
        case "3":
            CurveTextComponents = [
                <CurveText5 key="5" text={props.curveText} color="rgba(255, 255, 255, 1)" />,
                <CurveText6 key="6" text={props.curveText} color="rgba(255, 255, 255, 1)" />
            ];
            break;
        case "4":
            CurveTextComponents = [
                <CurveText7 key="7" text={props.curveText} color="rgba(255, 255, 255, 1)" />,
                <CurveText8 key="8" text={props.curveText} color="rgba(255, 255, 255, 1)" />
            ];
            break;
        case "5":
            CurveTextComponents = [
                <CurveText9 key="9" text={props.curveText} color="rgba(250, 83, 84, 1)" />,
                <CurveText10 key="10" text={props.curveText} color="rgba(250, 83, 84, 1)" />
            ];
            break;
        default:
            CurveTextComponents = [];
            break;
    }
    const marginStyles = props.customMargin
    ? {
        marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
        marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
    }
    : {};
    return (
        <div className={style.lineAndImageBlock} style={{...backgroundStyle, ...marginStyles}}>
            <div className={style.curveContainer}>
                <div className="flex flex-row justify-between w-full curve-text">
                    {CurveTextComponents}
                </div>
            </div>
        </div>
    )
}

export const LineAndImageBlockInfo: TypedBlockTypeInfo<LineAndImageBlockElement> = {
    id: "LineAndImageBlock",
    name: "Line And Image",
    preview: preview,
    renderer: LineAndImageBlock,
    initialData: {
        curveText: "string",
        curveType: "1",
        backgroundImg: null,
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        fields: [
            {
                id: "curveText",
                type: "String",
                name: "Curve Text",
            },
            {
                id: "curveType",
                type: "Radio",
                name: "Curve Type",
                possibleValues: [
                    {
                        id: "1",
                        name: "Visa support",
                    },
                    {
                        id: "2",
                        name: "Learning a language",
                    },
                    {
                        id: "3",
                        name: "Help with documents",
                    },
                    {
                        id: "4",
                        name: "Rental housing",
                    },
                    {
                        id: "5",
                        name: "On-site adaptation"
                    }
                ],
            },
            {
                id: "backgroundImg",
                type: "Custom",
                customType: "Image",
                name: "background Img",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};
import React, { useEffect, useRef, useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./RatingsBlock.module.css";
import cn from "classnames";
import { Slider } from "../../ui/Slider/Slider";

export interface RatingsBlockElement {
    header: string;
    subtitle: string;
    elements: { title: string; text: string; place: string }[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

const splitTitle = (title: string) => {
    const parts = title.split(' ');
    if (parts.length > 1) {
        return (
            <span>
                <span className={style.ratingBlock__elementHeader}>{parts.slice(0, 1).join(' ')}</span> {parts[1]}
            </span>
        );
    }
    return <span>{title}</span>;
};

export const RatingsBlock = (props: RatingsBlockElement) => {
    const [isVisible, setIsVisible] = useState(false);
    const blockRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.7,
            }
        );

        if (blockRef.current) {
            observer.observe(blockRef.current);
        }

        return () => {
            if (blockRef.current) {
                observer.unobserve(blockRef.current);
            }
        };
    }, []);

    const getFadeInClass = (delay: number) => ({
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(20px)",
        transition: `opacity 1.5s ease-out ${delay}s, transform 1.5s ease-out ${delay}s`
    });

    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div ref={blockRef} className={cn(style.ratingBlock__body)} style={marginStyles}>
            <div className={cn("flex flex-col justify-center lg:items-start max-w-screen-xl w-full my-0 mx-auto px-4 lg:px-14 relative", style.ratingBlock__animation, { [style.ratingBlockVisible]: isVisible })}>
                <div className="flex items-center lg:items-start flex-col">
                    <div className={cn("pt-14", style.ratingBlock__header)} style={{ ...getFadeInClass(0) }} dangerouslySetInnerHTML={{
                        __html: props.header
                    }} />
                    <div className={cn(style.ratingBlock__subtitle, "w-11/12 md:w-4/5 lg:w-4/6")} style={{ ...getFadeInClass(0.1) }} dangerouslySetInnerHTML={{
                        __html: props.subtitle
                    }} />
                </div>
                <div className={cn(
                    style.ratingBlock__elements,
                    "hidden lg:grid lg:mt-5 text-left"
                )}>
                    {props.elements.map((el, index) => (
                        <div key={index} className={cn(style.ratingBlock__element, "flex flex-col gap-2")}>
                            <div className={style.ratingBlock__elementHeader} style={{ ...getFadeInClass(0.2) }} dangerouslySetInnerHTML={{
                                __html: el.title
                            }} />
                            <div className={style.ratingBlock__elementText} style={{ ...getFadeInClass(0.3) }} dangerouslySetInnerHTML={{
                                __html: el.text
                            }} />
                            {el.place &&
                                <div className={style.ratingBlock__elementPlace} style={{ ...getFadeInClass(0.4) }} dangerouslySetInnerHTML={{
                                    __html: el.place
                                }} />
                            }
                        </div>
                    ))}
                </div>
                <div className={cn("flex my-8 text-center lg:hidden")}>
                    <Slider>
                        {props.elements.map((el, index) => (
                            <div key={index} className={cn(style.slider__element, "flex flex-col gap-2")}>
                                <div className={style.ratingBlock__elementHeader} dangerouslySetInnerHTML={{
                                    __html: el.title
                                }} />
                                <div className={style.ratingBlock__elementText} dangerouslySetInnerHTML={{
                                    __html: el.text
                                }} />
                                {el.place &&
                                    <div className={style.ratingBlock__elementPlace} dangerouslySetInnerHTML={{
                                        __html: el.place
                                    }} />
                                }
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export const RatingsBlockInfo: TypedBlockTypeInfo<RatingsBlockElement> = {
    id: "ratingsBlock",
    name: "Ratings",
    preview: preview,
    renderer: RatingsBlock,
    initialData: {
        header: "Header",
        subtitle: "",
        elements: [
            {
                title: "string",
                text: "string",
                place: "string"
            },
        ],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "title",
                        type: "Custom",
                        customType: "Html",
                        name: "Title"
                    },
                    {
                        id: "text",
                        type: "Custom",
                        customType: "Html",
                        name: "Text"
                    },
                    {
                        id: "place",
                        type: "Custom",
                        customType: "Html",
                        name: "Place"
                    },
                ],
            },
        },
        fields: [
            {
                id: "header",
                type: "Custom",
                customType: "Html",
                name: "Header",
            },
            {
                id: "subtitle",
                type: "Custom",
                customType: "Html",
                name: "Subtitle",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements"
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ]
    }
}

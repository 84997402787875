import { useRootStore } from "src/utils/rootStoreUtils";
import { useObserver } from "mobx-react";
import { AdminRouteNames } from "src/pages/Admin/AdminRoutes";
import React, { useState } from "react";
import { AdminTable } from "src/components/common/AdminTable";
import { AdminTraitListItemDto } from "src/interfaces/TraitPageDto";
import { dmap } from "src/utils/util";
import { AllLanguages } from "@project/components/src/utils/langs";
import { RouterLink } from "mobx-state-router";
import { Paginator } from "src/components/common/Paginator";

export const TraitPage = () => {
  const s = useRootStore().traitPage;
  const [searchQuery, setSearchQuery] = useState<string>("");

  const filteredItems = s.items.filter(item =>
    Object.values(item.names).some(name =>
      name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const sortedItems = filteredItems.sort((a, b) => {
    const nameA = a.names['en']?.toLowerCase() || '';
    const nameB = b.names['en']?.toLowerCase() || '';
    return nameA.localeCompare(nameB);
  });

  return useObserver(() => (
    <div className="container mx-auto px-4 sm:px-8 max-w-3xl">
      <div className="py-8">
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto flex flex-col">
          <div className={'flex justify-between'}>
            <div className={'flex flex-col gap-1'}>
              <RouterLink
                className={
                  "d-flex mb-4 mr-auto text-white font-bold py-2 px-4 rounded inline-block bg-blue-500 hover:bg-blue-100 hover:text-black"
                }
                routeName={AdminRouteNames.traitList}
              >
                Back to list
              </RouterLink>
              <RouterLink
                className={
                  "d-flex mb-4 mr-auto text-white font-bold py-2 px-4 rounded inline-block bg-blue-500 hover:bg-blue-100 hover:text-black"
                }
                routeName={AdminRouteNames.traitTypeEdit}
                params={{ id: s.traitTypeId }}
              >
                Edit Trait Type
              </RouterLink>
              <RouterLink
                className={
                  "d-flex mb-4 mr-auto text-white font-bold py-2 px-4 rounded inline-block bg-blue-500 hover:bg-blue-100 hover:text-black"
                }
                routeName={AdminRouteNames.traitCreate}
                params={{ id: s.traitTypeId }}
              >
                Create new
              </RouterLink>
            </div>
            <button
              onClick={async () => {
                const confirmed = window.confirm("Are you sure you want to delete this trait type?");
                if (confirmed) {
                  await s.delete();
                }
              }}
              className={"d-flex h-1/3 mb-4 text-white font-bold py-2 px-4 rounded inline-block bg-red-600 hover:bg-red-900"}>
              Delete Trait Type
            </button>
          </div>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search..."
              className="border p-2 rounded"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <AdminTable<AdminTraitListItemDto>
            columns={dmap(AllLanguages, (l) => ({
              id: l,
              header: l,
              renderer: (row) => {
                if (!row.names.hasOwnProperty(l)) return "...";
                return (
                  <RouterLink routeName={AdminRouteNames.traitItemPage} params={{ id: row.id.toString() }}>
                    <a className="text-blue-500 hover:text-blue-300 cursor-pointer underline">{row.names[l]}</a>
                  </RouterLink>
                );
              },
            }))}
            rows={sortedItems.slice(s.currentPage * 10, (s.currentPage + 1) * 10)}
            idGetter={(r) => r.id.toString()}
          />
          {s.totalPages > 1 && (
            <Paginator page={s.currentPage} totalPages={s.totalPages} setPage={(p) => (s.currentPage = p)} />
          )}
        </div>
      </div>
    </div>
  ));
};

import { ApiClientBase } from "./apiClientBase";

export type RequestFormType = {
    name: string;
    email: string;
    tel: string;
    contactWay?: string;
    com?: string;
};

export interface AdminTraitItemDto {
    namesJson: { [name: string]: string };
    info: string | null
}

export class BlockApiClient extends ApiClientBase {
    async sendConsultationRequest(req: RequestFormType) {
        const url = `call/request`;
        return await this.sendRequest(url, req);
    }

    async getAdminCities(id: string) {
        return await this.sendRequest<{info?: string | null, namesJson: {en?: string, cn?: string, esp?: string,
                fr?: string, ru?: string }}[]>("traits/cities-by-id/" + id)
    }
}

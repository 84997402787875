//BlueTextBlock
import React from "react";
import styles from "./BlueTextBlock.module.css";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import cn from "classnames";

export interface BlueTextBlockProps {
    title: string;
    topDescription: string;
    bottomDescription: string;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const BlueTextBlock = (props: BlueTextBlockProps) => {
    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={cn("w-full", styles.container_blue)} style={marginStyles}>
            <div className={cn("max-w-screen-xl gap-5 my-0 mx-auto px-2 md:px-8", styles.container)}>
                <div className={cn(styles.title)}>{props.title}</div>
                <div className={cn(styles.description, "flex flex-col gap-5")}>
                    <span>
                        {props.topDescription}
                    </span>
                    <span>
                        {props.bottomDescription}
                    </span>
                </div>
            </div>
        </div>
    );
};

export const BlueTextBlockInfo: TypedBlockTypeInfo<BlueTextBlockProps> = {
    id: "blueTextBlock",
    name: "Blue Text",
    preview: preview,
    renderer: BlueTextBlock,
    initialData: {
        title: "",
        topDescription: "",
        bottomDescription: "",
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "topDescription",
                type: "String",
                name: "Top Description",
            },
            {
                id: "bottomDescription",
                type: "String",
                name: "Bottom Description",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".newBreadcrumbsBlock_breadcrumbs__3oYib{\n  /*position: absolute;*/\n  top: 36px;\n  margin: auto;\n  left: 0;\n  right: 0;\n  flex-wrap: wrap;\n}\n\n.newBreadcrumbsBlock_breadcrumbs__3oYib a {\n  font-size: 18px;\n  font-weight: 400;\n  line-height: 21.6px;\n}\n\n.newBreadcrumbsBlock_breadcrumbs_white__3Wl4O a {\n  color: #fff !important;\n}\n\n.newBreadcrumbsBlock_breadcrumbs__3oYib a:not(:last-child) {\n  margin-right: 12px;\n}\n\n.newBreadcrumbsBlock_breadcrumbs__3oYib a > img {\n  width: 4px;\n  height: 4px;\n  margin-left: 12px;\n}\n\n.newBreadcrumbsBlock_breadcrumbs__3oYib > a:last-child {\n  text-decoration: none;\n}\n\n.newBreadcrumbsBlock_breadcrumbs__3oYib > a:last-child > a {\n  text-decoration: none;\n}\n", "",{"version":3,"sources":["webpack://../components/src/blocks/NewBreadcrumbsBlock/newBreadcrumbsBlock.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,SAAS;EACT,YAAY;EACZ,OAAO;EACP,QAAQ;EACR,eAAe;AACjB;;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".breadcrumbs{\n  /*position: absolute;*/\n  top: 36px;\n  margin: auto;\n  left: 0;\n  right: 0;\n  flex-wrap: wrap;\n}\n.breadcrumbs a {\n  font-size: 18px;\n  font-weight: 400;\n  line-height: 21.6px;\n}\n\n.breadcrumbs_white a {\n  color: #fff !important;\n}\n\n.breadcrumbs a:not(:last-child) {\n  margin-right: 12px;\n}\n\n.breadcrumbs a > img {\n  width: 4px;\n  height: 4px;\n  margin-left: 12px;\n}\n\n.breadcrumbs > a:last-child {\n  text-decoration: none;\n}\n\n.breadcrumbs > a:last-child > a {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs": "newBreadcrumbsBlock_breadcrumbs__3oYib",
	"breadcrumbs_white": "newBreadcrumbsBlock_breadcrumbs_white__3Wl4O"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useCallback, useRef, useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./SuccessStoriesBlock.module.css";
import { ApiBaseUrl } from "../../api/apiClientBase";
import preview from "./preview.png";
import Arrow from "../../assets/icons/arrow.svg";
import cn from "classnames";
import { Slider } from "../../ui/Slider/Slider";

export interface SuccessStoriesBlockElement {
  title: string;
  text: string;
  highlightedText: string;
  elements: {
    name: string;
    backgroundImg: number | null;
    video: number | null;
    description: string;
    logo: number | null;
    review?: string;
    tag: string;
  }[];
}

export const SuccessStoriesBlock = ({ title, text, highlightedText, elements }: SuccessStoriesBlockElement) => {
  const [isPlaying, setPlaying] = useState<number[]>([]);
  const [isMuted, setMuted] = useState<number[]>([]);

  const [currentTimes, setCurrentTimes] = useState<number[]>(new Array(elements.length).fill(0));
  const [durations, setDurations] = useState<number[]>(new Array(elements.length).fill(0));

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 260;
    }
  };

  const refArray = useRef<HTMLVideoElement[]>([]);
  const addToRefs = useCallback((el: HTMLVideoElement | null, index: number) => {
    if (el && !refArray.current.includes(el)) {
      refArray.current[index] = el;
    }
  }, []);

  const handlePlaying = (index: number) => {
    const video = refArray.current[index];
    if (video) {
      setDurations((prev) => {
        const newDurations = [...prev];
        newDurations[index] = video.duration;
        return newDurations;
      });
    }
  };

  const muteVideo = (index: number) => {
    const video = refArray.current[index];
    if (video) {
      video.muted = !video.muted;
      setMuted((prev) =>
          prev.includes(index) ? prev.filter((ind) => ind !== index) : [...prev, index]
      );
    }
  };

  const playVideo = (index: number) => {
    const video = refArray.current[index];
    if (video) {
      video.play();
      setPlaying((prev) => [...prev, index]);
    }
  };

  const pauseVideo = (index: number) => {
    const video = refArray.current[index];
    if (video) {
      video.pause();
      setPlaying((prev) => prev.filter((ind) => ind !== index));
    }
  };

  const handleTimeUpdate = (index: number) => {
    const video = refArray.current[index];
    console.log("currentTime: ", video.currentTime)
    if (video) {
      setCurrentTimes((prev) => {
        const newTimes = [...prev];
        newTimes[index] = video.currentTime;
        return newTimes;
      });
    }
  };

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newTime = parseFloat(e.target.value);
    const video = refArray.current[index];
    if (video) {
      video.currentTime = newTime;
      setCurrentTimes((prev) => {
        const newTimes = [...prev];
        newTimes[index] = newTime;
        return newTimes;
      });
    }
  };

  const getSliderStyle = (index: number): React.CSSProperties => {
    const currentTime = currentTimes[index];
    const duration = durations[index];
    const value = duration > 0 ? (currentTime / duration) * 100 : 0;
    return { '--value': `${value}%` } as React.CSSProperties;
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 260;
    }
  };

  const textBefore = text ? text.slice(0, text.indexOf(highlightedText)) : "";
  const textAfter = text
      ? textBefore.length !== text.length
          ? text.slice(text.indexOf(highlightedText) + highlightedText.length)
          : ""
      : "";

  return (
      <div className="py-12 mb-28 md:mb-32 lg:mb-40">
        <div className={styles.successStoriesBlock__title + " text-center md:mb-6 mb-3"}>{title}</div>
        <div className={styles.successStoriesBlock__text + " text-center md:mb-6 mb-3"}>
          {textBefore}
          {highlightedText && <span>{highlightedText}</span>}
          {textAfter}
        </div>
        <div className={"hidden lg:flex gap-5 justify-center mb-6 mt-6 mb-0"}>
          <button onClick={scrollLeft}>
            <img src={Arrow} alt="left button" />
          </button>
          <button onClick={scrollRight} className="transform rotate-180">
            <img src={Arrow} alt="right button" />
          </button>
        </div>
        <div
            ref={scrollContainerRef}
            className={cn(
                "hidden md:flex justify-start max-w-screen-xl gap-8" +
                " w-full my-0 mx-auto py-6 px-2 md:px-8 overflow-x-auto",
                styles.scrollContainer
            )}
        >
          {elements &&
              elements.map(({ name, video, backgroundImg, description, logo, review, tag }, index) => (
                  <div
                      className={cn(
                          styles.successStoriesBlock__card,
                          video && styles.successStoriesBlock__cardWithVideo,
                          backgroundImg && styles.successStoriesBlock__cardWithBackground
                      )}
                      key={index}
                      style={{ backgroundImage: `url(${ApiBaseUrl}/api/media/${backgroundImg})` }}
                  >
                    {video && (
                        <button
                            onClick={() => muteVideo(index)}
                            className={cn(
                                styles.successStoriesBlock__muteButton,
                                isPlaying.findIndex((ind) => ind === index) > -1 ? styles.successStoriesBlock__show : ""
                            )}
                        >
                          {isMuted.findIndex((ind) => ind === index) > -1 ? (
                              <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M30.2584 18.825C29.0078 18.1327 27.4112 18.3114 25.781 19.3274L22.5207 21.3707C22.2974 21.5047 22.0405 21.5829 21.7837 21.5829H20.7453H20.1871C17.485 21.5829 16 23.0679 16 25.7699V30.2361C16 32.9382 17.485 34.4232 20.1871 34.4232H20.7453H21.7837C22.0405 34.4232 22.2974 34.5014 22.5207 34.6353L25.781 36.6786C26.7636 37.2927 27.7238 37.5942 28.617 37.5942C29.1976 37.5942 29.7559 37.4602 30.2584 37.1811C31.4977 36.4888 32.19 35.0485 32.19 33.128V22.8781C32.19 20.9576 31.4977 19.5172 30.2584 18.825Z"
                                    fill="white"
                                />
                              </svg>
                          ) : (
                              <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M25.781 19.3274C27.4112 18.3114 29.0078 18.1327 30.2584 18.825C31.4977 19.5172 32.19 20.9576 32.19 22.8781V33.128C32.19 35.0485 31.4977 36.4888 30.2584 37.1811C29.7559 37.4602 29.1976 37.5942 28.617 37.5942C27.7238 37.5942 26.7636 37.2927 25.781 36.6786L22.5207 34.6353C22.2974 34.5014 22.0405 34.4232 21.7837 34.4232H20.7453H20.1871C17.485 34.4232 16 32.9382 16 30.2361V25.7699C16 23.0679 17.485 21.5829 20.1871 21.5829H20.7453H21.7837C22.0405 21.5829 22.2974 21.5047 22.5207 21.3707L25.781 19.3274ZM36.755 36.1058C36.5761 36.1058 36.4084 36.0499 36.2519 35.9381C35.8829 35.6586 35.8047 35.1331 36.0842 34.7642C39.0693 30.7841 39.0693 25.2164 36.0842 21.2363C35.8047 20.8674 35.8829 20.3419 36.2519 20.0624C36.6208 19.7829 37.1463 19.8612 37.4258 20.2301C40.8581 24.8027 40.8581 31.1977 37.4258 35.7704C37.2693 35.994 37.0121 36.1058 36.755 36.1058ZM34.7091 33.3108C34.5302 33.3108 34.3625 33.2549 34.206 33.1431C33.837 32.8636 33.7588 32.3381 34.0383 31.9692C35.7935 29.6325 35.7935 26.3679 34.0383 24.0313C33.7588 23.6624 33.837 23.1369 34.206 22.8574C34.5749 22.5779 35.1004 22.6562 35.3799 23.0251C37.5711 25.9543 37.5711 30.0462 35.3799 32.9754C35.2122 33.199 34.9662 33.3108 34.7091 33.3108Z"
                                    fill="white"
                                />
                              </svg>
                          )}
                        </button>
                    )}
                    {video && (
                    <video
                        muted={isMuted.findIndex((ind) => ind === index) > -1}
                        className={cn(
                            styles.successStoriesBlock__video,
                            isPlaying.findIndex((ind) => ind === index) > -1 ? styles.successStoriesBlock__play : ""
                        )}
                        ref={(el) => addToRefs(el, index)}
                        onTimeUpdate={() => handleTimeUpdate(index)}
                        onChange={() => handleTimeUpdate(index)}
                        onPlaying={() => handlePlaying(index)}
                    >
                          <source src={`${ApiBaseUrl}/api/media/${video}`} type="video/mp4" />
                          Your browser doesn't support HTML5 video tag.
                        </video>
                    )}
                    <div>
                      <h3 className={styles.successStoriesBlock__name}>{name}</h3>
                      <p className={styles.successStoriesBlock__description}>{description}</p>
                    </div>
                    <div className={styles.successStoriesBlock__infoBlock}>
                      {video && (
                          <button onClick={() => playVideo(index)} className={styles.successStoriesBlock__playButton}>
                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect width="80" height="80" rx="40" transform="matrix(-1 0 0 1 80 0)" fill="white" />
                              <path d="M36 40V36.6584C36 32.5095 38.3457 30.8106 41.2159 32.885L43.5316 34.5558L45.8473 36.2266C48.7176 38.301 48.7176 41.699 45.8473 43.7734L43.5316 45.4442L41.2159 47.115C38.3457 49.1894 36 47.4905 36 43.3416V40Z" />
                            </svg>
                          </button>
                      )}
                      {video && (
                          <button
                              onClick={() => pauseVideo(index)}
                              className={cn(
                                  styles.successStoriesBlock__pauseButton,
                                  isPlaying.findIndex((ind) => ind === index) > -1 ? styles.successStoriesBlock__show : ""
                              )}
                          >
                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect width="80" height="80" rx="40" transform="matrix(-1 0 0 1 80 0)" fill="white" />
                              <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M38.65 32.89V47.11C38.65 48.46 38.08 49 36.64 49H33.01C31.57 49 31 48.46 31 47.11V32.89C31 31.54 31.57 31 33.01 31H36.64C38.08 31 38.65 31.54 38.65 32.89ZM49 32.89V47.11C49 48.46 48.43 49 46.99 49H43.36C41.92 49 41.35 48.46 41.35 47.11V32.89C41.35 31.54 41.93 31 43.36 31H46.99C48.43 31 49 31.54 49 32.89Z"
                              />
                            </svg>
                          </button>
                      )}
                      {video && isPlaying && (
                          <div className={cn(styles.successStoriesBlock__videoSlider, isPlaying.findIndex((ind) => ind === index) > -1 ? styles.successStoriesBlock__show : "")}>
                            <input
                                type="range"
                                min="0"
                                max={durations[index]}
                                value={currentTimes[index]}
                                step="0.1"
                                style={getSliderStyle(index)}
                                onChange={(e) => handleSliderChange(e, index)}
                            />
                          </div>
                      )}
                      {!video && logo && (
                          <img
                              src={`${ApiBaseUrl}/api/media/${logo}`}
                              className={styles.successStoriesBlock__cardImage}
                              alt="logo"
                          />
                      )}
                      <p className={styles.successStoriesBlock__review}>{!video ? review : ""}</p>
                      <span className={styles.successStoriesBlock__tag}>{tag}</span>
                    </div>
                  </div>
              ))}
        </div>
        <div className={cn("flex my-8 mx-auto md:hidden")}>
          <Slider backgroundColor="white">
            {elements &&
                elements.map(({ name, video, backgroundImg, description, logo, review, tag }, index) => (
                    <div
                        className={cn(
                            styles.successStoriesBlock__card,
                            video && styles.successStoriesBlock__cardWithVideo,
                            backgroundImg && styles.successStoriesBlock__cardWithBackground
                        )}
                        key={index}
                        style={{ backgroundImage: `url(${ApiBaseUrl}/api/media/${backgroundImg})` }}
                    >
                      {video && (
                          <button
                              onClick={() => muteVideo(index)}
                              className={cn(
                                  styles.successStoriesBlock__muteButton,
                                  isPlaying.findIndex((ind) => ind === index) > -1 ? styles.successStoriesBlock__show : ""
                              )}
                          >
                            {isMuted.findIndex((ind) => ind === index) > -1 ? (
                                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M30.2584 18.825C29.0078 18.1327 27.4112 18.3114 25.781 19.3274L22.5207 21.3707C22.2974 21.5047 22.0405 21.5829 21.7837 21.5829H20.7453H20.1871C17.485 21.5829 16 23.0679 16 25.7699V30.2361C16 32.9382 17.485 34.4232 20.1871 34.4232H20.7453H21.7837C22.0405 34.4232 22.2974 34.5014 22.5207 34.6353L25.781 36.6786C26.7636 37.2927 27.7238 37.5942 28.617 37.5942C29.1976 37.5942 29.7559 37.4602 30.2584 37.1811C31.4977 36.4888 32.19 35.0485 32.19 33.128V22.8781C32.19 20.9576 31.4977 19.5172 30.2584 18.825Z"
                                      fill="white"
                                  />
                                </svg>
                            ) : (
                                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M25.781 19.3274C27.4112 18.3114 29.0078 18.1327 30.2584 18.825C31.4977 19.5172 32.19 20.9576 32.19 22.8781V33.128C32.19 35.0485 31.4977 36.4888 30.2584 37.1811C29.7559 37.4602 29.1976 37.5942 28.617 37.5942C27.7238 37.5942 26.7636 37.2927 25.781 36.6786L22.5207 34.6353C22.2974 34.5014 22.0405 34.4232 21.7837 34.4232H20.7453H20.1871C17.485 34.4232 16 32.9382 16 30.2361V25.7699C16 23.0679 17.485 21.5829 20.1871 21.5829H20.7453H21.7837C22.0405 21.5829 22.2974 21.5047 22.5207 21.3707L25.781 19.3274ZM36.755 36.1058C36.5761 36.1058 36.4084 36.0499 36.2519 35.9381C35.8829 35.6586 35.8047 35.1331 36.0842 34.7642C39.0693 30.7841 39.0693 25.2164 36.0842 21.2363C35.8047 20.8674 35.8829 20.3419 36.2519 20.0624C36.6208 19.7829 37.1463 19.8612 37.4258 20.2301C40.8581 24.8027 40.8581 31.1977 37.4258 35.7704C37.2693 35.994 37.0121 36.1058 36.755 36.1058ZM34.7091 33.3108C34.5302 33.3108 34.3625 33.2549 34.206 33.1431C33.837 32.8636 33.7588 32.3381 34.0383 31.9692C35.7935 29.6325 35.7935 26.3679 34.0383 24.0313C33.7588 23.6624 33.837 23.1369 34.206 22.8574C34.5749 22.5779 35.1004 22.6562 35.3799 23.0251C37.5711 25.9543 37.5711 30.0462 35.3799 32.9754C35.2122 33.199 34.9662 33.3108 34.7091 33.3108Z"
                                      fill="white"
                                  />
                                </svg>
                            )}
                          </button>
                      )}
                      {video && (
                          <video
                              muted={isMuted.findIndex((ind) => ind === index) > -1}
                              className={cn(
                                  styles.successStoriesBlock__video,
                                  isPlaying.findIndex((ind) => ind === index) > -1 ? styles.successStoriesBlock__play : ""
                              )}
                              ref={(el) => addToRefs(el, index + elements.length)}
                          >
                            <source src={`${ApiBaseUrl}/api/media/${video}`} type="video/mp4" />
                            Your browser doesn't support HTML5 video tag.
                          </video>
                      )}
                      {video && (
                        <div className={cn(styles.successStoriesBlock__videoSlider, isPlaying.findIndex((ind) => ind === index) > -1 ? styles.successStoriesBlock__show : "")}>
                          <input
                              type="range"
                              min="0"
                              max={durations[index]}
                              value={currentTimes[index]}
                              step="0.1"
                              style={getSliderStyle(index)}
                              onChange={(e) => handleSliderChange(e, index)}
                          />
                        </div>
                      )}
                        <div>
                        <h3 className={styles.successStoriesBlock__name}>{name}</h3>
                        <p className={styles.successStoriesBlock__description}>{description}</p>
                      </div>
                      <div className={styles.successStoriesBlock__infoBlock}>
                        {video && (
                            <button onClick={() => playVideo(index)} className={styles.successStoriesBlock__playButton}>
                              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="80" height="80" rx="40" transform="matrix(-1 0 0 1 80 0)" fill="white" />
                                <path d="M36 40V36.6584C36 32.5095 38.3457 30.8106 41.2159 32.885L43.5316 34.5558L45.8473 36.2266C48.7176 38.301 48.7176 41.699 45.8473 43.7734L43.5316 45.4442L41.2159 47.115C38.3457 49.1894 36 47.4905 36 43.3416V40Z" />
                              </svg>
                            </button>
                        )}
                        {video && (
                            <button
                                onClick={() => pauseVideo(index)}
                                className={cn(
                                    styles.successStoriesBlock__pauseButton,
                                    isPlaying.findIndex((ind) => ind === index) > -1 ? styles.successStoriesBlock__show : ""
                                )}
                            >
                              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="80" height="80" rx="40" transform="matrix(-1 0 0 1 80 0)" fill="white" />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M38.65 32.89V47.11C38.65 48.46 38.08 49 36.64 49H33.01C31.57 49 31 48.46 31 47.11V32.89C31 31.54 31.57 31 33.01 31H36.64C38.08 31 38.65 31.54 38.65 32.89ZM49 32.89V47.11C49 48.46 48.43 49 46.99 49H43.36C41.92 49 41.35 48.46 41.35 47.11V32.89C41.35 31.54 41.93 31 43.36 31H46.99C48.43 31 49 31.54 49 32.89Z"
                                />
                              </svg>
                            </button>
                        )}
                        {!video && logo && (
                            <img
                                src={`${ApiBaseUrl}/api/media/${logo}`}
                                className={styles.successStoriesBlock__cardImage}
                                alt="logo"
                            />
                        )}
                        <p className={styles.successStoriesBlock__review}>{!video ? review : ""}</p>
                        <span className={styles.successStoriesBlock__tag}>{tag}</span>
                      </div>
                    </div>
                ))}
          </Slider>
        </div>
      </div>
  );
};

export const SuccessStoriesBlockInfo: TypedBlockTypeInfo<SuccessStoriesBlockElement> = {
  id: "SuccessStoriesBlock",
  name: "SuccessStoriesBlock",
  preview: preview,
  renderer: SuccessStoriesBlock,
  initialData: {
    title: "string",
    text: "this is string, not a number",
    highlightedText: "string",
    elements: [
      {
        name: "string",
        description: "string",
        video: null,
        backgroundImg: null,
        logo: null,
        tag: "string",
      },
    ],
  },
  definition: {
    subTypes: {
      element: {
        fields: [
          {
            id: "name",
            type: "String",
            name: "Name",
          },
          {
            id: "video",
            name: "Video",
            type: "Custom",
            customType: "Video",
          },
          {
            id: "backgroundImg",
            name: "Background Image",
            type: "Custom",
            customType: "Image",
          },
          {
            id: "description",
            type: "String",
            name: "Description",
          },
          {
            id: "logo",
            type: "Custom",
            customType: "Image",
            name: "Logo",
          },
          {
            id: "review",
            type: "String",
            name: "Review",
          },
          {
            id: "tag",
            type: "String",
            name: "Tag",
          },
        ],
      },
    },
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "text",
        type: "String",
        name: "Text",
      },
      {
        id: "highlightedText",
        type: "String",
        name: "Highlighted Text",
      },
      {
        id: "elements",
        type: "List",
        listType: "element",
        name: "Elements",
      },
    ],
  },
};

import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./StudyPlanBlock.module.css";
import cn from "classnames";
import {ApiBaseUrl} from "../../api/apiClientBase";

export interface StudyPlanBlockElement {
    icon: null | number,
    title: string;
    text: string;
    elements: {
        title: string,
        text: string
    }[]
}

export const StudyPlanBlock = (props: StudyPlanBlockElement) => {
    return (
        <div className="flex flex-col gap-8 md:gap-12 items-center my-10 max-w-screen-xl mx-auto md:py-12 overflow-x-hidden">
            <div className={cn("flex flex-col text-center gap-7")}>
                <img src={`${ApiBaseUrl}/api/media/${props.icon}`} alt="" className={cn(styles.studyPlanBlock__icon)} />
                <div className={cn(styles.studyPlanBlock__title, "")}>
                    {props.title}
                </div>
                <div className={cn(styles.studyPlanBlock__text, "px-4")}>
                    {props.text}
                </div>
            </div>
            <div className={cn("flex flex-col gap-3 md:gap-10 items-center px-4", styles.studyPlanBlock__itemsContainer)}>
                <div className={cn("flex gap-5 w-full flex-wrap lg:flex-nowrap justify-center")}>
                    {props.elements && props.elements.map((element, index) => (
                        <div className={cn("flex flex-col", styles.studyPlanBlock__element)} key={index}>
                            <p className={cn(styles.studyPlanBlock__title)}
                               style={element.title.length >= 25 ? { width: "100%" } : element.title.length < 20 ?
                                   { width: "40%" } : { width: "60%" }}>
                                {element.title}
                            </p>
                            <p className={cn(styles.studyPlanBlock__elText)}>{element.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export const StudyPlanBlockInfo: TypedBlockTypeInfo<StudyPlanBlockElement> = {
    id: "StudyPlanBlock",
    name: "StudyPlanBlock",
    preview: preview,
    renderer: StudyPlanBlock,
    initialData: {
        icon: null,
        title: "string",
        text: "string",
        elements: [{
            title: "string",
            text: "string"
        }]
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "title",
                        type: "String",
                        name: "Title"
                    },
                    {
                        id: "text",
                        type: "String",
                        name: "Text"
                    }
                ]
            }
        },
        fields: [
            {
                id: "icon",
                type: "Custom",
                customType: "Image",
                name: "Icon",
            },
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "text",
                type: "String",
                name: "Text",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements",
            },
        ]
    }
}

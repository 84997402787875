import React from "react";
import styles from "./AuthorBlock.module.css";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface AuthorBlockProps {
    logo: number | null;
    title: string;
    author: string;
    description: string;
    socialNetwork: {
        logo: number | null;
        link: string;
    }[];
}

export const AuthorBlock = (props: AuthorBlockProps) => {
    return (
        <div className={cn(styles.container, "w-full max-w-screen-xl gap-5 my-0 mx-auto px-2 md:px-8 text-center")}>
            {props.logo && (
                <div className={styles.authorImage}>
                    <img src={`${ApiBaseUrl}/api/media/${props.logo}`} alt="Author" className="rounded-full" />
                </div>
            )}
            <div className={styles.authorInfo}>
                <h3 className={styles.title}>{props.title}</h3>
                <p className={styles.author}>{props.author}</p>
                <p className={styles.description}>{props.description}</p>
            </div>
            <div className={styles.socialLinks}>
                {props.socialNetwork.map((network, index) => (
                    <a key={index} href={network.link} target="_blank" rel="noopener noreferrer" className={styles.socialIcon}>
                        {network.logo && <img src={`${ApiBaseUrl}/api/media/${network.logo}`} alt="Social Network" />}
                    </a>
                ))}
            </div>
        </div>
    );
};

export const AuthorBlockInfo: TypedBlockTypeInfo<AuthorBlockProps> = {
    id: "authorBlock",
    name: "AuthorBlock",
    preview: preview,
    renderer: AuthorBlock,
    initialData: {
        logo: null,
        title: "",
        author: "",
        description: "",
        socialNetwork: [{
            logo: null,
            link: "",
        }]
    },
    definition: {
        subTypes: {
            socialNetwork: {
                fields: [
                    {
                        id: "logo",
                        name: "Logo",
                        type: "Custom",
                        customType: "Image",
                    },
                    {
                        id: "link",
                        name: "Link",
                        type: "String",
                    }
                ]
            }
        },
        fields: [
            {
                id: "logo",
                name: "Logo",
                type: "Custom",
                customType: "Image",
            },
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "author",
                type: "String",
                name: "Author",
            },
            {
                id: "description",
                type: "String",
                name: "Description",
            },
            {
                id: "socialNetwork",
                name: "Social Network",
                type: "List",
                listType: "socialNetwork"
            }
        ],
    },
};

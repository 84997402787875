import React, { useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import arrow from "../../assets/icons/arrow_dropdown_blue.svg"
import styles from "./ProgramsListBlock.module.css";
import cn from "classnames";

export interface ProgramsListBlockElement {
    titles: {
        text: string
    }[];
    elements: {
        title: string,
        programs: {
            name: string
        }[],
        listProgram: string;
    }[];

    showMoreButtonText: string;
    hideButtonText: string;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const ProgramsListBlock = (props: ProgramsListBlockElement) => {

    const [showAllIndexes, setShowAllIndexes] = useState<{ [key: string]: boolean }>({});

    const toggleShowAll = (index: string | number) => {
        setShowAllIndexes((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };
    const renderPrograms = (element: { id?: string | number; title: string; programs: { name: string }[], listProgram: string }, index: number) => {
        const showAll = showAllIndexes[index];
        const programsToShow = showAll ? element.programs : element.programs.slice(0, 14);
        const programsToShowMobile = showAll ? element.programs : element.programs.slice(0, 7);
        const hasPrograms = element.programs.length > 0;

        return (
            <div key={element.id || index} className={cn("flex flex-col")}>
                <div className={cn("flex flex-col gap-5")}>
                    <div className={cn(styles.programsList__text)} dangerouslySetInnerHTML={{
                        __html: element.title
                    }} />
                </div>

                {hasPrograms && (
                    <>
                        <ul className={cn(styles.programsList__programs, "hidden md:flex")}>
                            {programsToShow.map((program) => (
                                <li key={program.name} dangerouslySetInnerHTML={{
                                    __html: program.name
                                }} />
                            ))}
                        </ul>

                        <ul className={cn(styles.programsList__programsMobile, "flex md:hidden")}>
                            {programsToShowMobile.map((program) => (
                                <li key={program.name} dangerouslySetInnerHTML={{
                                    __html: program.name
                                }} />
                            ))}
                        </ul>

                        {element.programs.length > 14 && (
                            <button onClick={() => toggleShowAll(index)} className={cn("text-left flex gap-2 mt-3 hidden md:flex", styles.programsList__showMore)}>
                                {showAll ? <div dangerouslySetInnerHTML={{ __html: props.hideButtonText }} /> : <div dangerouslySetInnerHTML={{ __html: props.showMoreButtonText }} />} <img src={arrow} alt="" className={`mt-0.5 ${showAll ? styles.reverseArrow : ""}`} />
                            </button>
                        )}

                        {element.programs.length > 7 && (
                            <button onClick={() => toggleShowAll(index)} className={cn("text-left flex gap-2 mt-3 flex md:hidden", styles.programsList__showMore)}>
                                {showAll ? <div dangerouslySetInnerHTML={{ __html: props.hideButtonText }} /> : <div dangerouslySetInnerHTML={{ __html: props.showMoreButtonText }} />} <img src={arrow} alt="" className={`mt-0.5 ${showAll ? styles.reverseArrow : ""}`} />
                            </button>
                        )}
                    </>
                )}

                {!hasPrograms && (() => {
                    const listItems = element.listProgram.match(/<li>.*?<\/li>/g) || [];
                    const visibleItems = showAll ? listItems : listItems.slice(0, 14);

                    return (
                        <>
                            <div className={cn(styles.programsList__programsList)}>
                                <ul dangerouslySetInnerHTML={{ __html: visibleItems.join("") }} />
                            </div>

                            {listItems.length > 14 && (
                                <button onClick={() => toggleShowAll(index)} className={cn("text-left flex gap-2 mt-3 hidden md:flex", styles.programsList__showMore)}>
                                    {showAll ? <div dangerouslySetInnerHTML={{ __html: props.hideButtonText }} /> : <div dangerouslySetInnerHTML={{ __html: props.showMoreButtonText }} />}
                                    <img src={arrow} alt="" className={`mt-0.5 ${showAll ? styles.reverseArrow : ""}`} />
                                </button>
                            )}

                            {listItems.length > 7 && (
                                <button onClick={() => toggleShowAll(index)} className={cn("text-left flex gap-2 mt-3 flex md:hidden", styles.programsList__showMore)}>
                                    {showAll ? <div dangerouslySetInnerHTML={{ __html: props.hideButtonText }} /> : <div dangerouslySetInnerHTML={{ __html: props.showMoreButtonText }} />}
                                    <img src={arrow} alt="" className={`mt-0.5 ${showAll ? styles.reverseArrow : ""}`} />
                                </button>
                            )}
                        </>
                    );
                })()}

                <hr className={cn("my-8 w-full")} />
            </div>
        );
    };

    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={cn(styles.programsList__body, "w-full")} style={marginStyles}>
            <div className={cn("flex flex-col py-12 px-4 md:px-12 lg:px-20 max-w-screen-xl justify-center gap-10 mx-auto my-0 ")}>
                <div className={"flex flex-col md:gap-7 items-center text-center"}>
                    <div className={cn(styles.programsList__title, "mb-8 w-full")}>
                        {props.titles && props.titles.map((title) => <div dangerouslySetInnerHTML={{
                            __html: title.text
                        }} />)}
                    </div>
                    <div className={cn("flex flex-col w-full mb-5")}>
                        <hr className={cn("my-8 w-full")} />
                        <div>{props.elements && props.elements.map(renderPrograms)}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ProgramsListBlockInfo: TypedBlockTypeInfo<ProgramsListBlockElement> = {
    id: "ProgramsListBlock",
    name: "Programs List",
    preview: preview,
    renderer: ProgramsListBlock,
    initialData: {
        titles: [{
            text: "string"
        }],
        elements: [{
            title: "string",
            programs: [{
                name: "string"
            }],
            listProgram: "",
        }],
        showMoreButtonText: "string",
        hideButtonText: "string",
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            title: {
                fields: [
                    {
                        id: "text",
                        type: "Custom",
                        customType: "Html",
                        name: "Text",
                    }
                ]
            },
            program: {
                fields: [
                    {
                        id: "name",
                        type: "Custom",
                        customType: "Html",
                        name: "Name",
                    }
                ]
            },
            element: {
                fields: [
                    {
                        id: "title",
                        type: "Custom",
                        customType: "Html",
                        name: "Title",
                    },
                    {
                        id: "programs",
                        type: "List",
                        listType: "program",
                        name: "Programs",
                    },
                    {
                        id: "listProgram",
                        type: "Custom",
                        customType: "Html",
                        name: "List Program",
                    },
                ]
            }
        },
        fields: [
            {
                id: "titles",
                type: "List",
                listType: "title",
                name: "Titles",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements",
            },
            {
                id: "showMoreButtonText",
                type: "Custom",
                customType: "Html",
                name: "Show More Button Text",
            },
            {
                id: "hideButtonText",
                type: "Custom",
                customType: "Html",
                name: "Hide Button Text",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ]
    }
}

import React from "react";

import styles from "./EducationalDirectionsBlock.module.css";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import {ApiBaseUrl} from "../../api/apiClientBase";
import cn from "classnames";
import { CurvedText1 } from "./CurvedText1";
import { CurvedText2 } from "./CurvedText2";
import { CurvedText3 } from "./CurvedText3";
import { CurvedText4 } from "./CurvedText4";

export interface EducationalDirectionsProps {
  title: string;
  curveText: string;
  elements: {
    title: string;
    text: string;
    backgroundImg:  number | null;
    adult: boolean;
    rowsTitle: string;
    rows: {
      title: string;
      price: string;
    }[]
  }[];
}

export const EducationalDirectionsBlock: React.FC<EducationalDirectionsProps> = ({ title, curveText, elements }) => {
  const firstElement = elements[0];
  const restElements = elements.slice(1,4);
  return (
    <div className="py-12">
      <h2 className={styles.educationalDirectionsBlock__title + " text-center md:mb-6 mb-0"}>{title}</h2>
      <div className={"flex flex-col justify-between max-w-screen-xl gap-5 w-full my-0 mx-auto py-6 px-2 md:px-8"}>
        <div className={cn(styles.educationalDirectionsBlock__topItem, "w-full p-7 relative overflow-hidden")}
             style={{ backgroundImage: `url(${ApiBaseUrl}/api/media/${firstElement.backgroundImg})` }}>
          <div className={styles.educationalDirectionsBlock__info}>
            <h3
              className={cn(styles.educationalDirectionsBlock__blockTitle, firstElement.adult ? styles.educationalDirectionsBlock__blockTitleAdult : "")}>
              {firstElement.title}
            </h3>
            {firstElement.text && <p
              className={cn(styles.educationalDirectionsBlock__blockText, styles.educationalDirectionsBlock__styleText, styles.educationalDirectionsBlock__mobileHidden)}>
              {firstElement.text}
            </p>}
            {firstElement.rowsTitle && <p
              className={cn(styles.educationalDirectionsBlock__blockText)}>
              {firstElement.rowsTitle}
            </p>}
            {firstElement.rows.length > 0 && firstElement.rows.map(({ title, price }) => <p
                className={cn(styles.educationalDirectionsBlock__row, !price && styles.educationalDirectionsBlock__rowNoBorder)}>
                <span>{title}</span>
                <span>{price}</span>
              </p>
            )}
          </div>
          <div className={cn(styles.educationalDirectionsBlock__curve1)}>
            <CurvedText1 text={`${curveText}`} color={`#FA5354`} />
          </div>
          <div className={cn(styles.educationalDirectionsBlock__curve2)}>
            <CurvedText2 text={`${curveText}`} color={`#FA5354`} />
          </div>
          <div className={cn(styles.educationalDirectionsBlock__curve3)}>
            <CurvedText3 text={`${curveText}`} color={`#FA5354`} />
          </div>
          <div className={cn(styles.educationalDirectionsBlock__curve4)}>
            <CurvedText4 text={`${curveText}`} color={`#FA5354`} />
          </div>
        </div>
        <div className={styles.educationalDirectionsBlock__bottomBlocks + " flex flex-col md:flex-row gap-5"}>
          {restElements.map(({ title, text, backgroundImg, adult, rowsTitle, rows }) => <div
            className={"p-7 bg-cover bg-no-repeat bg-bottom"}
            style={{ backgroundImage: `url(${ApiBaseUrl}/api/media/${backgroundImg})` }}>
            <div className={styles.educationalDirectionsBlock__info}>
              <h3
                className={cn(styles.educationalDirectionsBlock__blockTitle, adult ? styles.educationalDirectionsBlock__blockTitleAdult : "")}>
                {title}
              </h3>
              {text && <p className={styles.educationalDirectionsBlock__blockText}>
                {text}
              </p>}
              {rowsTitle && <p className={cn(styles.educationalDirectionsBlock__blockText)}>
                {rowsTitle}
              </p>}
              {rows.length > 0 && rows.map(({ title, price }) => <p className={cn(styles.educationalDirectionsBlock__row, !price && styles.educationalDirectionsBlock__rowNoBorder)}>
                  <span>{title}</span>
                  <span>{price}</span>
                </p>
              )}
            </div>
          </div>)}
        </div>
      </div>
    </div>

  );
}

export const EducationalDirectionsBlockInfo: TypedBlockTypeInfo<EducationalDirectionsProps> = {
  id: "EducationalDirectionsBlock",
  name: "EducationalDirectionsBlock",
  preview: preview,
  renderer: EducationalDirectionsBlock,
  initialData: {
    title: "string",
    curveText: "string",
    elements: [
      {
        title: "string",
        text: "string",
        backgroundImg: null,
        adult: false,
        rowsTitle: "string",
        rows: [
          {title: 'США', price: "от $ 1260"},
          {title: 'Англия', price: "от £ 1056"},
          {title: 'Мальта', price: "от € 630"}
        ]
      },
      {
        title: "string",
        text: "string",
        backgroundImg: null,
        adult: false,
        rowsTitle: "string",
        rows: [
          {title: 'США', price: "от $ 1260"},
          {title: 'Англия', price: "от £ 1056"},
          {title: 'Мальта', price: "от € 630"}
        ]
      },
      {
        title: "string",
        adult: true,
        text: "string",
        backgroundImg: null,
        rowsTitle: "string",
        rows: [
          {title: 'США', price: "от $ 1260"},
          {title: 'Англия', price: "от £ 1056"},
          {title: 'Мальта', price: "от € 630"}
        ]
      },
      {
        title: "string",
        text: "string",
        backgroundImg: null,
        adult: false,
        rowsTitle: "string",
        rows: [
          {title: 'США', price: "от $ 1260"},
          {title: 'Англия', price: "от £ 1056"},
          {title: 'Мальта', price: "от € 630"}
        ]
      }
    ]
  },
  definition: {
    subTypes: {
      rows: {
        fields: [
          {
            id: "title",
            type: "String",
            name: "Title",
          },
          {
            id: "price",
            type: "String",
            name: "Price",
          },
        ],
      },
      element: {
        fields: [
          {
            id: "title",
            name: "Title",
            type: "String",
          },
          {
            id: "text",
            name: "Text",
            type: "String",
          },
          {
            id: "adult",
            type: "CheckBox",
            name: "Adult",
          },
          {
            id: "backgroundImg",
            name: "Background Image",
            type: "Custom",
            customType: "Image",
          },
          {
            id: "rowsTitle",
            name: "Rows title",
            type: "String",
          },
          {
            id: "rows",
            name: "Rows",
            type: "List",
            listType: "rows",
          }
        ],
      },
    },
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "curveText",
        type: "String",
        name: "CurveText  ",
      },
      {
        id: "elements",
        name: "Elements",
        type: "List",
        listType: "element",
      }
    ],
  },
};

import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./AboutServiceBlock.module.css";
import cn from "classnames";

export interface AboutServiceBlockElement {
    title: string;
    subtitle: string;
    text: string;
    extraText: string
}

export const AboutServiceBlock = (props: AboutServiceBlockElement) => {
    return (
        <div className="flex flex-col p-12 max-w-screen-xl justify-center gap-10 mx-auto my-0 " id={"aboutServiceBlock"}>
                <div className={"flex flex-col gap-7 items-center text-center"}>
                    <div className={cn(styles.aboutServiceBlock__title)}>
                        {props.title}
                    </div>
                    <div className={cn(styles.aboutServiceBlock__subtitle)}>
                        {props.subtitle}
                    </div>
                    <div className={"flex flex-col gap-3"}>
                        <div className={cn(styles.aboutServiceBlock__text)}>
                            {props.text}
                        </div>
                        <div className={cn(styles.aboutServiceBlock__text)}>
                            {props.extraText}
                        </div>
                    </div>
                </div>
        </div>
    )
}

export const AboutServiceBlockInfo: TypedBlockTypeInfo<AboutServiceBlockElement> = {
    id: "AboutServiceBlock",
    name: "AboutServiceBlock",
    preview: preview,
    renderer: AboutServiceBlock,
    initialData: {
        title: "string",
        subtitle: "string",
        text: "string",
        extraText: "string",
    },
    definition: {
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "subtitle",
                type: "String",
                name: "Subtitle",
            },
            {
                id: "text",
                type: "String",
                name: "Text",
            },
            {
                id: "extraText",
                type: "String",
                name: "Extra Text",
            },
        ]
    }
}

import React, { useEffect, useRef, useState } from 'react';
import style from './HistoryBlock.module.css';

interface TimelineSliderProps {
  elements: { title: string; subtitle?: string; text: string }[];
}

const TimelineSlider: React.FC<TimelineSliderProps> = ({ elements }) => {
    const timelineRef = useRef<HTMLDivElement>(null);
    const elementsRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const scrollInterval = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (elements.length > 0 && elementsRefs.current[0] && timelineRef.current) {
            const firstElement = elementsRefs.current[0];
            const timeline = timelineRef.current;

            const isVisible = firstElement.offsetLeft >= timeline.scrollLeft &&
                firstElement.offsetLeft < timeline.scrollLeft + timeline.offsetWidth;

            if (!isVisible) {
                timeline.scrollLeft = firstElement.offsetLeft - (timeline.offsetWidth / 2) + (firstElement.offsetWidth / 2);
            }
            setActiveIndex(0);
        }
    }, [elements.length]);

    useEffect(() => {
        const timeline = timelineRef.current;

        const handleScroll = () => {
            if (!timeline) return;
            const scrollLeft = timeline.scrollLeft;
            const totalWidth = timeline.scrollWidth;
            const visibleWidth = timeline.offsetWidth;
            const currentIndex = Math.round((scrollLeft / (totalWidth - visibleWidth)) * (elements.length - 1));
            setActiveIndex(currentIndex);
        };

        if (timeline) {
            timeline.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (timeline) {
                timeline.removeEventListener('scroll', handleScroll);
            }
        };
    }, [elements.length]);

    const startAutoScroll = () => {
        const timeline = timelineRef.current;
        if (!timeline) return;

        scrollInterval.current = setInterval(() => {
            timeline.scrollLeft += 7 ;
        }, 10);
    };

    const stopAutoScroll = () => {
        if (scrollInterval.current) {
            clearInterval(scrollInterval.current);
            scrollInterval.current = null;
        }
    };

    const handleMouseEnter = () => {
        startAutoScroll();
    };

    const handleMouseLeave = () => {
        stopAutoScroll();
    };

    const handleManualScroll = () => {
        stopAutoScroll();
    };

    const handleDotClick = (index: number) => {
        setActiveIndex(index);
        const element = elementsRefs.current[index];
        const timeline = timelineRef.current;

        if (element && timeline) {
            timeline.scrollLeft = element.offsetLeft - (timeline.offsetWidth / 2) + (element.offsetWidth / 2);
        }
    };

    return (
        <div className={style.scrollableTimelineContainer}>
            <div className={style.timelineWrapper} ref={timelineRef} onMouseEnter={handleMouseEnter} onWheel={handleManualScroll}  onMouseDown={handleManualScroll}
                 onMouseLeave={handleMouseLeave}>
                <div className={style.line} />
                <div className={style.timeline}>

                    {elements.map((el, index) => (
                        <div
                            key={index}
                            className={`${style.timelineElement} ${index === activeIndex ? style.active : ''}`}
                            ref={(el) => (elementsRefs.current[index] = el)}
                            style={{ maxWidth: elements.length < 4 ? "30%" : "15%" }}
                        >
                            <div
                                className={`${style.dot} ${index === activeIndex ? style.active : ''}`}
                                onClick={() => handleDotClick(index)}
                            />
                            <div
                                className={style.timelineItemTitle}
                                onClick={() => handleDotClick(index)}
                            >{el.title}</div>
                            {el.subtitle && (
                                <div className={style.timelineItemSubtitle} onClick={() => handleDotClick(index)}>
                                    {el.subtitle}
                                </div>
                            )}
                            <div
                                className={style.timelineItemText}
                                onClick={() => handleDotClick(index)}
                            >{el.text}</div>

                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TimelineSlider;

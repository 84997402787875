import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./TextAndImageBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface TextAndImageBlockElement {
    title: string;
    highlight: string;
    text: string;
    backgroundColor?: "pink" | "blue";
    img: number | null;
    icon: number | null;
    description: string;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

const highlightMatchAsHtml = (title: string, highlight: string, backgroundColor?: string): string => {
    if (!highlight || !title.includes(highlight)) return title;

    const highlightStyle =
        backgroundColor === "blue"
            ? "background-color: #CEE1F3; color: #00309D;"
            : "background-color: rgba(250, 83, 84, 1); color: rgba(255, 255, 255, 1);";

    return title.replace(
        new RegExp(`(${highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, "gi"),
        `<span style="${highlightStyle} padding: 0 5px;">$1</span>`
    );
};

export const TextAndImageBlock = (props: TextAndImageBlockElement) => {
    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    const highlightedTitleHtml = highlightMatchAsHtml(props.title, props.highlight, props.backgroundColor);
    return (
        <div style={{ backgroundColor: props.backgroundColor === "blue" ? "#00309D" : "#F3D7E3", color: props.backgroundColor === "blue" ? "white" : "black", ...marginStyles }}
            className={cn("mt-28 md:mt-32 lg:mt-40 mb-28 md:mb-32 lg:mb-40")}>
            <div className="flex flex-col lg:flex-row justify-between mx-auto w-full max-w-screen-xl">
                <div className="w-full lg:w-6/12 mb-16 lg:mb-0">
                    {props.img && <img className={`w-full h-full object-cover object-center ${style.image}`} src={`${ApiBaseUrl}/api/media/${props.img}`} alt="" />}
                </div>
                <div className="mx-auto w-full lg:w-6/12 flex flex-col justify-evenly px-6 lg:ml-20 flex flex-col items-center lg:items-start">
                    <div className="flex flex-col items-center lg:items-start">
                        <h2 className={cn(style.title, "w-10/12 md:w-8/12 lg:w-full")} dangerouslySetInnerHTML={{
                            __html: highlightedTitleHtml
                        }} />
                        {props.text &&
                            <p className={cn(style.main_text, "w-10/12 lg:w-full")} dangerouslySetInnerHTML={{
                                __html: props.text
                            }} />
                        }
                    </div>
                    <div className={cn(style.main_text, "mb-16 lg:mb-0 w-10/12")}>
                        {props.icon && <img src={`${ApiBaseUrl}/api/media/${props.icon}`} alt="" width="40" height="40" className="mb-6 hidden lg:block" />}
                        {props.description && <div className={cn("mt-2 lg:mt-0")} dangerouslySetInnerHTML={{
                            __html: props.description
                        }} />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export const TextAndImageBlockInfo: TypedBlockTypeInfo<TextAndImageBlockElement> = {
    id: "TextAndImageBlock",
    name: "Text And Image",
    preview: preview,
    renderer: TextAndImageBlock,
    initialData: {
        title: "string",
        highlight: "string",
        text: "string",
        backgroundColor: "pink",
        img: null,
        icon: null,
        description: "string",
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        fields: [
            {
                id: "title",
                type: "Custom",
                customType: "Html",
                name: "Title",
            },
            {
                id: "highlight",
                type: "String",
                name: "Highlight"
            },
            {
                id: "text",
                type: "Custom",
                customType: "Html",
                name: "Text",
            },
            {
                id: "backgroundColor",
                type: "Radio",
                name: "Background Color",
                possibleValues: [
                    {
                        id: "pink",
                        name: "pink",
                    },
                    {
                        id: "blue",
                        name: "blue",
                    },
                ],
            },
            {
                id: "img",
                type: "Custom",
                customType: "Image",
                name: "Img",
            },
            {
                id: "icon",
                type: "Custom",
                customType: "Image",
                name: "Icon",
            },
            {
                id: "description",
                type: "Custom",
                customType: "Html",
                name: "Description",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};

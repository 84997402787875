import React, { useRef } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./AwardsBlock.module.css"
import { ApiBaseUrl } from "../../api/apiClientBase";
import preview from "./preview.png";
import Arrow from "../../assets/icons/white_arrow.svg"
import cn from "classnames";
import { Slider } from "../../ui/Slider/Slider";

export interface AwardsBlockElement {
    title: string;
    elements: {
        logo: number | null,
        description: string,
        ranking: string,
    }[];
    certificatesTitle: string;
    certificates: {
        logo: number | null,
    }[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const AwardsBlock = (props: AwardsBlockElement) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft -= 260;
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 260;
        }
    };

    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className="my-12" style={marginStyles}>
            <div className={styles.awardsBlock__title + " text-center md:mb-6 mb-3"} dangerouslySetInnerHTML={{
                __html: props.title
            }} />
            <div className={"hidden lg:flex gap-5 justify-center mb-6 mt-6 mb-0"}>
                <button onClick={scrollLeft} className={styles.awardsBlock__arrowButton}><img src={Arrow} alt="left button" /></button>
                <button onClick={scrollRight} className={cn("transform rotate-180", styles.awardsBlock__arrowButton)}>
                    <img src={Arrow} alt="right button" />
                </button>
            </div>
            <div ref={scrollContainerRef} className={cn("hidden md:flex justify-start max-w-screen-xl gap-8 " +
                "w-full my-0 mx-auto py-6 px-2 md:px-8 overflow-x-auto", styles.scrollContainer)}>
                {props.elements && props.elements.map((element, index) =>
                    <div className={styles.awardsBlock__card + " flex flex-col justify-between"} key={index}>
                        <img src={`${ApiBaseUrl}/api/media/${element.logo}`} className={styles.awardsBlock__cardImage} alt="logo" />
                        {element.description && <div className={styles.awardsBlock__description} dangerouslySetInnerHTML={{
                            __html: element.description
                        }} />}
                        {element.ranking && <div className={styles.awardsBlock__ranking} dangerouslySetInnerHTML={{
                            __html: element.ranking
                        }} />}
                    </div>
                )}
            </div>
            <div className={cn("flex my-8 mx-auto md:hidden")}>
                <Slider backgroundColor="white">
                    {props.elements.map((element, index) => (
                        <div className={styles.awardsBlock__card + " mx-auto my-0 flex flex-col justify-between"} key={index}>
                            <img src={`${ApiBaseUrl}/api/media/${element.logo}`} className={styles.awardsBlock__cardImage} alt="logo" />
                            {element.description && <div className={styles.awardsBlock__description} dangerouslySetInnerHTML={{
                                __html: element.description
                            }} />}
                            {element.ranking && <div className={styles.awardsBlock__ranking} dangerouslySetInnerHTML={{
                                __html: element.ranking
                            }} />}
                        </div>
                    ))}
                </Slider>
            </div >
            <div className={"flex flex-col justify-center items-center"}>
                <div className={cn(styles.awardsBlock__description, "text-center my-6 w-5/6 md:w-2/4")} dangerouslySetInnerHTML={{
                    __html: props.certificatesTitle
                }} />
                {/*<div className="flex lg:flex-row flex-col justify-between mx-auto content-center max-w-screen-xl md:mt-20 mt-2 px-4 gap-5">*/}
                <div className={"flex flex-row gap-3 md:gap-10 justify-center mt-8"}>
                    {props.certificates && props.certificates.map((certificate, index) =>
                        <img alt="" src={`${ApiBaseUrl}/api/media/${certificate.logo}`} key={index} className={styles.awardsBlock__certificate} />
                    )}
                </div>
                {/*</div>*/}
            </div>
        </div >

    );
};

export const AwardsBlockInfo: TypedBlockTypeInfo<AwardsBlockElement> = {
    id: "AwardsBlock",
    name: "Awards",
    preview: preview,
    renderer: AwardsBlock,
    initialData: {
        title: "string",
        elements: [{
            logo: null,
            description: "string",
            ranking: "string",
        }],
        certificatesTitle: "string",
        certificates: [{
            logo: null,
        }],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            certificate: {
                fields: [
                    {
                        id: "logo",
                        type: "Custom",
                        customType: "Image",
                        name: "Logo"
                    },
                ]
            },
            element: {
                fields: [
                    {
                        id: "logo",
                        type: "Custom",
                        customType: "Image",
                        name: "Logo",
                    },
                    {
                        id: "description",
                        type: "Custom",
                        customType: "Html",
                        name: "Description"
                    },
                    {
                        id: "ranking",
                        type: "Custom",
                        customType: "Html",
                        name: "Ranking"
                    },
                ],
            },
        },
        fields: [
            {
                id: "title",
                type: "Custom",
                customType: "Html",
                name: "Title",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements"
            },
            {
                id: "certificatesTitle",
                type: "Custom",
                customType: "Html",
                name: "Certificates Title",
            },
            {
                id: "certificates",
                type: "List",
                listType: "certificate",
                name: "Certificates",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};

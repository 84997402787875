// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImgAndSignatureAndTextBlock_scrollContainer__1Sv7k {\n  display: flex;\n  overflow-x: auto;\n  grid-gap: 16px;\n  gap: 16px;\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n\n.ImgAndSignatureAndTextBlock_scrollContainer__1Sv7k::-webkit-scrollbar {\n  display: none;\n}\n\n.ImgAndSignatureAndTextBlock_scrollContainer__1Sv7k div img {\n  width: 100%;\n  height: 350px;\n  -o-object-fit: cover;\n  object-fit: cover;\n}\n\n@media (max-width: 767px){\n  .ImgAndSignatureAndTextBlock_scrollContainer__1Sv7k{\n    display: none;\n  }\n}", "",{"version":3,"sources":["webpack://../components/src/blocks/ImgAndSignatureAndTextBlock/ImgAndSignatureAndTextBlock.module.css"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,gBAAgB;EAChB,cAAS;EAAT,SAAS;EACT,wBAAwB;EACxB,qBAAqB;AACzB;;AAEA;EACI,aAAa;AACjB;;AAEA;EACI,WAAW;EACX,aAAa;EACb,oBAAiB;EAAjB,iBAAiB;AACrB;;AAEA;EACI;IACI,aAAa;EACjB;AACJ","sourcesContent":[".scrollContainer {\n    display: flex;\n    overflow-x: auto;\n    gap: 16px;\n    -ms-overflow-style: none;\n    scrollbar-width: none;\n}\n\n.scrollContainer::-webkit-scrollbar {\n    display: none;\n}\n\n.scrollContainer div img {\n    width: 100%;\n    height: 350px;\n    object-fit: cover;\n}\n\n@media (max-width: 767px){\n    .scrollContainer{\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollContainer": "ImgAndSignatureAndTextBlock_scrollContainer__1Sv7k"
};
export default ___CSS_LOADER_EXPORT___;

import {FileLoader} from "src/components/fileLoader/FileLoader";

export const FilesPage = () => {

  return <div className="container mx-auto px-4 sm:px-8 max-w-3xl">
    <div className="py-8">
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <FileLoader />
      </div>
    </div>
  </div>
}


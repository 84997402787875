import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./WorkBasisBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface WorkBasisBlockElement {
    logo: null | number;
    title: string;
    text: string;
    extraText: string;
    image: null | number;
    buttonText: string;
    buttonLink: string;
    showButton: boolean;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const WorkBasisBlock = (props: WorkBasisBlockElement) => {
    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className="flex flex-col lg:p-6 p-4 max-w-screen-xl justify-between gap-10 mx-auto my-10 lg:my-0" style={marginStyles}>
            <div className={cn("flex flex-col justify-between items-center gap-5")}>
                {props.logo && <img src={`${ApiBaseUrl}/api/media/${props.logo}`} alt="" className={cn(styles.workBasisBlock__logo, "md:mb-10")} />}
                <div className={cn(styles.workBasisBlock__title)} dangerouslySetInnerHTML={{ __html: props.title }} />
                <div className={cn(styles.workBasisBlock__text, "items-center")} dangerouslySetInnerHTML={{ __html: props.text }} />
                <div className={cn(styles.workBasisBlock__text, "items-center")} dangerouslySetInnerHTML={{ __html: props.extraText }} />
            </div>
            {props.image &&
                <img src={`${ApiBaseUrl}/api/media/${props.image}`} alt="" className={cn(styles.workBasisBlock__img)} />
            }
            {props.showButton &&
                <a className={cn(styles.workBasisBlock__button)} href={props.buttonLink} dangerouslySetInnerHTML={{ __html: props.buttonText }} />
            }
        </div>
    )
}

export const WorkBasisBlockInfo: TypedBlockTypeInfo<WorkBasisBlockElement> = {
    id: "WorkBasisBlock",
    name: "Work Basis",
    preview: preview,
    renderer: WorkBasisBlock,
    initialData: {
        logo: null,
        title: "string",
        text: "string",
        extraText: "",
        image: null,
        buttonText: "string",
        buttonLink: "",
        showButton: false,
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        fields: [
            {
                id: "logo",
                type: "Custom",
                customType: "Image",
                name: "Logo",
            },
            {
                id: "title",
                type: "Custom",
                customType: "Html",
                name: "Title",
            },
            {
                id: "text",
                type: "Custom",
                customType: "Html",
                name: "Text",
            },
            {
                id: "extraText",
                type: "Custom",
                customType: "Html",
                name: "Extra Text",
            },
            {
                id: "image",
                type: "Custom",
                customType: "Image",
                name: "Image",
            },
            {
                id: "buttonText",
                type: "Custom",
                customType: "Html",
                name: "Button Text",
            },
            {
                id: "buttonLink",
                type: "String",
                name: "Button Link",
            },
            {
                id: "showButton",
                type: "CheckBox",
                name: "Show Button"
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ]
    }
}

import React, { useContext } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./ContactInfoBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";
import { ComponentHostContext } from "../index";

export interface ContactInfoBlockElement {
    title: string;
    phone1: string;
    phone2: string;
    socialMedia: string;
    email: string;
    workingHours: string;
    responseTime: string;
    buttonText: string;
    phoneIcon: number | null;
    phoneIcon2: number | null;
    emailIcon: number | null;
    socialIcon: number | null;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const ContactInfoBlock: React.FC<ContactInfoBlockElement> = ({
    title,
    phone1,
    phone2,
    socialMedia,
    email,
    workingHours,
    responseTime,
    buttonText,
    phoneIcon,
    phoneIcon2,
    emailIcon,
    socialIcon,
    customMargin,
    topMargin,
    bottomMargin
}) => {
    const cl = useContext(ComponentHostContext);
    const marginStyles = customMargin
        ? {
            marginTop: topMargin ? `${topMargin}px` : undefined,
            marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className="mt-20" style={marginStyles}>
            <div className="flex flex-col items-center lg:items-start justify-between mx-auto w-full p-12 max-w-screen-xl text-sm lg:text-lg">
                <div className="flex w-full justify-center">
                    <h2 className={style.title}>{title}</h2>
                </div>
                <div className={style.contactDetails}>
                    <div>
                        <div className="flex flex-col md:flex-row gap-2 md:gap-10 ">
                            <div className={style.contactItem}>
                                {phoneIcon && <img src={`${ApiBaseUrl}/api/media/${phoneIcon}`} alt="Phone Icon" className={style.icon} />}
                                <span>{phone1}</span>
                            </div>
                            <div className={style.contactItem}>
                                {socialIcon && <img src={`${ApiBaseUrl}/api/media/${socialIcon}`} alt="Social Icon" className={style.icon} />}
                                <span>{socialMedia}</span>
                            </div>
                            <div className={style.contactItem}>
                                {phoneIcon && <img src={`${ApiBaseUrl}/api/media/${phoneIcon2}`} alt="Phone Icon" className={style.icon} />}
                                <span>{phone2}</span>
                            </div>
                        </div>
                        <div className={cn(style.infoItem)}>
                            <span>{workingHours}</span>
                        </div>
                    </div>
                    <div className="lg:mr-16 md:m-0">
                        <div className={cn(style.infoItem)}>
                            {emailIcon && <img src={`${ApiBaseUrl}/api/media/${emailIcon}`} alt="Email Icon" className={style.icon} />}
                            <span>{email}</span>
                        </div>
                        <div className={style.infoItem}>
                            <span>{responseTime}</span>
                        </div>
                    </div>
                </div>
                <button className={style.contactButton} onClick={() =>cl?.showContactUsFormBlue()}>
                    {buttonText}
                </button>
            </div>
        </div>
    );
};

export const ContactInfoBlockInfo: TypedBlockTypeInfo<ContactInfoBlockElement> = {
    id: "ContactInfoBlock",
    name: "Contact Info",
    preview: preview,
    renderer: ContactInfoBlock,
    initialData: {
        title: "Хотите узнать о Quartier Latin больше — мы на связи!",
        phone1: "+33 1 44 82 90 31",
        phone2: "+33 6 26 63 86 27",
        socialMedia: "QuartierLatinRU",
        email: "contact@quartier-latin.com",
        workingHours: "Понедельник - пятница 8.30 - 18.00",
        responseTime: "Ответим в течение 24 рабочих часов",
        buttonText: "Задать вопрос",
        phoneIcon: null,
        phoneIcon2: null,
        emailIcon: null,
        socialIcon: null,
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "phone1",
                type: "String",
                name: "Phone 1",
            },
            {
                id: "phone2",
                type: "String",
                name: "Phone 2",
            },
            {
                id: "socialMedia",
                type: "String",
                name: "Social Media",
            },
            {
                id: "email",
                type: "String",
                name: "Email",
            },
            {
                id: "workingHours",
                type: "String",
                name: "Working Hours",
            },
            {
                id: "responseTime",
                type: "String",
                name: "Response Time",
            },
            {
                id: "buttonText",
                type: "String",
                name: "Button Text",
            },
            {
                id: "phoneIcon",
                type: "Custom",
                name: "Phone Icon",
                customType: "Image",
            },
            {
                id: "phoneIcon2",
                type: "Custom",
                name: "Phone Icon 2",
                customType: "Image",
            },
            {
                id: "emailIcon",
                type: "Custom",
                name: "Email Icon",
                customType: "Image",
            },
            {
                id: "socialIcon",
                type: "Custom",
                name: "Social Icon",
                customType: "Image",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};

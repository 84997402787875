interface CurvedTextProps {
    text: string;
}

export const CurvedText: React.FC<CurvedTextProps> = ({ text }) => {
    const repeatedText = `${text + "\u00A0\u00A0\u00A0\u00A0" }`.repeat(10);
    return (
    <svg width="951" height="930" viewBox="0 0 951 898" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            id="curvePayment"
            d="M433.626 20C452.989 72.5462 423.793 278.967 241.409 373.247C59.0242 467.528 -31.3613 669.853 201.451 759.378C434.264 848.903 496.418 455.372 587.749 321.538C679.079 187.704 853.367 280.703 885.682 464.653C917.997 648.602 737.266 671.83 647.528 797.281C636.647 812.492 617.217 856.622 618.503 887.588"
            stroke={"#FA5354"}
            strokeWidth="115"
        />
            <text fontSize="42px" fontFamily="Mustica Pro, sans-serif" fontWeight="600" fill="white">
                <textPath href="#curvePayment" startOffset="0">
                    <tspan x="0" dy="0.2em" style={{ lineHeight: '54.6px' }}>
                        {repeatedText}
                    </tspan>
                </textPath>
            </text>
    </svg>
    );
};


import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./UniversityProgramsBlock.module.css";
import cn from "classnames";
import Date from "./Date.svg"
import Language from "./Language.svg"
import Campus from "./Campus.svg"
import Timing from "./Timing.svg"

export interface UniversityProgramsBlockElement {
  title: string;
  elements: {
    programName: string,
    languages: string,
    time: string,
    place: string,
    duration: string,
    price: string,
  }[];
  button: boolean;
  buttonText: string;
  buttonLink: string;
  topMargin?: string;
  bottomMargin?: string;
  customMargin?: boolean;
}

export const UniversityProgramsBlock = (props: UniversityProgramsBlockElement) => {
  const formatPrice = (price: string) => {
    const match = price.match(/([€$£¥]\s?\d+([.,-]\d+)?)/);
    if (match) {
      const [matchedText] = match;
      const [before, after] = price.split(matchedText);
      return (
        <>
          {before}
          <strong className={cn(styles.universityProgramsBlock__price)}>{matchedText}</strong>
          {after}
        </>
      );
    }
    return price;
  };
  const marginStyles = props.customMargin
    ? {
      marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
      marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
    }
    : {};
  return (
    <div className="flex flex-col my-12 px-4 md:px-12 lg:px-20 max-w-screen-xl justify-center gap-10 mx-auto" style={marginStyles}>
      <div className={"flex flex-col gap-7 items-center text-center mb-8"}>
        <div className={cn(styles.universityProgramsBlock__title)}>
          {props.title}
        </div>
        <div className={cn("flex flex-col w-full mb-5")}>
          <hr className={cn("mb-5 w-full")} />
          {props.elements && props.elements.map((element) =>
            <div className={cn("flex flex-col")}>
              <div className={cn("flex flex-col md:flex-row gap-5 md:gap-0 md:justify-between")}>
                <div className={cn("flex flex-col md:flex-row justify-between w-full ", styles.universityProgramsBlock__table)}>
                  <div className={cn(styles.universityProgramsBlock__programName, "self-start ")}>{element.programName}</div>
                  <div className={"flex flex-col lg:flex-row w-full gap-4 lg:justify-between "}>
                    <div className={"flex flex-col gap-4 w-full lg:w-7/12"}>
                      <div className={cn("flex gap-2 self-start w-full", styles.universityProgramsBlock__row)}>
                        <img src={Language} alt="" className={cn(styles.universityProgramsBlock__icon)} />
                        <p className={cn(styles.universityProgramsBlock__text, "self-start flex")}>{element.languages}</p>
                      </div>
                      <div className={cn("flex gap-2 self-start", styles.universityProgramsBlock__row)}>
                        <img src={Timing} alt="" className={cn(styles.universityProgramsBlock__icon)} />
                        <p className={cn(styles.universityProgramsBlock__text, "self-start")}>{element.time}</p>
                      </div>
                    </div>
                    <div className={"flex flex-col gap-4 w-full lg:w-2/4"}>
                      <div className={cn("flex gap-2", styles.universityProgramsBlock__row)}>
                        <img src={Campus} alt="" className={cn(styles.universityProgramsBlock__icon)} />
                        <p className={cn(styles.universityProgramsBlock__text, "self-start")}>{element.place}</p>
                      </div>
                      <div className={cn("flex gap-2", styles.universityProgramsBlock__row)}>
                        <img src={Date} alt="" className={cn(styles.universityProgramsBlock__icon)} />
                        <p className={cn(styles.universityProgramsBlock__text, "self-start")}>{element.duration}</p>
                      </div>
                    </div>
                  </div>
                  <div className={"flex flex-col gap-4 w-7/12 lg:w-2/4"}>
                    <p className={cn(styles.universityProgramsBlock__aroundPrice, "self-start")}>{formatPrice(element.price)}</p>
                    <div className={cn("hidden")}>
                    </div>
                  </div>
                </div>
              </div>
              <hr className={cn("my-5 w-full")} />
            </div>
          )}
        </div>
        {props.button &&
          <div className={"flex w-full justify-center"}>
            <a type={"button"} href={`/${props.buttonLink}`} className={styles.universityProgramsBlock__button}>{props.buttonText}</a>
          </div>
        }
      </div>
    </div>
  )
}

export const UniversityProgramsBlockInfo: TypedBlockTypeInfo<UniversityProgramsBlockElement> = {
  id: "UniversityProgramsBlock",
  name: "University Programs",
  preview: preview,
  renderer: UniversityProgramsBlock,
  initialData: {
    title: "string",
    elements: [{
      programName: "string",
      languages: "string",
      time: "string",
      place: "string",
      duration: "string",
      price: "string",
    }],
    button: false,
    buttonText: "",
    buttonLink: "#",
    topMargin: "",
    bottomMargin: "",
    customMargin: false,
  },
  definition: {
    subTypes: {
      element: {
        fields: [
          {
            id: "programName",
            type: "String",
            name: "Program Name",
          },
          {
            id: "languages",
            type: "String",
            name: "Languages",
          },
          {
            id: "time",
            type: "String",
            name: "Time",
          },
          {
            id: "place",
            type: "String",
            name: "Place",
          },
          {
            id: "duration",
            type: "String",
            name: "Duration",
          },
          {
            id: "price",
            type: "String",
            name: "Price",
          },
        ]
      }
    },
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "elements",
        type: "List",
        listType: "element",
        name: "Elements",
      },
      {
        id: "button",
        type: "CheckBox",
        name: "Button",
      },
      {
        id: "buttonText",
        type: "String",
        name: "Button Text",
      },
      {
        id: "buttonLink",
        type: "String",
        name: "Button Link",
      },
      {
        id: "customMargin",
        type: "CheckBox",
        name: "Custom Margin",
      },
      {
        id: "topMargin",
        type: "String",
        name: "Top Margin (px)",
      },
      {
        id: "bottomMargin",
        type: "String",
        name: "Bottom Margin (px)",
      },
    ]
  }
}

import React, { useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./RequisitesBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface RequisitesBlockElement {
    icon: number | null;
    title: string;
    companyNameLabel: string;
    ogrnLabel: string;
    innLabel: string;
    legalAddressLabel: string;
    actualAddressLabel: string;
    companyName: string;
    ogrn: string;
    inn: string;
    legalAddress: string;
    actualAddress: string;
    buttonText: string;
    successText: string;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const RequisitesBlock: React.FC<RequisitesBlockElement> = ({
    icon,
    title,
    companyNameLabel,
    ogrnLabel,
    innLabel,
    legalAddressLabel,
    actualAddressLabel,
    companyName,
    ogrn,
    inn,
    legalAddress,
    actualAddress,
    buttonText,
    successText,
    customMargin,
    topMargin,
    bottomMargin
}) => {
    const requisitesText = `${companyNameLabel} ${companyName}\n${ogrnLabel} ${ogrn}\n${innLabel} ${inn}\n${legalAddressLabel}: ${legalAddress}\n${actualAddressLabel}: ${actualAddress}`;
    const [copyStatus, setCopyStatus] = useState(buttonText);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(requisitesText).then(() => {
            setCopyStatus(successText);
            setTimeout(() => {
                setCopyStatus(buttonText);
            }, 3000);
        }).catch(err => {
            console.error("Failed to copy: ", err);
        });
    };
    const marginStyles = customMargin
        ? {
            marginTop: topMargin ? `${topMargin}px` : undefined,
            marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className="my-12" style={marginStyles}>
            <div className="flex flex-col items-start lg:p-12 justify-between mx-auto w-full max-w-screen-xl text-sm lg:text-lg">
                <h3 className={cn(style.title, "flex justify-center w-full lg:justify-start")}>{title}</h3>
                <div className={cn("flex w-full justify-between", style.requisitesContent)}>
                    <div className={cn(style.column, "hidden lg:flex flex-col justify-around ")}>
                        <p>{companyNameLabel} {companyName}</p>
                        <p>{ogrnLabel} {ogrn}</p>
                        <p>{innLabel} {inn}</p>
                    </div>
                    <div className={cn(style.column, "flex lg:hidden flex-row justify-around gap-5")}>
                        <div className="flex flex-col">
                            <span>{companyNameLabel}</span>
                            <span>{companyName}</span>
                        </div>
                        <div className="flex flex-col">
                            <span>{ogrnLabel}</span>
                            <span>{ogrn}</span>
                        </div>
                        <div className="flex flex-col">
                            <span>{innLabel}</span>
                            <span>{inn}</span>
                        </div>
                    </div>
                    <div className={cn(style.column, "w-4/6")}>
                        <p className={style.subtitle}>{legalAddressLabel}</p>
                        <p>{legalAddress}</p>
                    </div>
                    <div className={cn(style.column, "w-4/6")}>
                        <p className={style.subtitle}>{actualAddressLabel}</p>
                        <p>{actualAddress}</p>
                    </div>
                </div>
                <button className={style.copyButton} onClick={copyToClipboard}>
                    {icon && <img src={`${ApiBaseUrl}/api/media/${icon}`} alt="Иконка" className={style.icon} />}
                    {copyStatus}
                </button>
            </div>
        </div>
    );
};

export const RequisitesBlockInfo: TypedBlockTypeInfo<RequisitesBlockElement> = {
    id: "RequisitesBlock",
    name: "Requisites",
    preview: preview,
    renderer: RequisitesBlock,
    initialData: {
        icon: null,
        title: "Реквизиты",
        companyNameLabel: "Наименование:",
        ogrnLabel: "ОГРН:",
        innLabel: "ИНН:",
        legalAddressLabel: "Юридический адрес",
        actualAddressLabel: "Фактический адрес",
        companyName: "Quartier Latin",
        ogrn: "5167746328811",
        inn: "9710020431",
        legalAddress: "105082, г. Москва, внут.тер. Муниципальный округ Красносельский, пер. Спартаковский, д. 2, стр. 1, этаж 2, помещение 18, офис №32",
        actualAddress: "105082, г. Москва, м. Красносельская, Спартаковский пер., д. 2, с. 1, подъезд 5, 3 этаж, офис 32",
        buttonText: "Скопировать реквизиты",
        successText: "Скопировано",
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        fields: [
            {
                id: "icon",
                type: "Custom",
                name: "Icon",
                customType: "Image",
            },
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "companyNameLabel",
                type: "String",
                name: "Company Name Label",
            },
            {
                id: "ogrnLabel",
                type: "String",
                name: "OGRN Label",
            },
            {
                id: "innLabel",
                type: "String",
                name: "INN Label",
            },
            {
                id: "legalAddressLabel",
                type: "String",
                name: "Legal Address Label",
            },
            {
                id: "actualAddressLabel",
                type: "String",
                name: "Actual Address Label",
            },
            {
                id: "companyName",
                type: "String",
                name: "Company Name",
            },
            {
                id: "ogrn",
                type: "String",
                name: "OGRN",
            },
            {
                id: "inn",
                type: "String",
                name: "INN",
            },
            {
                id: "legalAddress",
                type: "String",
                name: "Legal Address",
            },
            {
                id: "actualAddress",
                type: "String",
                name: "Actual Address",
            },
            {
                id: "buttonText",
                type: "String",
                name: "Button Text",
            },
            {
                id: "successText",
                type: "String",
                name: "Success Text",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};
import React, { useEffect, useRef, useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./ExtraStatisticsBlock.module.css";
import cn from "classnames";
import {ApiBaseUrl} from "../../api/apiClientBase";

export interface ExtraStatisticsBlockElement {
    header: string;
    highlightedTextHeader: string,
    elements: { title: string; text: string; icon: number | null }[];
}

const splitTitle = (title: string) => {
    const parts = title.split(' ');
    if (parts.length > 1) {
        return (
            <span>
                <span className={style.extraStatisticsBlock__elementHeader}>{parts.slice(0, 1).join(' ')}</span> {parts[1]}
            </span>
        );
    }
    return <span>{title}</span>;
};

export const ExtraStatisticsBlock = (props: ExtraStatisticsBlockElement) => {
    const [isVisible, setIsVisible] = useState(false);
    const blockRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.7,
            }
        );

        if (blockRef.current) {
            observer.observe(blockRef.current);
        }

        return () => {
            if (blockRef.current) {
                observer.unobserve(blockRef.current);
            }
        };
    }, []);

    const getFadeInClass = (delay: number) => ({
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(20px)",
        transition: `opacity 1.5s ease-out ${delay}s, transform 1.5s ease-out ${delay}s`
    });

  const textBefore = props.header.slice(0, props.header.indexOf(props.highlightedTextHeader));
  const textAfter =
    textBefore.length !== props.header.length ? props.header.slice(props.header.indexOf(props.highlightedTextHeader) + props.highlightedTextHeader?.length) : "";

    return (
        <div ref={blockRef} className={cn(style.extraStatisticsBlock__body)}>
            <div className={cn("flex flex-col justify-between max-w-screen-xl w-full my-0 mx-auto px-4 lg:px-14 relative", style.extraStatisticsBlock__animation, { [style.extraStatisticsBlockVisible]: isVisible })}>
                <div className="flex items-center lg:items-start flex-col">
                    <div className={cn(style.extraStatisticsBlock__header)} style={{...getFadeInClass(0)}}>
                      {props.highlightedTextHeader ?
                        <h1>
                          {textBefore}
                          <span>{props.highlightedTextHeader}</span>
                          {textAfter}
                        </h1> :
                        <h1>
                          {props.header}
                        </h1>
                      }
                        </div>
                </div>
                <div className={cn(
                    style.extraStatisticsBlock__elements,
                    "grid text-left"
                )}>
                    {props.elements.map((el, index) => (
                        <div key={index} className={cn(style.extraStatisticsBlock__element, "flex flex-col gap-2")}>
                            <img src={`${ApiBaseUrl}/api/media/${el.icon}`} alt="" className={cn(style.extraStatisticsBlock__elementIcon)}/>
                            <div className={"flex flex-col gap-1 md:gap-3"}>
                                <div className={style.extraStatisticsBlock__elementTitle} style={{...getFadeInClass(0.2)}}>
                                    {splitTitle(el.title)}
                                </div>
                                <div className={style.extraStatisticsBlock__elementText} style={{...getFadeInClass(0.3)}}>
                                    {el.text}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export const ExtraStatisticsBlockInfo: TypedBlockTypeInfo<ExtraStatisticsBlockElement> = {
    id: "ExtraStatisticsBlock",
    name: "ExtraStatisticsBlock",
    preview: preview,
    renderer: ExtraStatisticsBlock,
    initialData: {
        header: "Header",
        highlightedTextHeader: "",
        elements: [
            {
                title: "string",
                text: "string",
                icon: null
            },
        ],
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "title",
                        type: "String",
                        name: "Title"
                    },
                    {
                        id: "text",
                        type: "String",
                        name: "Text"
                    },
                    {
                        id: "icon",
                        type: "Custom",
                        customType: "Image",
                        name: "Icon"
                    },
                ],
            },
        },
        fields: [
            {
                id: "header",
                type: "String",
                name: "Header",
            },
            {
                id: "highlightedTextHeader",
                type: "String",
                name: "Highlighted Text Header",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements"
            },
        ]
    }
}

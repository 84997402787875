import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./TrainingPlanBlock.module.css";
import cn from "classnames";

export interface TrainingPlanBlockElement {
    title: string;
    semesters: {
        title: string;
        elements: {
            text: string;
        }[];
        text: string;
    }[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const TrainingPlanBlock: React.FC<TrainingPlanBlockElement> = ({ title, semesters, customMargin, topMargin, bottomMargin }) => {
    const marginStyles = customMargin
        ? {
            marginTop: topMargin ? `${topMargin}px` : undefined,
            marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={cn("my-24 mx-auto w-full max-w-screen-xl")} style={marginStyles}>
            {title &&
                <h2 className={styles.trainingPlanTitle} dangerouslySetInnerHTML={{
                    __html: title
                }} />
            }
            {semesters &&
                <div className={styles.semestersContainer}>
                    {semesters.map((semester, index) => (
                        <div key={index} className={styles.semesterBlock}>
                            {semester.title && <h3 className={styles.semesterTitle} dangerouslySetInnerHTML={{
                                __html: semester.title
                            }} />}
                            {semester.elements && !semester.text &&
                                <ul className={styles.courseList}>
                                    {semester.elements.map((element, idx) => (
                                        <li key={idx} className={styles.courseItem} dangerouslySetInnerHTML={{
                                            __html: element.text
                                        }} />
                                    ))}
                                </ul>
                            }
                            {semester.text && <div className={styles.courseItem} dangerouslySetInnerHTML={{
                                __html: semester.text
                            }} />}
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}

export const TrainingPlanBlockInfo: TypedBlockTypeInfo<TrainingPlanBlockElement> = {
    id: "TrainingPlanBlock",
    name: "Training Plan",
    preview: preview,
    renderer: TrainingPlanBlock,
    initialData: {
        title: "",
        semesters: [
            {
                title: "",
                elements: [{
                    text: "",
                }],
                text: "",
            },
        ],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "text",
                        type: "Custom",
                        customType: "Html",
                        name: "Text",
                    }
                ]
            },
            semester: {
                fields: [
                    {
                        id: "title",
                        type: "Custom",
                        customType: "Html",
                        name: "Title",
                    },
                    {
                        id: "elements",
                        name: "Elements",
                        type: "List",
                        listType: "element",
                    },
                    {
                        id: "text",
                        type: "Custom",
                        customType: "Html",
                        name: "Text instead of a list",
                    }
                ]
            }
        },
        fields: [
            {
                id: "title",
                name: "Title",
                type: "Custom",
                customType: "Html",
            },
            {
                id: "semesters",
                name: "Semesters",
                type: "List",
                listType: "semester",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};
import React, { useRef } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./PaymentForServicesBlock.module.css"
import { ApiBaseUrl } from "../../api/apiClientBase";
import preview from "./preview.png";
import cn from "classnames";
import { CurvedText } from "./CurvedText";
import { MiniCurvedText } from "./MiniCurvedText";
import { StretchedMiniCurvedText } from "./StretchedMiniCurvedText";
import { HtmlPresenter } from "../../ui/HtmlPresenter/htmlPresenter";

export interface PaymentForServicesBlockElement {
    title: string,
    text: string,
    secondText: string,
    bottomIcon: null | number,
    bottomTitle: string,
    bottomText: string,
    curveText: string,
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const PaymentForServicesBlock = (props: PaymentForServicesBlockElement) => {
    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={cn(styles.paymentForServices, "flex justify-center my-28 md:my-32 lg:my-40")} style={marginStyles}>
            <div className={"h-full w-full max-w-screen-xl flex relative"}>
                {props.curveText &&
                    <div className={cn(styles.paymentForServices__curve, "hidden lg:flex")}>
                        <CurvedText text={`${props.curveText}`} />
                    </div>
                }
                <div className={styles.paymentForServices__textContainer}>
                    <div className={styles.paymentForServices__information}>
                        <div className="flex flex-col gap-5">
                            {props.title &&
                                <div className={styles.paymentForServices__title} dangerouslySetInnerHTML={{
                                    __html: props.title,
                                }} />
                            }
                            {(props.text || props.secondText) &&
                                <div className="flex flex-col gap-2">
                                    {props.text &&
                                        <div className={styles.paymentForServices__text} dangerouslySetInnerHTML={{
                                            __html: props.text,
                                        }} />
                                    }
                                    {props.secondText &&
                                        <div className={styles.paymentForServices__text} dangerouslySetInnerHTML={{
                                            __html: props.secondText,
                                        }} />
                                    }
                                </div>
                            }
                        </div>
                        <div className="flex flex-col gap-2">
                            {props.bottomIcon && <img src={`${ApiBaseUrl}/api/media/${props.bottomIcon}`} className={styles.paymentForServices__icon} alt="" />}
                            {props.bottomTitle && <div className={styles.paymentForServices__BottomTitle} dangerouslySetInnerHTML={{
                                __html: props.bottomTitle,
                            }} />}
                            {props.bottomText && <div className={styles.paymentForServices__text} dangerouslySetInnerHTML={{
                                __html: props.bottomText,
                            }} />}
                        </div>
                    </div>
                </div>
                {props.curveText && <div className={cn(styles.paymentForServices__miniCurve, "hidden md:flex lg:hidden")}>
                    <StretchedMiniCurvedText text={`${props.curveText}`} />
                </div>}
            </div>
        </div>
    );
};

export const PaymentForServicesBlockInfo: TypedBlockTypeInfo<PaymentForServicesBlockElement> = {
    id: "PaymentForServicesBlock",
    name: "Payment For Services",
    preview: preview,
    renderer: PaymentForServicesBlock,
    initialData: {
        title: "string",
        text: "string",
        secondText: "string",
        bottomIcon: null,
        bottomTitle: "string",
        bottomText: "string",
        curveText: "string",
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        fields: [
            {
                id: "title",
                type: "Custom",
                customType: "Html",
                name: "Title",
            },
            {
                id: "text",
                type: "Custom",
                customType: "Html",
                name: "Text",
            },
            {
                id: "secondText",
                type: "Custom",
                customType: "Html",
                name: "Second Text",
            },
            {
                id: "bottomIcon",
                type: "Custom",
                customType: "Image",
                name: "Bottom Icon"
            },
            {
                id: "bottomTitle",
                type: "Custom",
                customType: "Html",
                name: "Bottom Title",
            },
            {
                id: "bottomText",
                type: "Custom",
                customType: "Html",
                name: "Bottom Text",
            },
            {
                id: "curveText",
                type: "String",
                name: "Curve Text",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};

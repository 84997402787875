import React, { useRef, useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./PhotoGalleryBlock.module.css";
import Arrow from "../../assets/icons/white_arrow.svg";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";
import Slider from "../../ui/Slider/Slider";

export interface PhotoGalleryBlockElement {
    title: string;
    subtitle: string;
    text: string;
    extraText: string;
    elements: {
        videoLink: string;
        image: number | null;
    }[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const PhotoGalleryBlock: React.FC<PhotoGalleryBlockElement> = ({ title, subtitle, text, extraText, elements, customMargin, topMargin, bottomMargin }) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);

    const openModal = (imageUrl: string, index: number) => {
        setSelectedImage(imageUrl);
        setCurrentIndex(index);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setCurrentIndex(null);
    };

    const showNextImage = () => {
        if (currentIndex !== null && elements.length > 0) {
            const nextIndex = (currentIndex + 1) % elements.length;
            const nextImage = elements[nextIndex].image ? `${ApiBaseUrl}/api/media/${elements[nextIndex].image}` : null;
            if (nextImage) {
                setSelectedImage(nextImage);
                setCurrentIndex(nextIndex);
            }
        }
    };

    const showPrevImage = () => {
        if (currentIndex !== null && elements.length > 0) {
            const prevIndex = (currentIndex - 1 + elements.length) % elements.length;
            const prevImage = elements[prevIndex].image ? `${ApiBaseUrl}/api/media/${elements[prevIndex].image}` : null;
            if (prevImage) {
                setSelectedImage(prevImage);
                setCurrentIndex(prevIndex);
            }
        }
    };

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft -= 360;
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 360;
        }
    };
    const marginStyles = customMargin
        ? {
            marginTop: topMargin ? `${topMargin}px` : undefined,
            marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className="mx-auto w-full max-w-screen-xl mt-40" style={marginStyles}>
            <div className="flex flex-col items-center md:w-9/12 mx-auto">
                <div className={cn(styles.PhotoGalleryBlock__title)} dangerouslySetInnerHTML={{
                    __html: title
                }} />
                <div className={cn(styles.PhotoGalleryBlock__subtitle)} dangerouslySetInnerHTML={{
                    __html: subtitle
                }} />
                <div className={cn(styles.PhotoGalleryBlock__text, "mb-4")} dangerouslySetInnerHTML={{
                    __html: text
                }} />
                <div className={cn(styles.PhotoGalleryBlock__text)} dangerouslySetInnerHTML={{
                    __html: extraText
                }} />
            </div>
            <div className={"hidden lg:flex gap-5 justify-center mb-12 mt-12"}>
                <button onClick={scrollLeft} className={styles.PhotoGalleryBlock__arrowButton}><img src={Arrow} alt="left button" /></button>
                <button onClick={scrollRight} className={cn("transform rotate-180", styles.PhotoGalleryBlock__arrowButton)}>
                    <img src={Arrow} alt="right button" />
                </button>
            </div>
            <div ref={scrollContainerRef} className={cn("hidden md:flex justify-start max-w-screen-xl gap-8" +
                " w-full my-0 mx-auto py-6 px-2 md:px-8", styles.scrollContainer)}>
                {elements &&
                    elements.map(({ videoLink, image }, index) => (
                        <div
                            className={cn(styles.PhotoGalleryBlock__element, "overflow-x-auto")}
                            key={index}
                        >
                            {videoLink && (
                                <iframe
                                    src={videoLink}
                                    allow="autoplay; encrypted-media; screen-wake-lock"
                                    allowFullScreen
                                    style={{ width: "100%", height: "100%" }}
                                ></iframe>
                            )}
                            {image && (
                                <img src={`${ApiBaseUrl}/api/media/${image}`} width={"100%"} height={"100%"} alt="" onClick={() => openModal(`${ApiBaseUrl}/api/media/${image}`, index)} />
                            )}
                        </div>
                    ))}
            </div>
            {selectedImage && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
                    <button className={cn("absolute top-2 right-2 bg-white text-black rounded-full px-3 py-1 text-lg", styles.closeModal)} onClick={closeModal}>✖</button>
                    <button className={cn("absolute left-5 md:left-12 lg:left-40 bg-white text-black rounded-full p-3 text-2xl", styles.arrowModal)} onClick={showPrevImage}><img src={Arrow} alt="left button" style={{ filter: "invert(100%)" }}/></button>

                    <img src={selectedImage} alt="Full Size" className="max-w-1/2 rounded-lg shadow-lg" style={{ height: "90vh", width: "70vw" }} />

                    <button className={cn("absolute right-5 md:right-12 lg:right-40 bg-white text-black rounded-full p-3 text-2xl transform rotate-180", styles.arrowModal)} onClick={showNextImage}> <img src={Arrow} alt="right button" style={{ filter: "invert(100%)" }}/></button>
                </div>
            )}
            <div className={cn("flex my-8 mx-auto md:hidden")}>
                <Slider backgroundColor="white">
                    {elements &&
                        elements.map(({ videoLink, image }, index) => (
                            <div
                                className={cn("overflow-hidden h-44 w-full flex items-center")}
                                key={index}
                            >
                                {videoLink && (
                                    <iframe
                                        src={videoLink}
                                        allow="autoplay; encrypted-media; screen-wake-lock"
                                        allowFullScreen
                                        style={{ width: "100%", height: "100%" }}
                                    ></iframe>
                                )}
                                {image && (
                                    <img src={`${ApiBaseUrl}/api/media/${image}`} width={"100%"} height={"100%"} alt="" className={cn("object-cover object-center")} onClick={() => openModal(`${ApiBaseUrl}/api/media/${image}`, index)} />
                                )}
                            </div>
                        ))}
                </Slider>
            </div>
        </div>
    )
}


export const PhotoGalleryBlockInfo: TypedBlockTypeInfo<PhotoGalleryBlockElement> = {
    id: "PhotoGalleryBlock",
    name: "Photo Gallery",
    preview: preview,
    renderer: PhotoGalleryBlock,
    initialData: {
        title: "",
        subtitle: "",
        text: "",
        extraText: "",
        elements: [
            {
                videoLink: "",
                image: null,
            },
        ],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "videoLink",
                        type: "String",
                        name: "Video Link",
                    },
                    {
                        id: "image",
                        name: "Image",
                        type: "Custom",
                        customType: "Image",
                    },
                ]
            }
        },
        fields: [
            {
                id: "title",
                name: "Title",
                type: "Custom",
                customType: "Html",
            },
            {
                id: "subtitle",
                name: "Subtext",
                type: "Custom",
                customType: "Html",
            },
            {
                id: "text",
                name: "Text",
                type: "Custom",
                customType: "Html",
            },
            {
                id: "extraText",
                name: "Extra Text",
                type: "Custom",
                customType: "Html",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};

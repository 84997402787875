// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/icons/pin_black.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../assets/icons/pin_blue.svg";
import ___CSS_LOADER_URL_IMPORT_2___ from "../../assets/icons/pin_red.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Map_map__1KSLv canvas {\n  filter: grayscale(1);\n  -webkit-filter: grayscale(1);\n  -moz-filter: grayscale(1);\n  -o-filter: grayscale(1);\n}\n\n.Map_marker__2kXk6 {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center / 56px 56px;\n  width: 56px;\n  height: 56px;\n  transform: translate(-50%, -50%);\n}\n\n.Map_centerPin__1sBc0 {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat center / 56px 56px;\n  width: 56px;\n  height: 56px;\n  transform: translate(-50%, -50%);\n}\n\n.Map_marker__2kXk6.Map_active__33NC9 {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat center / 56px 56px;\n}", "",{"version":3,"sources":["webpack://../components/src/ui/Map/Map.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,4BAA4B;EAC5B,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,gFAAgF;EAChF,WAAW;EACX,YAAY;EACZ,gCAAgC;AAClC;;AAEA;EACE,gFAA+E;EAC/E,WAAW;EACX,YAAY;EACZ,gCAAgC;AAClC;;AAEA;EACE,gFAA8E;AAChF","sourcesContent":[".map canvas {\n  filter: grayscale(1);\n  -webkit-filter: grayscale(1);\n  -moz-filter: grayscale(1);\n  -o-filter: grayscale(1);\n}\n\n.marker {\n  background: url(\"../../assets/icons/pin_black.svg\") no-repeat center / 56px 56px;\n  width: 56px;\n  height: 56px;\n  transform: translate(-50%, -50%);\n}\n\n.centerPin {\n  background: url(\"../../assets/icons/pin_blue.svg\") no-repeat center / 56px 56px;\n  width: 56px;\n  height: 56px;\n  transform: translate(-50%, -50%);\n}\n\n.marker.active {\n  background: url(\"../../assets/icons/pin_red.svg\") no-repeat center / 56px 56px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"map": "Map_map__1KSLv",
	"marker": "Map_marker__2kXk6",
	"centerPin": "Map_centerPin__1sBc0",
	"active": "Map_active__33NC9"
};
export default ___CSS_LOADER_EXPORT___;

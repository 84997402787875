import React from "react";
import styles from "./TableContentsBlock.module.css";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import cn from "classnames";

export interface TableContentsBlockProps {
    list: {
        chapter: string;
    }[];
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const TableContentsBlock = (props: TableContentsBlockProps) => {
    const marginStyles = props.customMargin
        ? {
            marginTop: props.topMargin ? `${props.topMargin}px` : undefined,
            marginBottom: props.bottomMargin ? `${props.bottomMargin}px` : undefined,
        }
        : {};
    return (
        <div className={cn(styles.container, "lg:flex hidden")} style={marginStyles}>
            {props.list.map((item, index) => (
                <div
                    key={index}
                    className={cn(styles.item, { [styles.active]: index === 0 })}
                >
                    <div className={styles.chapter}>{item.chapter}</div>
                    <div className={styles.dot}></div>
                </div>
            ))}
            <div className={styles.line}></div>
        </div>
    );
};

export const TableContentsBlockInfo: TypedBlockTypeInfo<TableContentsBlockProps> = {
    id: "tableContentsBlock",
    name: "Table Contents",
    preview: preview,
    renderer: TableContentsBlock,
    initialData: {
        list: [{
            chapter: "",
        }],
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            list: {
                fields: [
                    {
                        id: "chapter",
                        type: "String",
                        name: "Chapter"
                    }
                ]
            },
        },
        fields: [
            {
                id: "list",
                type: "List",
                listType: "list",
                name: "List",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};

import React, { useRef } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./UsefulArticlesBlock.module.css"
import { ApiBaseUrl } from "../../api/apiClientBase";
import preview from "./preview.png";
import Date from "../../assets/icons/date.svg"
import Time from "../../assets/icons/time.svg"
import Arrow from "../../assets/icons/white_arrow.svg"
import cn from "classnames";
import { Slider } from "../../ui/Slider/Slider";
import { Link } from "react-router-dom";

export interface UsefulArticlesBlockElement {
    title: string,
    text: string,
    elements: {
        articleName: string,
        date: string,
        time: string,
        backgroundImg: number | null,
        items: {
            name: string
        }[],
        linkArticle: string;
    }[];
    buttonText: string,
    buttonLink: string,
    showButton: boolean,
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const UsefulArticlesBlock = (props: UsefulArticlesBlockElement) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft -= 360;
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 360;
        }
    };
    const marginTop = props.customMargin ? props.topMargin : '48' ;
    const marginBottom = props.customMargin ? props.bottomMargin : '48';
    return (
        <div style={{ marginTop: `${marginTop}px`, marginBottom: `${marginBottom}px` }}>
            <div className={styles.usefulArticleBlock__title + " text-center md:mb-6 mb-3"}>{props.title}</div>
            <div className={styles.usefulArticleBlock__text + " text-center md:mb-6 mb-0"}>{props.text}</div>
            {props.elements.length > 3 &&
                <div className={"hidden lg:flex gap-5 justify-center mb-6 mt-6 mb-0"}>
                    <button className={styles.usefulArticleBlock__arrowButton} onClick={scrollLeft}><img src={Arrow} alt="left button" /></button>
                    <button onClick={scrollRight} className={cn("transform rotate-180", styles.usefulArticleBlock__arrowButton)}>
                        <img src={Arrow} alt="right button"/>
                    </button>
                </div>
            }
            <div ref={scrollContainerRef} className={cn("hidden md:flex justify-start max-w-screen-xl gap-8" +
                " w-full my-0 mx-auto py-6 px-2 md:px-8 overflow-x-auto", styles.scrollContainer)}>
                {props.elements && props.elements.map((element, index) =>
                    <a href={element.linkArticle}>
                        <div className={styles.usefulArticleBlock__card} key={index}>
                            <div className={styles.usefulArticleBlock__cardImageWrapper}>
                                <div className={styles.usefulArticleBlock__cardImage} style={{ backgroundImage: `url(${ApiBaseUrl}/api/media/${element.backgroundImg})` }}>
                                </div>
                                <div className={styles.usefulArticleBlock__itemsContainer}>
                                    {element.items.map((item, index) =>
                                        <div className={styles.usefulArticleBlock__item} key={index}>{item.name.toUpperCase()}</div>
                                    )}
                                </div>
                            </div>
                            <div className={"flex flex-col gap-3"}>
                                <div className={styles.usefulArticleBlock__articleName}>{element.articleName}</div>
                                <div className={styles.usefulArticleBlock__articleInfo + " flex gap-5"}>
                                    <div className="flex gap-5">
                                        <div className="flex md:gap-3 gap-1">
                                            <img src={Date} alt="date" />
                                            {element.date}
                                        </div>
                                        <div className="flex md:gap-3 gap-2">
                                            <img src={Time} alt="time" />
                                            {element.time}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                )}
            </div>
            <div className={cn("flex my-8 mx-auto md:hidden")}>
                <Slider backgroundColor="white">
                    {props.elements.map((element, index) => (
                        <div className={styles.usefulArticleBlock__card + " mx-auto my-0"} key={index}>
                            <div className={styles.usefulArticleBlock__cardImage} style={{ backgroundImage: `url(${ApiBaseUrl}/api/media/${element.backgroundImg})` }}>
                                {element.items.map((item, index) =>
                                    <div className={styles.usefulArticleBlock__item} key={index}>{item.name.toUpperCase()}</div>
                                )}
                            </div>
                            <div className={"flex flex-col gap-3"}>
                                <div className={styles.usefulArticleBlock__articleName}>{element.articleName}</div>
                                <div className={styles.usefulArticleBlock__articleInfo + " flex gap-5"}>
                                    <div className="flex gap-5">
                                        <div className="flex gap-3">
                                            <img src={Date} alt="date" />
                                            {element.date}
                                        </div>
                                        <div className="flex gap-3">
                                            <img src={Time} alt="time" />
                                            {element.time}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            {
                props.showButton &&
                <div className="flex justify-center md:mt-6 mt-0">
                    <a type={"button"} className={styles.usefulArticleBlock__button} href={props.buttonLink}>
                        {props.buttonText}
                    </a>
                </div>
            }
        </div >

    );
};

export const UsefulArticlesBlockInfo: TypedBlockTypeInfo<UsefulArticlesBlockElement> = {
    id: "UsefulArticlesBlock",
    name: "Useful Articles",
    preview: preview,
    renderer: UsefulArticlesBlock,
    initialData: {
        title: "string",
        text: "string",
        elements: [{
            articleName: "string",
            date: "23 ноября 2024",
            time: "12 мин",
            backgroundImg: null,
            items: [{
                name: ""
            }],
            linkArticle: "",
        }],
        buttonText: "string",
        buttonLink: "#",
        showButton: true,
        topMargin: '',
        bottomMargin: '',
        customMargin: false,
    },
    definition: {
        subTypes: {
            item: {
                fields: [
                    {
                        id: "name",
                        type: "String",
                        name: "Name"
                    },
                ]
            },
            element: {
                fields: [
                    {
                        id: "articleName",
                        type: "String",
                        name: "Article name"
                    },
                    {
                        id: "date",
                        type: "String",
                        name: "Date"
                    },
                    {
                        id: "time",
                        type: "String",
                        name: "Reading Time"
                    },
                    {
                        id: "backgroundImg",
                        type: "Custom",
                        customType: "Image",
                        name: "Background Image",
                    },
                    {
                        id: "items",
                        type: "List",
                        listType: "item",
                        name: "Items"
                    },
                    {
                        id: "linkArticle",
                        type: "String",
                        name: "Link Article",
                    }
                ],
            },
        },
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "text",
                type: "String",
                name: "Text",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements"
            },
            {
                id: "buttonText",
                type: "String",
                name: "Button Text",
            },
            {
                id: "buttonLink",
                type: "String",
                name: "Button Link",
            },
            {
                id: "showButton",
                type: "CheckBox",
                name: "Show Button",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};

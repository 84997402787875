import React, { FC, useEffect, useRef, useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./CoursePracticalLearningBlock.module.css"
import preview from "./preview.png";
import cn from "classnames";
import { CurvedText } from "./CurvedText";

export interface CoursePracticalLearningBlockElement {
  title: string,
  items: {
    text: string
  }[]
  curveText: string
}

export const CoursePracticalLearningBlock = (props: CoursePracticalLearningBlockElement) => {
  const [isVisible, setIsVisible] = useState(false);
  const blockRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.7,
      }
    );

    if (blockRef.current) {
      observer.observe(blockRef.current);
    }

    return () => {
      if (blockRef.current) {
        observer.unobserve(blockRef.current);
      }
    };
  }, []);

  return (
    <div ref={blockRef} className={cn(styles.practicalLearningBlock, "flex justify-center px-12 ")}>
      <div className={cn("h-full w-full max-w-screen-xl flex relative", styles.practicalLearningBlock__animation, { [styles.practicalLearningVisible]: isVisible })}>
        <div className={cn(styles.practicalLearningBlock__info, "lg:px-12 pt-2 pb-20")} >
          <div className={styles.practicalLearningBlock__title}>{props.title}</div>
          <ul className={cn(styles.practicalLearningBlock__textContainer)}>
            {props.items && props.items.map((item) => (
              <li className={cn(styles.practicalLearningBlock__text, "mb-3")}>{item.text}</li>
            ))}
          </ul>
        </div>
        <div className={cn(styles.practicalLearningBlock__curve, "hidden lg:flex")} >
          <CurvedText text={`${props.curveText}`} width={"115"} fontSize={"42px"}/>
        </div>
        <div className={cn(styles.practicalLearningBlock__curve, "hidden md:flex lg:hidden")} >
          <CurvedText text={`${props.curveText}`} width={"100"} fontSize={"38px"} />
        </div>
      </div>
    </div>

  );
};

export const CoursePracticalLearningBlockInfo: TypedBlockTypeInfo<CoursePracticalLearningBlockElement> = {
  id: "CoursePracticalLearningBlock",
  name: "CoursePracticalLearningBlock",
  preview: preview,
  renderer: CoursePracticalLearningBlock,
  initialData: {
    title: "string",
    items: [{
      text: "string"
    }],
    curveText: "string"
  },
  definition: {
    subTypes: {
      item: {
        fields: [
          {
            id: "text",
            type: "String",
            name: "Text",
          }
        ]
      }
    },
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "items",
        type: "List",
        listType: "item",
        name: "Texts"
      },
      {
        id: "curveText",
        type: "String",
        name: "Curve Text",
      },
    ],
  },
};

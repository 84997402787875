import React, { useContext, useEffect, useRef, useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./MoreAboutCompanyBlock.module.css";
import cn from "classnames";
import { Slider } from "../../ui/Slider/Slider";

export interface MoreAboutCompanyBlockElement {
    header: string;
    bluetext: string;
    subtitle: string;
    elements: { title: string; text: string }[];
    textButton: string;
    linkButton: string;
    showButton: boolean;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}


const highlightMatchAsHtml = (text: string, highlight: string): string => {
    if (!highlight || !text.includes(highlight)) return text;

    return text.replace(
        new RegExp(`(${highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, "gi"),
        `<span style="background-color: #CEE1F3; color: #00309D; padding: 0 5px;">$1</span>`
    );
};

const splitTitle = (title: string, highlightOption: "full" | "partial") => {
    if (highlightOption === "full") {
        return <span style={{ lineHeight: "44px" }}>{title}</span>;
    }

    const parts = title.split(' ');
    if (parts.length > 1 || highlightOption === "partial") {
        return (
            <span>
                {parts[0]} <span className={style.moreAboutCompany__elementHeader}>{parts.slice(1).join(' ')}</span>
            </span>
        );
    }
    return <span>{title}</span>;
};

export const MoreAboutCompanyBlock = (props: MoreAboutCompanyBlockElement) => {
    const [isVisible, setIsVisible] = useState(false);
    const blockRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.5,
            }
        );

        if (blockRef.current) {
            observer.observe(blockRef.current);
        }

        return () => {
            if (blockRef.current) {
                observer.unobserve(blockRef.current);
            }
        };
    }, []);

    const marginTop = props.customMargin ? props.topMargin || "0" : "0";
    const marginBottom = props.customMargin ? props.bottomMargin || "0" : "0";
    const highlightedTextHtml = highlightMatchAsHtml(props.header, props.bluetext);
    return (
        <div className={cn(style.moreAboutCompany__body)} ref={blockRef} style={{ marginTop: `${marginTop}px`, marginBottom: `${marginBottom}px` }}>
            <div className={cn("flex flex-col justify-center lg:items-start max-w-screen-xl w-full my-0 mx-auto px-4 lg:px-14", style.moreAboutCompany__animation, { [style.visible]: isVisible })} >
                <div className="flex items-center lg:items-start flex-col">
                    <div className={cn("pt-14", style.moreAboutCompany__header)} dangerouslySetInnerHTML={{
                        __html: highlightedTextHtml
                    }} />
                    <div className={cn(style.moreAboutCompany__subtitle, "w-11/12 md:w-4/5 lg:w-4/6")} dangerouslySetInnerHTML={{
                        __html: props.subtitle
                    }} />
                    {props.showButton && (
                        <div className={cn(style.moreAboutCompany__buttonContainer)}>
                            <a className={cn(style.moreAboutCompany__button, "flex w-fit")} href={props.linkButton}>
                                <span dangerouslySetInnerHTML={{
                                    __html: props.textButton
                                }} />
                            </a>
                        </div>
                    )}
                </div>
                <div className={cn(
                    style.moreAboutCompany__elements,
                    { [style.moreAboutCompany__elementsTwoColumns]: !props.showButton },
                    "hidden md:grid md:mt-5"
                )}>
                    {props.elements.map((el, index) => (
                        <div key={index} className={style.moreAboutCompany__element}>
                            <div className={style.moreAboutCompany__elementHeader} dangerouslySetInnerHTML={{
                                __html: el.title
                            }} />
                            <div className={style.moreAboutCompany__elementText} dangerouslySetInnerHTML={{
                                __html: el.text
                            }} />
                        </div>
                    ))}
                </div>
                <div className={cn("flex my-8 text-center md:hidden")}>
                    <Slider>
                        {props.elements.map((el, index) => (
                            <div key={index} className={style.slider__element}>
                                <div className={style.moreAboutCompany__elementHeader} dangerouslySetInnerHTML={{
                                    __html: el.title
                                }} />
                                <div className={style.moreAboutCompany__elementText} dangerouslySetInnerHTML={{
                                    __html: el.text
                                }} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export const MoreAboutCompanyBlockInfo: TypedBlockTypeInfo<MoreAboutCompanyBlockElement> = {
    id: "moreAboutCompanyBlock",
    name: "More About Company",
    preview: preview,
    renderer: MoreAboutCompanyBlock,
    initialData: {
        header: "Header",
        bluetext: "",
        subtitle: "",
        elements: [
            {
                title: "string",
                text: "string"
            },
        ],
        showButton: true,
        textButton: "search",
        linkButton: "",
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "title",
                        type: "Custom",
                        customType: "Html",
                        name: "Title"
                    },
                    {
                        id: "text",
                        type: "Custom",
                        customType: "Html",
                        name: "Text"
                    }
                ],
            },
        },
        fields: [
            {
                id: "header",
                type: "Custom",
                customType: "Html",
                name: "Header",
            },
            {
                id: "bluetext",
                type: "String",
                name: "Bluetext"
            },
            {
                id: "subtitle",
                type: "Custom",
                customType: "Html",
                name: "Subtitle",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements"
            },
            {
                id: "textButton",
                type: "Custom",
                customType: "Html",
                name: "Text button",
            },
            {
                id: "linkButton",
                type: "String",
                name: "Link Button"
            },
            {
                id: "showButton",
                type: "CheckBox",
                name: "Show button",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ]
    }
}
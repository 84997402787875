import React, { useContext } from "react";
import styles from "./AdditionalServiceBlock.module.css"
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import { ApiBaseUrl } from "../../api/apiClientBase";
import cn from "classnames";
import { ComponentHostContext } from "../index";

interface ServiceOption {
    serviceIcon: number | null;
    serviceTitle: string;
    serviceText: string;
    backgroundImg: number | null;
    servicePrice: string;
    serviceButtonText: string;
    textAndButtonColor: string;
    link: string
}

export interface AdditionalServiceBlockProps {
    title: string;
    subtitle: string;
    highlightedText: string;
    highlightedTextColor: 'blue' | 'red';
    text: string;
    services: ServiceOption[];
}

const normalizeText = (text: string): string => {
    return text.toLowerCase().replace(/\s+/g, ' ').trim();
};

const highlightMatch = (subtitle: string, highlightedtext: string, highlightedTextColor: 'blue' | 'red'): JSX.Element[] => {
    if (!highlightedtext) return [<span key="0">{subtitle}</span>];
    const normalizedSubtitle = normalizeText(subtitle);
    const normalizedhighlightedtext = normalizeText(highlightedtext);
    const regex = new RegExp(`(${normalizedhighlightedtext.split(' ').join('\\s*')})`, "gi");
    const parts = normalizedSubtitle.split(regex);


    const highlightColor = highlightedTextColor === 'blue' ? "rgba(206, 225, 243, 1)" : "rgba(243, 215, 227, 1)";
    const textColor = highlightedTextColor === 'blue' ? "rgba(0, 48, 157, 1)" : "rgba(250, 83, 84, 1)";

    let originalIndex = 0;

    return parts.map((part, index) => {
        const originalPart = subtitle.substring(originalIndex, originalIndex + part.length);
        originalIndex += part.length;

        return regex.test(part) ? (
            <span key={index} style={{ backgroundColor: highlightColor, color: textColor }}>
                {originalPart}
            </span>
        ) : (
            <span key={index}>{originalPart}</span>
        );
    });
};

export const AdditionalServiceBlock: React.FC<AdditionalServiceBlockProps> = ({ title, subtitle, highlightedText, highlightedTextColor, text, services }) => {
    const textColor = (color: string) => {
        return color === "black" ? ["#373737", "#FFFFFF"] : ["#FFFFFF", "#373737"];
    };
    const cl = useContext(ComponentHostContext);
    const handleServiceClick = (service: ServiceOption) => {
        cl?.setSelectedService({ id: service.serviceIcon || 0, name: service.serviceTitle });
        cl?.showContactUsFormServiceBlue("rgba(0, 48, 157, 1)");
    };
    return (
        <div id={title.replace(/\s+/g, '-').toLowerCase()} className={cn("py-12 align-center mb-28 md:mb-32 lg:mb-40", styles.additionalServiceBlock)}>
            <div className={styles.additionalServiceBlock__title + " text-center mb-6"}>
                {title}
            </div>
            <div className={styles.additionalServiceBlock__subtitle + " text-center md:mb-6 mb-0"}>
                {highlightMatch(subtitle, highlightedText, highlightedTextColor)}
            </div>
            <div className={cn("text-center md:mb-6 mb-0 mx-auto", styles.additionalServiceBlock__text)}>
                {text}
            </div>
            <div className={"flex flex-col justify-between max-w-screen-xl w-full gap-5 my-0 mx-auto py-6 px-2 md:px-8"}>
                <a href={services[0].link || "#"} className={cn(styles.additionalServiceBlock__card, "flex flex-col justify-between p-7 relative overflow-hidden")}>
                    <div
                        className={cn(styles.additionalServiceBlock__cardImageWrapper, "absolute inset-0")}
                    >
                        <div
                            className={cn(styles.additionalServiceBlock__cardImage)}
                            style={{
                                backgroundImage: `url(${ApiBaseUrl}/api/media/${services[0].backgroundImg})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                width: '100%',
                                height: '100%',
                            }}
                        />
                    </div>
                    <div style={{ color: textColor(services[0].textAndButtonColor)[0] }} className="flex flex-col justify-between flex-grow relative z-10">
                        <div>
                            <div className={styles.additionalServiceBlock__imageTitle}>
                                {services[0].serviceTitle}
                            </div>
                            <div className={styles.additionalServiceBlock__imageText}>
                                {services[0].serviceText}
                            </div>
                        </div>
                        <div>
                            <div className={styles.additionalServiceBlock__imagePrice}>
                                {services[0].servicePrice}
                            </div>
                            <button
                                className={styles.additionalServiceBlock__imageButtonFirst}
                                style={{
                                    backgroundColor: textColor(services[0].textAndButtonColor)[0],
                                    color: textColor(services[0].textAndButtonColor)[1],
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleServiceClick(services[0])
                                }}
                            >
                                {services[0].serviceButtonText}
                            </button>
                        </div>
                    </div>
                </a>
                <a href={services[1].link || "#"} style={{
                    backgroundImage: `url(${ApiBaseUrl}/api/media/${services[1].backgroundImg})`,
                    color: textColor(services[1].textAndButtonColor)[0]
                }}
                    className={cn(styles.additionalServiceBlock__firstItem, styles.additionalServiceBlock__card, "hidden md:flex lg:hidden flex-col justify-between p-7", { [styles.additionalServiceBlock__cardNoImage]: !services[1].backgroundImg, })}>
                    <div>
                        <div className={styles.additionalServiceBlock__imageTitle}>
                            {services[1].serviceTitle}
                        </div>
                        <div className={styles.additionalServiceBlock__imageText}>
                            {services[1].serviceText}
                        </div>
                    </div>
                    <div>
                        <div className={styles.additionalServiceBlock__imagePrice}>
                            {services[1].servicePrice}
                        </div>
                        <button className={styles.additionalServiceBlock__imageButtonFirst} style={{ backgroundColor: textColor(services[1].textAndButtonColor)[0], color: textColor(services[1].textAndButtonColor)[1] }}
                            onClick={(e) => {
                                e.preventDefault();
                                handleServiceClick(services[1])
                            }}
                        >
                            {services[1].serviceButtonText}
                        </button>
                    </div>
                </a>
                <div className={cn(styles.additionalServiceBlock__secondRow, "w-full hidden lg:flex flex-wrap justify-between gap-5")}>
                    {services.slice(1, 3).map((service, index) => (
                        <a
                            href={service.link || "#"}
                            key={index}
                            className={cn("flex-1 flex flex-col justify-between p-7 relative overflow-hidden", styles.additionalServiceBlock__secItem, {
                                [styles.additionalServiceBlock__cardNoImage]: !service.backgroundImg,
                            })}
                            style={{ color: textColor(service.textAndButtonColor)[0] }}
                        >
                            <div
                                className={cn(styles.additionalServiceBlock__cardImageWrapper, "absolute inset-0")}
                            >
                                <div
                                    className={cn(styles.additionalServiceBlock__cardImage)}
                                    style={{
                                        backgroundImage: `url(${ApiBaseUrl}/api/media/${service.backgroundImg})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                            </div>
                            <div className="flex flex-col justify-between flex-grow relative z-10">
                                <div>
                                    <img
                                        src={`${ApiBaseUrl}/api/media/${service.serviceIcon}`}
                                        className={cn(styles.serviceIcon, index % 2 === 0 ? "flex md:hidden" : "lg:hidden")}
                                        alt=""
                                    />
                                    <div className={styles.additionalServiceBlock__imageTitle}>
                                        {service.serviceTitle}
                                    </div>
                                    <div className={styles.additionalServiceBlock__imageText}>
                                        {service.serviceText}
                                    </div>
                                </div>
                                <div>
                                    <div className={styles.additionalServiceBlock__imagePrice}>
                                        {service.servicePrice}
                                    </div>
                                    <button
                                        className={styles.additionalServiceBlock__imageButton}
                                        style={{
                                            backgroundColor: textColor(service.textAndButtonColor)[0],
                                            color: textColor(service.textAndButtonColor)[1],
                                            zIndex: 30,
                                            position: 'relative',
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleServiceClick(service)
                                        }}
                                    >
                                        {service.serviceButtonText}
                                    </button>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                    {services.slice(1, services.length).map((service, index) => (
                        <a href={service.link || "#"} key={index} className={cn(styles.additionalServiceBlock__miniItem, styles.additionalServiceBlock__card, {
                            [styles.additionalServiceBlock__cardNoImage]: !service.backgroundImg,
                        }, "flex-col justify-between p-7 flex md:hidden")}>
                            <div className="text-content">
                                <img src={`${ApiBaseUrl}/api/media/${service.serviceIcon}`} className={styles.serviceIcon} alt="" />
                                <div className={styles.additionalServiceBlock__imageTitle} style={{
                                    width: service.serviceTitle.length < 30 ? '60%' : '90%'
                                }}>
                                    {service.serviceTitle}
                                </div>
                                <div className={cn(styles.additionalServiceBlock__imageText, styles.styleText, "flex")}>
                                    {service.serviceText}
                                </div>
                            </div>
                            <div>
                                <div className={styles.additionalServiceBlock__imagePrice}>
                                    {service.servicePrice}
                                </div>
                                <button
                                    className={styles.additionalServiceBlock__imageButton}
                                    style={{
                                        backgroundColor: "#373737",
                                        color: "#FFFFFF",
                                        zIndex: 30,
                                        position: 'relative',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleServiceClick(service)
                                    }}
                                >
                                    {service.serviceButtonText}
                                </button>
                            </div>
                        </a>
                    ))}

                    {services.slice(2, services.length).map((service, index) => (
                        <a href={service.link || "#"} key={index} className={cn(styles.additionalServiceBlock__miniItem, styles.additionalServiceBlock__card, {
                            [styles.additionalServiceBlock__cardNoImage]: !service.backgroundImg,
                        }, "flex-col justify-between p-7 hidden md:flex lg:hidden")} style={{ color: textColor(service.textAndButtonColor)[0] }} >
                            <div className="text-content">
                                <img src={`${ApiBaseUrl}/api/media/${service.serviceIcon}`} className={styles.serviceIcon} alt="" />
                                <div className={styles.additionalServiceBlock__imageTitle} style={{
                                    width: service.serviceTitle.length < 30 ? '60%' : '90%'
                                }}>
                                    {service.serviceTitle}
                                </div>
                                <div className={cn(styles.additionalServiceBlock__imageText, styles.styleText, "flex")}>
                                    {service.serviceText}
                                </div>
                            </div>
                            <div>
                                <div className={styles.additionalServiceBlock__imagePrice}>
                                    {service.servicePrice}
                                </div>
                                <button
                                    className={styles.additionalServiceBlock__imageButton}
                                    style={{
                                        backgroundColor: "#373737",
                                        color: "#FFFFFF",
                                        zIndex: 30,
                                        position: 'relative',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleServiceClick(service)
                                    }}
                                >
                                    {service.serviceButtonText}
                                </button>
                            </div>
                        </a>
                    ))}

                    {services.slice(3, services.length).map((service, index) => (
                        <a href={service.link || "#"} key={index} className={cn(styles.additionalServiceBlock__miniItem, styles.additionalServiceBlock__card, {
                            [styles.additionalServiceBlock__cardNoImage]: !service.backgroundImg,
                        }, "flex-col justify-between p-7 flex hidden lg:flex")} style={{ color: textColor(service.textAndButtonColor)[0] }} >
                            <div className="text-content">
                                <img src={`${ApiBaseUrl}/api/media/${service.serviceIcon}`} className={styles.serviceIcon} alt="" />
                                <div className={styles.additionalServiceBlock__imageTitle} style={{
                                    width: service.serviceTitle.length < 30 ? '60%' : '90%'
                                }}>
                                    {service.serviceTitle}
                                </div>
                                <div className={cn(styles.additionalServiceBlock__imageText, styles.styleText, "flex")}>
                                    {service.serviceText}
                                </div>
                            </div>
                            <div>
                                <div className={styles.additionalServiceBlock__imagePrice}>
                                    {service.servicePrice}
                                </div>
                                <button
                                    className={styles.additionalServiceBlock__imageButton}
                                    style={{
                                        backgroundColor: "#373737",
                                        color: "#FFFFFF",
                                        zIndex: 30,
                                        position: 'relative',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleServiceClick(service)
                                    }}
                                >
                                    {service.serviceButtonText}
                                </button>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
};

export const AdditionalServiceBlockInfo: TypedBlockTypeInfo<AdditionalServiceBlockProps> = {
    id: "AdditionalServiceBlock",
    name: "AdditionalServiceBlock",
    preview: preview,
    renderer: AdditionalServiceBlock,
    initialData: {
        title: "string",
        subtitle: "string",
        highlightedText: "string",
        highlightedTextColor: "blue",
        text: "string",
        services: [
            {
                serviceIcon: null,
                serviceTitle: "string",
                serviceText: "string",
                backgroundImg: null,
                servicePrice: "string",
                serviceButtonText: "string",
                textAndButtonColor: "black",
                link: "#"
            },
        ],
    },
    definition: {
        subTypes: {
            service: {
                fields: [
                    {
                        id: "serviceIcon",
                        name: "Service Icon",
                        type: "Custom",
                        customType: "Image",
                    },
                    {
                        id: "serviceTitle",
                        name: "Service Title",
                        type: "String",
                    },
                    {
                        id: "serviceText",
                        name: "Service Text",
                        type: "String",
                    },
                    {
                        id: "backgroundImg",
                        name: "Background Image",
                        type: "Custom",
                        customType: "Image",
                    },
                    {
                        id: "servicePrice",
                        name: "Service Price",
                        type: "String",
                    },
                    {
                        id: "serviceButtonText",
                        name: "Service Button Text",
                        type: "String",
                    },
                    {
                        id: "textAndButtonColor",
                        name: "Text And Button Color",
                        type: "Radio",
                        possibleValues: [
                            {
                                id: "black",
                                name: "black"
                            },
                            {
                                id: "white",
                                name: "white"
                            }
                        ]
                    },
                    {
                        id: "link",
                        name: "Service Link",
                        type: "String"
                    }
                ],
            },
        },
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "subtitle",
                type: "String",
                name: "Subtitle",
            },
            {
                id: "highlightedText",
                type: "String",
                name: "Highlighted Text",
            },
            {
                id: "highlightedTextColor",
                type: "Radio",
                name: "Highlighted Text Color",
                possibleValues: [
                    {
                        id: "blue",
                        name: "Blue"
                    },
                    {
                        id: "red",
                        name: "Red",
                    },
                ]
            },
            {
                id: "text",
                type: "String",
                name: "Text",
            },
            {
                id: "services",
                name: "Services",
                type: "List",
                listType: "service",
            },
        ],
    },
};
